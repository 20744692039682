import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { getToken } from '../../../../Utils/Authenticate';
import { ReactComponent as UpArray } from '../../../../assets/images/up_array.svg';
import { Combobox, Transition } from '@headlessui/react';
import CheckIcon from '@mui/icons-material/Check';
import Popper from '@mui/material/Popper';
import { useParams } from 'react-router-dom';

export default function CustomizedHook({ setIngridientDetails, IngridientDetails }) {
  const [SearchQuery, setSearchQuery] = useState('');
  const [Tags, setTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [SelectedValue, setSelectedValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPop, setOpenPop] = useState(false);

  let accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const getTags = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/ingredient/tags?search=${SearchQuery}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const tags = response.data;
        if (tags.result === 'success') {
          const updatedTagsData = [{ name: SearchQuery }, ...tags.data];
          setTags(updatedTagsData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTags();
  }, [SearchQuery]);

  const comboboxRef = useRef(null);

  const openDirection = () => {
    if (comboboxRef.current) {
      const comboboxRect = comboboxRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceAbove = comboboxRect.top;
      const spaceBelow = windowHeight - comboboxRect.bottom;

      const threshold = 200;

      if (spaceBelow < threshold && spaceAbove > spaceBelow) {
        return 'top';
      } else {
        return 'bottom';
      }
    }
  };

  return (
    <div className="2xl:text-[14px] xl:text-[12px] lg:text-[10px] text-[#666]">
      <Combobox
        ref={comboboxRef}
        onChange={(data) => {
          setOpen(!open);
          setAnchorEl(null);
          setOpenPop(false);
          setSelectedValue('');
          setSearchQuery('');
          setIngridientDetails((prevDetails) => {
            const isDuplicate = IngridientDetails.tags.some(
              (tag) => tag.name.trim().toLowerCase() === data.name.trim().toLowerCase()
            );

            if (!isDuplicate) {
              return {
                ...prevDetails,
                tags: [...prevDetails.tags, data],
              };
            }
            return prevDetails;
          });
        }}
      >
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden  bg-white text-left  ">
            <Combobox.Input
              onChange={(event) => {
                setOpenPop(true);
                const newValue = event.target.value;
                const filteredValue = newValue.replace(/[^a-zA-Z0-9\s]/g, '');
                setSearchQuery(filteredValue);
              }}
              autocomplete="off"
              value={SearchQuery}
              placeholder={'Search Tags'}
              className="2xl:py-[8px] xl:py-[6px] lg:py-[4px] 2xl:pl-[7px] xl:pl-[5px] lg:pl-[3px] 2xl:pr-[25px] xl:pr-[23px] lg:pr-[21px] rounded-[10px] outline-none"
            />
            {/* <Combobox.Button className="absolute top-0 bottom-0 right-3">
              <UpArray
                onClick={(e) => {
                  setOpen(!open);
                  setOpenPop(!openPop);
                }}
                className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
              />
            </Combobox.Button> */}
          </div>
          <Popper
            sx={{ zIndex: 1200, width: comboboxRef?.current?.offsetWidth }}
            open={openPop}
            anchorEl={comboboxRef.current}
            placement="bottom-start"
          >
            <Transition
              as={Combobox.Options}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              style={{ borderRadius: '0px 0px 10px 10px', boxShadow: '4px 4px 8px -2px #0000001F' }}
              afterLeave={() => {
                setSearchQuery('');
                setOpenPop(!openPop);
              }}
              className={`shadow-xl max-h-60 overflow-auto bg-white 2xl:text-[14px] xl:text-[12px] lg:text-[10px]`}
            >
              <>
                {Tags?.filter((data) => !!data.name)?.length === 0 ? (
                  <div className="relative cursor-default lg:py-[8px] lg:px-4 md:py-[6px] md:px-3 select-none text-gray-700 ">
                    Nothing found.
                  </div>
                ) : (
                  Tags?.filter((data) => !!data.name).map((tags, id) => {
                    const isSelected = IngridientDetails.tags.some((tag) => tag.name === tags.name);
                    return (
                      <Combobox.Option
                        key={id}
                        className={({ active }) => {
                          return `relative select-none cursor-pointer lg:py-2 lg:px-3 border-b ${
                            active ? 'bg-[#D2A62C] text-[#FFFFFF]' : ''
                          } ${isSelected ? 'bg-[#D2A62C] text-[#FFFFFF]' : ''}`;
                        }}
                        value={tags}
                      >
                        {({ selected, active }) => (
                          <>
                            <div
                              className={`flex justify-between items-center ${
                                selected ? 'font-medium' : 'font-normal'
                              }${isSelected ? 'font-bold' : ''}`}
                            >
                              {tags.name}
                              {isSelected ? (
                                <CheckIcon sx={{ fontSize: '15px', marginLeft: '10px' }} />
                              ) : (
                                <></>
                              )}
                            </div>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }`}
                              >
                                <div className=" lg:h-4 lg:w-3 md:w-1 md:h-2" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    );
                  })
                )}
              </>
            </Transition>
          </Popper>
        </div>
      </Combobox>
    </div>
  );
}

import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import Button from './Button';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const SaveTemplet = ({ isOpen, closeModal, Version, planDetails }) => {
  const [tempName, setTempName] = useState('');
  let { userId } = useParams();

  const uniqueMeals = Array.from(
    new Map(
      planDetails?.flatMap((data) =>
        data.sections?.map(({ meal_id, meal, meal_time }) => [
          JSON.stringify({ meal, meal_time }),
          { meal_id, meal, meal_time },
        ])
      )
    ).values()
  );

  const saveTemplate = () => {
    if (tempName.trim()?.length === 0) {
      toast.error('Please Enter Template Name');
      return;
    }
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/diet_plan_set/save`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        user_id: userId,
        template_name: tempName,
        version_id: Version,
        meals: uniqueMeals,
      },
    };
    axios
      .request(config)
      .then(
        ({ data }) => {
          closeModal();
          toast.success(data.message);

          setTempName('');
        },
        (err) => {
          toast.error(err.response.data.message);
        }
      )
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <>
      <div className=" flex items-center justify-center">
        <button
          type="button"
          className="mr-[10px]"
          // className="inline-flex justify-center rounded-md  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px] text-shades-2 text-SmallerBodyDisplayText font-Regular"
        >
          Save As Template
        </button>
      </div>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="2xl:w-[694px] 2xl:h-[240px] xl:w-[536.81px] xl:h-[185.64px] lg:w-[447.34px] lg:h-[154.7px] transform overflow-hidden  rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="2xl:px-5 xl:px-4 lg:px-3 font-medium justify-between  border-b items-center   text-gray-900"
                  >
                    <div className="flex justify-between items-center">
                      <div></div>
                      <div className="text-center flex  text-shades-2 2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] 2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px]">
                        Save As Template
                      </div>
                      <div className=" cursor-pointer" onClick={closeModal}>
                        <Close />
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className=" border-b 2xl:py-5 xl:py-[15.47px]  lg:py-[12.89px]">
                    <div className="flex justify-center items-center 2xl:px-10 xl:px-[30.94px] lg:px-[25.78px] ">
                      <div className="text-body-color-2  text-base font-Regular  w-full">
                        Enter Template Name
                      </div>
                      <div className="border-b border-black text-body-color-1  2xl:text-base xl:text-[12.38px] lg:text-[10.31px] font-Medium w-full">
                        <input
                          type="text"
                          className="w-full outline-none"
                          placeholder="Template Name"
                          value={tempName}
                          onChange={(e) => setTempName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" flex justify-center 2xl:py-5 xl:py-[15.47px]  lg:py-[12.89px]">
                    <Button
                      type="button"
                      className=" rounded-small border border-transparent   bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-medium text-white"
                      onClick={saveTemplate}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SaveTemplet;

import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as Tag } from '../../../assets/images/tagged.svg';
import { ReactComponent as UnTag } from '../../../assets/images/untagged.svg';
import SortIcon from '../../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import BloodRescheduleTable from './BloodRescheduleTable';
import axios from 'axios';

export default function Report({ headers, userId }) {
  const [OpenModal, setOpenModal] = useState(false);
  const [RescheduleTable, setRescheduleTable] = useState([]);
  const initialRows = Array(5).fill({
    kit_status_timestamp: -1,
    barcode: -1,
    dna_sample_timestamp: -1,
    dna_report: -1,
    dna_status: -1,
  });
  const [DNAtestData, setDNAtestData] = useState({
    columns: [
      {
        id: 0,
        column_name: 'Kit Dispatch Date',
        map_column: 'kit_status_timestamp',
        type: 'string',
      },
      { id: 1, column_name: `Barcode`, map_column: 'barcode', type: 'string' },
      {
        id: 1,
        column_name: 'Kit Received Date',
        map_column: 'dna_sample_timestamp',
        type: 'string',
      },
      { id: 1, column_name: 'DNA Report', map_column: 'dna_report', type: 'string' },
      { id: 1, column_name: 'DNA Test Status', map_column: 'dna_status', type: 'string' },
    ],
    rows: initialRows,
  });

  const initialRowsBlood = Array(5).fill({
    request_date: -1,
    request_status: -1,
    reason_for_failure: -1,
    generation_date: -1,
    uploaded_date: -1,
    reschedule: -1,
  });
  const [BloodtestData, setBloodtestData] = useState({
    columns: [
      { id: 0, column_name: 'Request Date', map_column: 'request_date', type: 'string' },
      { id: 1, column_name: `Request Status`, map_column: 'request_status', type: 'string' },
      {
        id: 1,
        column_name: 'Reason for Failure',
        map_column: 'reason_for_failure',
        type: 'string',
      },
      { id: 1, column_name: 'Generation Date', map_column: 'generation_date', type: 'string' },
      { id: 1, column_name: 'Upload Date', map_column: 'uploaded_date', type: 'string' },
      { id: 1, column_name: 'Reschedule', map_column: 'reschedule', type: 'string' },
    ],
    rows: initialRowsBlood,
  });
  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const { columns } = DNAtestData;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [DNAtestData]);

  const DNATable = useReactTable({
    data: DNAtestData.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const Bloodcolumns = useMemo(() => {
    const { columns } = BloodtestData;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [BloodtestData]);

  const BloodTable = useReactTable({
    data: BloodtestData.rows,
    columns: Bloodcolumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fechReport = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/reports/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data[0];
        setDNAtestData({ ...DNAtestData, rows: finalData.dna_test });
        setBloodtestData({ ...BloodtestData, rows: finalData.blood_test });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fechReport();
  }, []);

  return (
    <div className="w-full">
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        <div className="items-center flex  border-b">
          <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
            DNA Test
          </div>
        </div>
        <table className="min-w-full table-margin">
          <thead className="sticky top-[0px] items-center ">
            {DNATable?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=" flex  ">
                {headerGroup.headers.map((header, index, array) => {
                  return (
                    <th
                      key={header.id}
                      className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                      ${
                        header.column.id === 'kit_status_timestamp'
                          ? 'w-[20%]'
                          : header.column.id === 'barcode'
                          ? 'w-[20%]'
                          : header.column.id === 'dna_sample_timestamp'
                          ? 'w-[20%]'
                          : header.column.id === 'dna_report'
                          ? 'w-[20%]'
                          : header.column.id === 'dna_status'
                          ? 'w-[20%]'
                          : ''
                      }
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center justify-between w-full">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        {array?.length - 1 === index + 10
                          ? null
                          : {
                              asc: (
                                <img
                                  src={SortIcon}
                                  // height={20}
                                  className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  style={{ transform: 'rotate(180deg)' }}
                                  alt="SortIcon"
                                />
                              ),
                              desc: (
                                <img
                                  src={SortIcon}
                                  className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  alt="SortIcon"
                                />
                              ),
                              false: <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />,
                            }[header.column.getIsSorted()]}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="duration-1000 report-table">
            {DNATable.getRowModel().rows.map((row, rId) => (
              <tr
                key={row.id}
                className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`duration-1000  text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
                    ${
                      cell.column.id === 'kit_status_timestamp'
                        ? 'w-[20%]'
                        : cell.column.id === 'barcode'
                        ? 'w-[20%]'
                        : cell.column.id === 'dna_sample_timestamp'
                        ? 'w-[20%]'
                        : cell.column.id === 'dna_report'
                        ? 'w-[20%]'
                        : cell.column.id === 'dna_status'
                        ? 'w-[20%]'
                        : ''
                    }
                    `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        <div className="items-center flex  border-b">
          <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
            Blood Test
          </div>
        </div>
        <table className="min-w-full table-margin">
          <thead className="sticky top-[0px] items-center ">
            {BloodTable?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=" flex  ">
                {headerGroup.headers.map((header, index, array) => {
                  return (
                    <th
                      key={header.id}
                      className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                      ${
                        header.column.id === 'request_date'
                          ? 'w-[15%]'
                          : header.column.id === 'request_status'
                          ? 'w-[15%]'
                          : header.column.id === 'reason_for_failure'
                          ? 'w-[15%]'
                          : header.column.id === 'generation_date'
                          ? 'w-[20%]'
                          : header.column.id === 'uploaded_date'
                          ? 'w-[20%]'
                          : header.column.id === 'reschedule'
                          ? 'w-[20%]'
                          : ''
                      }
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center justify-between w-full">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        {array?.length - 1 === index + 10
                          ? null
                          : {
                              asc: (
                                <img
                                  src={SortIcon}
                                  // height={20}
                                  className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  style={{ transform: 'rotate(180deg)' }}
                                  alt="SortIcon"
                                />
                              ),
                              desc: (
                                <img
                                  src={SortIcon}
                                  className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  alt="SortIcon"
                                />
                              ),
                              false: <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />,
                            }[header.column.getIsSorted()]}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="duration-1000 report-table">
            {BloodTable.getRowModel().rows.map((row, rId) => (
              <tr
                key={row.id}
                className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`duration-1000  text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
                    ${
                      cell.column.id === 'request_date'
                        ? 'w-[15%]'
                        : cell.column.id === 'request_status'
                        ? 'w-[15%]'
                        : cell.column.id === 'reason_for_failure'
                        ? 'w-[15%]'
                        : cell.column.id === 'generation_date'
                        ? 'w-[20%]'
                        : cell.column.id === 'uploaded_date'
                        ? 'w-[20%]'
                        : cell.column.id === 'reschedule'
                        ? 'w-[20%]'
                        : ''
                    }
                    `}
                  >
                    {cell.column.id === 'reschedule' && cell.row.original.reschedule ? (
                      <div
                        className="text-[#D6A725] cursor-pointer"
                        onClick={() => {
                          setRescheduleTable(cell.row.original.reschedule_history);
                          setOpenModal(true);
                        }}
                      >
                        Yes
                      </div>
                    ) : cell.column.id === 'reschedule' ? (
                      <div>No</div>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <BloodRescheduleTable
        setOpenModal={setOpenModal}
        RescheduleTable={RescheduleTable}
        OpenModal={OpenModal}
      />
    </div>
  );
}

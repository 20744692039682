import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import Button from '../common/Button';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';

const SaveTempletModal = ({
  isOpen,
  closeModal,
  type,
  meal_type_id,
  description,
  setDescVal,
  rec,
  data,
  setRefreshTemp,
  planId,
}) => {
  const [tempName, setTempName] = useState('');

  //console.log("desc", description)

  useEffect(() => {
    setTempName('');
  }, [isOpen]);

  console.log('data', data);
  const saveTemplate = () => {
    if (tempName.trim()?.length === 0) {
      toast.error('Please Enter Template Name');
      return;
    }
    let recipies = [];
    if (rec) {
      recipies = rec?.map((ele) => {
        return {
          recipe_id: ele?.recipe_id,
          servings: ele?.serving,
          measurement_unit: ele?.measurement,
        };
      });
    }

    let payload =
      type === 'Meal Note Wise'
        ? {
            template_name: tempName,
            type: 'Meal Note Wise',
            description: description,
          }
        : {
            template_name: tempName,
            type: 'Section Wise',
            template: [
              {
                meal_type_id: meal_type_id,
                recipes: recipies,
              },
            ],
          };

    if (type === 'Meal Note Wise') {
      payload['meal_type_id'] = meal_type_id;
    }

    if (type === 'Plan Wise') {
      let recipes = [];
      // data?.map((ele) => {
      // return {
      //     "meal_type_id": ele?.meal_type_id,
      //     "recipes": [
      //         {
      //             "recipe_id": 2,
      //             "servings": 2,
      //             "measurement_unit": "Bowl"
      //         }
      //     ],
      // }
      // return  {
      //     "meal_type_id": ele?.meal_type_id,
      //     "recipes": ele?.
      // }

      // return ele?.suggested_recipes?.map((val) => {
      //     return {
      //         "meal_type_id": val?.meal_type_id,
      //         "recipes": [
      //             {
      //                 "recipe_id": val?.recipe_id,
      //                 "servings": val?.serving,
      //                 "measurement_unit": val?.measurement
      //             }
      //         ]
      //     }
      // })
      // })
      for (let i = 0; i < data?.length; i++) {
        let obj = {
          meal_type_id: data[i]?.meal_type_id,
          meal_name: data[i]?.meal_type,
          meal_type: data[i]?.meal_time,
        };
        let arr = [];
        for (let j = 0; j < data[i]?.suggested_recipes?.length; j++) {
          arr.push({
            recipe_id: data[i]?.suggested_recipes[j]?.recipe_id,
            servings: data[i]?.suggested_recipes[j]?.serving,
            measurement_unit: data[i]?.suggested_recipes[j]?.measurement,
          });
        }
        obj['recipes'] = arr;
        recipes.push(obj);
      }
      payload = {
        template_name: tempName,
        type: 'Plan Wise',
        template: recipes,
        plan_id: planId,
      };
    }

    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/save`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };
    axios
      .request(config)
      .then(
        ({ data }) => {
          toast.success(data.message);
          setTempName('');
          closeModal();
          if (setDescVal) {
            setDescVal();
          }
          if (setRefreshTemp) {
            setRefreshTemp((old) => !old);
          }
        },
        (err) => {
          toast.error(err.response.data.message);
        }
      )
      .catch((err) => {
        console('catch', err);
        toast.error(err);
      });
  };

  return (
    <>
      <div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center  text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="2xl:w-[694px] 2xl:h-[240px] xl:w-[536.81px] xl:h-[185.64px] lg:w-[447.34px] lg:h-[154.7px] transform overflow-hidden  rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="div"
                      className="2xl:px-5 xl:px-4 lg:px-3 font-medium justify-between  border-b items-center flex  text-gray-900"
                    >
                      <div></div>
                      <div className=" text-center flex  text-shades-2 2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] 2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px]">
                        Save As Template
                      </div>

                      <div className="flex">
                        <div onClick={closeModal} className=" cursor-pointer">
                          <Close />
                        </div>
                      </div>
                    </Dialog.Title>
                    <div className=" border-b 2xl:py-5 xl:py-[15.47px]  lg:py-[12.89px]">
                      <div className="flex justify-center items-center 2xl:px-10 xl:px-[30.94px] lg:px-[25.78px] ">
                        <div className="text-body-color-2  text-base font-Regular  w-full">
                          Enter Template Name
                        </div>
                        <div className="border-b border-black text-body-color-1  2xl:text-base xl:text-[12.38px] lg:text-[10.31px] font-Medium w-full">
                          <input
                            type="text"
                            className="w-full outline-none"
                            placeholder="Template Name"
                            value={tempName}
                            onChange={(e) => setTempName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" flex justify-center 2xl:py-5 xl:py-[15.47px]  lg:py-[12.89px]">
                      <Button
                        type="button"
                        className=" rounded-small border border-transparent   bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-medium text-white"
                        onClick={saveTemplate}
                      >
                        Save
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

export default SaveTempletModal;

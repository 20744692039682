import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import Button from '../common/Button';
import SelectParameterDropdown from '../common/SelectParameterDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setEditValue, updateTrackerValue } from '../../Store/ProgressAnalysisSlice';
import DatePickerMUI from '../common/DatePickerMUI';

const PrgAnlEditValue = ({ isOpen, closeModal }) => {
  const val = useSelector((state) => state.ProgressAnalysis.editValue);

  const SelectID = useSelector((state) => state.ProgressAnalysis.selectedParameter);

  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const handleOnChange = (e) => {
    dispatch(setEditValue(e.target.value));
  };
  const date = useSelector((state) => state.ProgressAnalysis.selectedDate);
  const updateValue = () => {
    const callBackOnSuccess = () => {
      closeModal();
    };
    dispatch(updateTrackerValue(callBackOnSuccess));
  };
  // useEffect(() => {
  //   if (SelectID && SelectID?.id === 1 && (val.length > 3 || isNaN(val) || parseFloat(val) <= 0)) {
  //     setError('In valid value');
  //   } else {
  //     setError('');
  //   }
  // }, [val, SelectID]);

  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg border-b font-medium leading-6 text-gray-900"
                  >
                    <div className="flex  px-10 justify-between py-4 items-center">
                      <div></div>
                      <div className="text-shades-2 font-normal text-base">Edit Progress Value</div>
                      <div onClick={closeModal} className="2xl:pr-5">
                        <Close className="cursor-pointer 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px] " />
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className="border-b px-9 ">
                    <div className="flex text-body-color-2 py-6 text-xs justify-between items-center">
                      <div className="w-1/2">
                        <h1>Update On</h1>
                      </div>
                      <div className="w-1/2">{date.replace(/-/g, '/')}</div>
                    </div>
                    <div className="flex text-body-color-2 text-xs justify-between items-center">
                      <div className="w-1/2">
                        <h1>Select Parameter</h1>
                      </div>
                      <div className="w-1/2">
                        <SelectParameterDropdown isDisabled={true} />
                      </div>
                    </div>
                    <div className="flex text-body-color-2 py-4 text-xs justify-between items-center">
                      <div className="w-1/2">
                        <h1>Add value</h1>
                      </div>
                      <div className="w-1/2 border-b border-black">
                        <input
                          className="focus:outline-none py-[10px] text-xs font-medium text-black "
                          value={val}
                          onChange={handleOnChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="my-4 flex justify-center">
                    <Button
                      type="button"
                      className="inline-flex justify-center rounded-[10px] bg-shades-2 px-4 py-2 text-xs font-normal text-white "
                      onClick={updateValue}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default PrgAnlEditValue;

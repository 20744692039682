import React, { useState } from 'react';
const ParagraphWithMoreLess = ({ text, limit }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const resultString = isTruncated ? text.slice(0, limit) + '...' : text;
  return (
    <div>
      <p>{resultString}</p>
      <button onClick={() => setIsTruncated(!isTruncated)} className="text-blue-500">
        {isTruncated ? 'View More' : 'View Less'}
      </button>
    </div>
  );
};
export default ParagraphWithMoreLess;

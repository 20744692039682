import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

export const AddUser = ({
  OpenUser,
  setOpenUser,
  Userfor,
  setUserfor,
  headers,
  UserDetails,
  Type,
  getRoles,
  Data,
  setData,
}) => {
  const [Inputs, setInputs] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [Region, setRegion] = useState([]);

  const getInputField = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/role_qa?role_id=${Userfor}`, {
        headers: headers,
      })
      .then((response) => {
        const res = response.data;
        setInputs(res.data);
      })
      .catch((error) => {
        // console.error(error.response.data);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/region`, {
        headers: headers,
      })
      .then((response) => {
        const res = response.data;
        setRegion(res.data);
      })
      .catch((error) => {
        // console.error(error.response.data);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/get_roles`, {
        headers: headers,
      })
      .then((response) => {
        const res = response.data;
        setRoles(res.responce);
      })
      .catch((error) => {
        // console.error(error.response.data);
      });
  };

  useEffect(() => {
    getInputField();
  }, [Userfor]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const addUserForAdmin = () => {
    if (Data.name || Data.email) {
      if (Data.name.length >= 2) {
        if (Data.email) {
          if (emailRegex.test(Data.email)) {
            if (Type === 'add') {
              const modifiedData = { ...Data };
              if (modifiedData.name) {
                modifiedData.name = modifiedData.name.trim();
              }
              if (modifiedData.hasOwnProperty('user_id')) {
                delete modifiedData.user_id;
              }
              axios
                .post(`${process.env.REACT_APP_BASE_URL}/admin/user/add`, modifiedData, {
                  headers: headers,
                })
                .then((response) => {
                  const res = response.data;
                  toast.success(res.message);
                  getRoles();
                  setOpenUser(false);
                  setData({
                    ...Data,
                    name: '',
                    email: '',
                    role_id: Userfor,
                    educational_qualification: '',
                    year_of_experience: '',
                    region: '',
                    phone: '',
                    user_id: '',
                  });
                })
                .catch((error) => {
                  const err = error.response;
                  toast.error(err.data.message);
                });
            } else {
              const modifiedData = { ...Data };
              if (modifiedData.name) {
                modifiedData.name = modifiedData.name.trim();
              }
              axios
                .patch(`${process.env.REACT_APP_BASE_URL}/admin/user/update`, modifiedData, {
                  headers: headers,
                })
                .then((response) => {
                  const res = response.data;
                  toast.success(res.message);
                  getRoles();
                  setOpenUser(false);
                  setData({
                    ...Data,
                    name: '',
                    email: '',
                    role_id: Userfor,
                    educational_qualification: '',
                    year_of_experience: '',
                    region: '',
                    phone: '',
                    user_id: '',
                  });
                })
                .catch((error) => {
                  const err = error.response;
                  toast.error(err.data.message);
                });
            }
          } else {
            toast.error('Email is Incorrect');
          }
        } else {
          toast.error('Email is Empty');
        }
      } else {
        toast.error('The User Name should be 2 to 60 Characters');
      }
    } else {
      toast.error('User Name and Email is Empty');
    }
  };

  return (
    <Transition appear show={OpenUser} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpenUser(false);
          setData({
            ...Data,
            name: '',
            email: '',
            role_id: Userfor,
            educational_qualification: '',
            year_of_experience: '',
            region: '',
            phone: '',
            user_id: '',
          });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-[60%] flex justify-center items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className={`flex justify-center items-center flex-col w-full`}>
                  <Close
                    className="absolute cursor-pointer right-5 top-5"
                    onClick={() => {
                      setOpenUser(false);
                      setData({
                        ...Data,
                        name: '',
                        email: '',
                        role_id: Userfor,
                        educational_qualification: '',
                        year_of_experience: '',
                        region: '',
                        phone: '',
                        user_id: '',
                      });
                    }}
                  />
                  <div className="flex items-center justify-center flex-col">
                    <span className="text-[#515151] text-[25px] font-Semi-Bold">
                      {Type === 'add' ? 'Add' : 'Edit'} User
                    </span>
                  </div>
                  <br />
                  <div className="w-full flex justify-center overflow-auto">
                    <div className="flex flex-col justify-start w-[60%]  h-[75vh]">
                      {Inputs?.map((fields, index) => {
                        if (fields.type === 'input') {
                          return (
                            <>
                              <div key={index} className="flex flex-col">
                                <span className="text-[#9F9F9F]">{fields.name}</span>
                                <input
                                  type={fields.map_id === 'email' ? 'email' : 'text'}
                                  value={Data[fields.map_id]}
                                  style={{
                                    boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                                  }}
                                  className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (fields.map_id === 'phone' && !/^\d*$/.test(inputValue)) {
                                      return;
                                    }
                                    if (
                                      fields.map_id === 'year_of_experience' &&
                                      !/^\d*$/.test(inputValue)
                                    ) {
                                      return;
                                    }
                                    setData((prevData) => ({
                                      ...prevData,
                                      [fields.map_id]:
                                        fields.map_id === 'name'
                                          ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
                                          : fields.map_id === 'email'
                                          ? inputValue.toLowerCase()
                                          : inputValue,
                                    }));
                                  }}
                                />
                              </div>
                              <br />
                            </>
                          );
                        }
                        return null;
                      })}
                      {Inputs?.map((fields, index) => {
                        if (fields.map_id === 'region') {
                          return (
                            <>
                              <div className="flex flex-col">
                                <span className="text-[#9F9F9F]">{fields.name}</span>
                                <select
                                  className="py-[7px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                                  value={Data[fields.map_id]}
                                  style={{
                                    boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                                  }}
                                  onChange={(e) => {
                                    setData((prevData) => ({
                                      ...prevData,
                                      [fields.map_id]: e.target.value,
                                    }));
                                  }}
                                >
                                  <option value="" disabled hidden>
                                    Select an option
                                  </option>
                                  {Region.map((region, index) => (
                                    <option key={index} value={region}>
                                      {region}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <br />
                            </>
                          );
                        }
                        return null;
                      })}
                      <div
                        style={{ boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.07)' }}
                        className="h-[250px] rounded-[20px] border"
                      >
                        <div className="text-[20px] p-[15px] border-b">Select Role</div>
                        <div
                          className="h-[187px] overflow-auto text-[15px]"
                          style={{ borderRadius: '0px 0px 20px 20px' }}
                        >
                          {Array.isArray(Roles) &&
                            Roles.length !== 0 &&
                            Roles?.map((roles, index) => (
                              <div
                                className={`cursor-pointer border-b py-[10px] px-[33px] ${
                                  Userfor === roles.role_id ? 'bg-brand-color text-white' : ''
                                } `}
                                onClick={() => {
                                  setUserfor(roles.role_id);
                                  setData({
                                    ...Data,
                                    role_id: roles.role_id,
                                  });
                                }}
                              >
                                <Tooltip
                                  title={roles.role_name.length > 30 ? roles.role_name : ''}
                                  placement="top"
                                  arrow
                                >
                                  <span>
                                    {roles.role_name.length > 30
                                      ? `${roles.role_name.slice(0, 30)}...`
                                      : roles.role_name}
                                  </span>
                                </Tooltip>
                              </div>
                            ))}
                        </div>
                      </div>
                      <br />
                      <div className="flex flex-col items-center w-full">
                        <button
                          type="submit"
                          className="p-[5px] w-[60%] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                          onClick={() => {
                            addUserForAdmin();
                          }}
                        >
                          {Type === 'add' ? 'Add' : 'Update'} User
                        </button>
                        <br />
                        <button
                          className="p-[5px] w-[60%] rounded-[10px] text-brand-color transition-colors hover:bg-[#F3EBD6] outline-none"
                          onClick={() => {
                            setOpenUser(false);
                            setData({
                              ...Data,
                              name: '',
                              email: '',
                              role_id: Userfor,
                              educational_qualification: '',
                              year_of_experience: '',
                              region: '',
                              phone: '',
                              user_id: '',
                            });
                          }}
                        >
                          Discard
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

import React from 'react';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import RacipeHeader from './RacipeHeader';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SortIcon from '../../assets/images/des.png';
import { useState } from 'react';

const defaultData = [
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
  {
    name: 'Nutrition name',
    quantity: '2.5',
    servings: '100Gm',
  },
];

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    cell: (info) => info.getValue(),
    header: () => (
      <div className="flex justify-between 2xl:gap-10   2xl:px-5">
        <div>
          <span className=""> Name</span>
        </div>
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.quantity, {
    id: 'quantity',
    cell: (info) => (
      <span>
        {info.getValue()} {info.row.original.unit}
      </span>
    ),
    header: () => (
      <div className="flex justify-between 2xl:gap-10   2xl:px-5">
        <div>
          <span>Quantity</span>
        </div>
      </div>
    ),
  }),

  columnHelper.accessor((row) => row.servings, {
    id: 'servings',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => (
      <div>
        <span>Servings</span>
      </div>
    ),
  }),
];

const IngredientsUsed = ({ ingData }) => {
  // console.log("data", ingData)
  const [data, setData] = React.useState(() => [...ingData]);

  const [sorting, setSorting] = useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <RacipeHeader heading={'Ingredients Used'} />
      <div className="2xl:mt-[20px] xl:mt-[15px] lg:mt-[13px]">
        <div className="ModalTable">
          <table className="min-w-[-webkit-fill-available]">
            <thead className="bg-tint-1/20">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="flex items-center">
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="w-1/3 first-of-type:border-l  2xl:py-[9px] 2xl:px-5 xl:py-[6px] xl:px-4 lg:py-[4px] lg:px-3 text-left text-body-color-1  border-r last-of-type:border-r-0 font-Medium  Rounded_class  2xl:text-SmallBodyDisplayText xl:text-base lg:text-sm"
                    >
                      <div className="flex justify-between items-center">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        <div className="cursor-pointer">
                          {
                            {
                              asc: (
                                <img
                                  src={SortIcon}
                                  // width={20}
                                  // height={20}
                                  className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  style={{ transform: 'rotate(180deg)' }}
                                  alt="SortIcon"
                                />
                              ),
                              desc: (
                                <img
                                  src={SortIcon}
                                  className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  alt="SortIcon"
                                />
                              ),
                              false: <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />,
                            }[header.column.getIsSorted()]
                          }
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="2xl:h-[580px] xl:h-[470px] lg:h-[385px] overflow-auto block">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="clienttable_row mt-[4px] even:bg-secondary-3/20 flex items-center"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="w-1/3 first-of-type:border-l border-r 2xl:py-[9px] 2xl:px-5 xl:py-[6px] xl:px-4 lg:py-[4px] lg:px-3  text-left text-body-color-2  font-Regular 2xl:text-SmallerBodyDisplayText xl:text-sm lg:text-xs"
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default IngredientsUsed;

import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as Select } from '../../assets/images/select_for_role.svg';
import { ReactComponent as DeSelect } from '../../assets/images/deselect.svg';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const CreateNewRole = ({
  NewRole,
  setNewRole,
  headers,
  Sections,
  setSections,
  TypeForRole,
  setTypeForRole,
  RoleDetails,
  setRoleDetails,
  RoleId,
  getRoles,
}) => {
  const [Text, setText] = useState('');
  const maxWordCount = 500;

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= maxWordCount) {
      setText(newText);
      setRoleDetails({ ...RoleDetails, role_description: newText });
    }
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text/plain');
    const currentText = Text || '';
    const newText = currentText + pastedText.substring(0, maxWordCount - currentText.length);
    event.preventDefault();

    setText(newText);
    setRoleDetails((prevRoleDetails) => ({
      ...prevRoleDetails,
      role_description: newText,
    }));
  };

  const wordCount = Text ? Text.length : 0;

  const getSections = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/section`, {
        headers: headers,
      })
      .then((response) => {
        const res = response.data;
        setSections(res.data);
      })
      .catch((error) => {
        // console.error(error.response.data);
      });
  };

  const addUpdatePermission = () => {
    const filteredSection = Sections.filter(
      (section) =>
        section.can_view || section.can_create || section.can_update || section.can_delete
    ).map(({ name, ...rest }) => rest);
    const body = {
      role_name: RoleDetails.role_name,
      role_description: RoleDetails.role_description,
      role_permissions: filteredSection,
    };

    if (RoleDetails.role_name || filteredSection.length !== 0) {
      if (RoleDetails.role_name) {
        if (RoleDetails.role_name.length >= 3) {
          if (filteredSection.length !== 0) {
            if (TypeForRole === 'add') {
              axios
                .post(`${process.env.REACT_APP_BASE_URL}/admin/user/role`, body, {
                  headers: headers,
                })
                .then((response) => {
                  const res = response.data;
                  setNewRole(false);
                  setSections([]);
                  setRoleDetails({ ...RoleDetails, role_description: '', role_name: '' });
                  getRoles();
                  setTypeForRole('');
                  toast.success(res.message);
                })
                .catch((error) => {
                  const message = error.response.data;
                  console.error(error.response.data);
                  toast.error(message.message);
                });
            } else {
              axios
                .patch(`${process.env.REACT_APP_BASE_URL}/admin/user/role/${RoleId}`, body, {
                  headers: headers,
                })
                .then((response) => {
                  const res = response.data;
                  setNewRole(false);
                  setSections([]);
                  setRoleDetails({ ...RoleDetails, role_description: '', role_name: '' });
                  getRoles();
                  setTypeForRole('');
                  toast.success(res.message);
                })
                .catch((error) => {
                  const message = error.response.data;
                  console.error(error.response.data);
                  toast.error(message.message);
                });
            }
          } else {
            toast.error('Please Provide Access Feature');
          }
        } else {
          toast.error('Invalid name. Name should be in the range of 3 to 60 characters.');
        }
      } else {
        toast.error('Role Name is Mandatory');
      }
    } else {
      toast.error('Role Name and Access Features are Empty');
    }
  };

  useEffect(() => {
    if (TypeForRole === 'add') {
      getSections();
    }
  }, [TypeForRole]);

  useEffect(() => {
    if (RoleDetails) {
      setText(RoleDetails.role_description);
    }
  }, [RoleDetails]);

  return (
    <Transition appear show={NewRole} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setNewRole(false);
          setSections([]);
          setRoleDetails({ ...RoleDetails, role_description: '', role_name: '' });
          setTypeForRole('');
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-[60%] flex justify-center items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className={`flex justify-center items-center flex-col w-full`}>
                  <Close
                    className="absolute cursor-pointer right-5 top-5"
                    onClick={() => {
                      setNewRole(false);
                      setSections([]);
                      setRoleDetails({ ...RoleDetails, role_description: '', role_name: '' });
                      setTypeForRole('');
                    }}
                  />
                  <div className="flex items-center justify-center flex-col">
                    <span className="text-[#515151] text-[25px] font-Semi-Bold">
                      {TypeForRole === 'add' ? 'Create New' : 'Update'} Role
                    </span>
                  </div>
                  <br />
                  <div className="w-full flex justify-center overflow-auto">
                    <div className="flex flex-col justify-start w-[60%] h-[75vh]">
                      <div className="flex flex-col">
                        <span className="text-[#9F9F9F]">Role Name</span>
                        <input
                          type="text"
                          placeholder="e.g. Manager"
                          style={{
                            boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                          }}
                          className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                          value={RoleDetails.role_name}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setRoleDetails({
                              ...RoleDetails,
                              role_name: inputValue.charAt(0).toUpperCase() + inputValue.slice(1),
                            });
                          }}
                        />
                      </div>
                      <br />
                      <div className="flex flex-col">
                        <span className="text-[#9F9F9F]">Role Description (500 words)</span>
                        <div className="relative mt-[10px]">
                          <textarea
                            className="rounded-[10px] max-h-[140px] min-h-[140px] border border-[#E6E6E6] p-[10px] w-full resize-none outline-none"
                            placeholder="Write description here ....."
                            value={Text}
                            style={{
                              boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                            }}
                            onChange={handleTextChange}
                            onPaste={handlePaste}
                          />
                          <div className="absolute right-[3px] bottom-[-18px] text-[14px] text-[#CCC]">
                            {wordCount}/{maxWordCount}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div
                        style={{ boxShadow: '4px 4px 8px 2px rgba(0, 0, 0, 0.07)', marginTop: 5 }}
                        className="h-[365px] rounded-[20px] border"
                      >
                        <div className="text-[18px] p-[15px] border-b">Access Features</div>
                        <div
                          className="h-[295px] overflow-auto text-[13px]"
                          style={{ borderRadius: '0px 0px 20px 20px' }}
                        >
                          {Sections?.map((pages, index) => (
                            <div className={`border-b py-[10px] px-[33px] flex justify-between`}>
                              <div
                                className="cursor-pointer flex justify-center items-center"
                                onClick={() => {
                                  setSections((prevSections) => {
                                    const updatedSections = [...prevSections];
                                    updatedSections[index] = {
                                      ...updatedSections[index],
                                      can_view: !updatedSections[index].can_view,
                                    };

                                    if (
                                      updatedSections[index].name === 'Ingredients Panel' &&
                                      updatedSections[index].can_view === false
                                    ) {
                                      const name = updatedSections.findIndex(
                                        (section) => section.name === 'Import/Export Ingredients'
                                      );
                                      updatedSections[name].can_create = false;
                                      updatedSections[name].can_view = false;
                                      updatedSections[name].can_delete = false;
                                      updatedSections[name].can_update = false;
                                    }

                                    if (
                                      updatedSections[index].name === 'Import/Export Ingredients' &&
                                      updatedSections[index].can_view === true
                                    ) {
                                      const ingredientPanelIndex = updatedSections.findIndex(
                                        (section) => section.name === 'Ingredients Panel'
                                      );
                                      updatedSections[ingredientPanelIndex] = {
                                        ...updatedSections[ingredientPanelIndex],
                                        can_view: true,
                                      };
                                      updatedSections[index].can_create = true;
                                      updatedSections[index].can_update = true;
                                      updatedSections[index].can_delete = true;
                                    }

                                    if (
                                      updatedSections[index].name === 'Recipe Panel' &&
                                      updatedSections[index].can_view === false
                                    ) {
                                      const name = updatedSections.findIndex(
                                        (section) => section.name === 'Import/Export Recipes'
                                      );
                                      updatedSections[name].can_create = false;
                                      updatedSections[name].can_view = false;
                                      updatedSections[name].can_delete = false;
                                      updatedSections[name].can_update = false;
                                    }

                                    if (
                                      updatedSections[index].name === 'Import/Export Recipes' &&
                                      updatedSections[index].can_view === true
                                    ) {
                                      const ingredientPanelIndex = updatedSections.findIndex(
                                        (section) => section.name === 'Recipe Panel'
                                      );
                                      updatedSections[ingredientPanelIndex] = {
                                        ...updatedSections[ingredientPanelIndex],
                                        can_view: true,
                                      };
                                      updatedSections[index].can_create = true;
                                      updatedSections[index].can_update = true;
                                      updatedSections[index].can_delete = true;
                                    }

                                    if (updatedSections[index].name === 'App Access') {
                                      updatedSections[index].can_create = true;
                                      updatedSections[index].can_update = true;
                                      updatedSections[index].can_delete = true;
                                    }

                                    if (!updatedSections[index].can_view) {
                                      updatedSections[index].can_create = false;
                                      updatedSections[index].can_update = false;
                                      updatedSections[index].can_delete = false;
                                    }
                                    return updatedSections;
                                  });
                                }}
                              >
                                {pages.can_view ? (
                                  <Select className="mr-2" />
                                ) : (
                                  <DeSelect className="mr-2" />
                                )}
                                {pages.name}
                              </div>
                              <div className="flex">
                                <div
                                  className={`cursor-pointer flex justify-center items-center`}
                                  onClick={() => {
                                    setSections((prevSections) => {
                                      const updatedSections = [...prevSections];
                                      updatedSections[index] = {
                                        ...updatedSections[index],
                                        can_create: !updatedSections[index].can_create,
                                        can_update: !updatedSections[index].can_create,
                                        can_delete: !updatedSections[index].can_create,
                                      };

                                      if (
                                        updatedSections[index].name === 'Import/Export Ingredients'
                                      ) {
                                        updatedSections[index].can_view = false;
                                      }

                                      if (
                                        updatedSections[index].name ===
                                          'Import/Export Ingredients' &&
                                        updatedSections[index].can_create === true
                                      ) {
                                        const name = updatedSections.findIndex(
                                          (section) => section.name === 'Ingredients Panel'
                                        );
                                        updatedSections[name].can_view = true;
                                        updatedSections[name].can_delete = true;
                                        updatedSections[name].can_update = true;
                                      }

                                      if (updatedSections[index].name === 'Import/Export Recipes') {
                                        updatedSections[index].can_view = false;
                                      }

                                      if (
                                        updatedSections[index].name === 'Import/Export Recipes' &&
                                        updatedSections[index].can_create === true
                                      ) {
                                        const name = updatedSections.findIndex(
                                          (section) => section.name === 'Recipe Panel'
                                        );
                                        updatedSections[name].can_view = true;
                                        updatedSections[name].can_delete = true;
                                        updatedSections[name].can_update = true;
                                      }

                                      if (updatedSections[index].name === 'App Access') {
                                        updatedSections[index].can_view = false;
                                      }

                                      if (updatedSections[index].can_create) {
                                        updatedSections[index].can_view = true;
                                      }
                                      return updatedSections;
                                    });
                                  }}
                                >
                                  {pages.can_create ? (
                                    <Select className="mr-2" />
                                  ) : (
                                    <DeSelect className="mr-2" />
                                  )}
                                  Create
                                </div>
                                <div
                                  className={`cursor-pointer flex justify-center items-center ml-2`}
                                  onClick={() => {
                                    setSections((prevSections) => {
                                      const updatedSections = [...prevSections];
                                      updatedSections[index] = {
                                        ...updatedSections[index],
                                        can_view: !updatedSections[index].can_view,
                                      };

                                      if (
                                        updatedSections[index].name === 'Ingredients Panel' &&
                                        updatedSections[index].can_view === false
                                      ) {
                                        const name = updatedSections.findIndex(
                                          (section) => section.name === 'Import/Export Ingredients'
                                        );
                                        updatedSections[name].can_create = false;
                                        updatedSections[name].can_view = false;
                                        updatedSections[name].can_delete = false;
                                        updatedSections[name].can_update = false;
                                      }

                                      if (
                                        updatedSections[index].name ===
                                          'Import/Export Ingredients' &&
                                        updatedSections[index].can_view === true
                                      ) {
                                        const ingredientPanelIndex = updatedSections.findIndex(
                                          (section) => section.name === 'Ingredients Panel'
                                        );
                                        updatedSections[ingredientPanelIndex] = {
                                          ...updatedSections[ingredientPanelIndex],
                                          can_view: true,
                                        };
                                        updatedSections[index].can_create = true;
                                        updatedSections[index].can_update = true;
                                        updatedSections[index].can_delete = true;
                                      }

                                      if (
                                        updatedSections[index].name === 'Recipe Panel' &&
                                        updatedSections[index].can_view === false
                                      ) {
                                        const name = updatedSections.findIndex(
                                          (section) => section.name === 'Import/Export Recipes'
                                        );
                                        updatedSections[name].can_create = false;
                                        updatedSections[name].can_view = false;
                                        updatedSections[name].can_delete = false;
                                        updatedSections[name].can_update = false;
                                      }

                                      if (
                                        updatedSections[index].name === 'Import/Export Recipes' &&
                                        updatedSections[index].can_view === true
                                      ) {
                                        const ingredientPanelIndex = updatedSections.findIndex(
                                          (section) => section.name === 'Recipe Panel'
                                        );
                                        updatedSections[ingredientPanelIndex] = {
                                          ...updatedSections[ingredientPanelIndex],
                                          can_view: true,
                                        };
                                        updatedSections[index].can_create = true;
                                        updatedSections[index].can_update = true;
                                        updatedSections[index].can_delete = true;
                                      }

                                      if (updatedSections[index].name === 'App Access') {
                                        updatedSections[index].can_create = true;
                                        updatedSections[index].can_update = true;
                                        updatedSections[index].can_delete = true;
                                      }

                                      if (!updatedSections[index].can_view) {
                                        updatedSections[index].can_create = false;
                                        updatedSections[index].can_update = false;
                                        updatedSections[index].can_delete = false;
                                      }
                                      return updatedSections;
                                    });
                                  }}
                                >
                                  {pages.can_view ? (
                                    <Select className="mr-2" />
                                  ) : (
                                    <DeSelect className="mr-2" />
                                  )}
                                  View
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <br />
                      <div className="flex flex-col items-center w-full">
                        <button
                          className="p-[5px] w-[60%] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                          onClick={() => {
                            addUpdatePermission();
                          }}
                        >
                          {TypeForRole === 'add' ? 'Add' : 'Update'} Role
                        </button>
                        <br />
                        <button
                          className="p-[5px] w-[60%] rounded-[10px] text-brand-color transition-colors hover:bg-[#F3EBD6] outline-none"
                          onClick={() => {
                            setNewRole(false);
                            setSections([]);
                            setRoleDetails({ ...RoleDetails, role_description: '', role_name: '' });
                            setTypeForRole('');
                          }}
                        >
                          Discard
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchOrgnisationData,
  fetchProgressData,
  getOrgData,
  setOrgId,
  setPrgOrgId,
} from '../../Store/ClientTableSlice';

export default function DropDown() {
  const [selected, setSelected] = useState('All Organization');

  const dispatch = useDispatch();
  const setOrgData = useSelector((state) => [
    ...state.ClientTable.fetchOrgnisationData.data,
    { id: '', org_name: 'All Organization' },
  ]);

  useEffect(() => {
    dispatch(fetchOrgnisationData());
  }, []);

  const SelectOrgPerson = (person) => {
    setSelected(person.org_name);
    dispatch(setPrgOrgId(person.id));
    dispatch(fetchProgressData());
  };

  return (
    <div className="">
      <Listbox
        value={selected}
        onChange={(data) => SelectOrgPerson(data)}
        className="relative inline-block bg-white"
      >
        <div className="relative ">
          <Listbox.Button className="relative w-auto justify-center  2xl:py-[10px] 2xl:px-3 lg:py-[13px] lg:px-[10px] md:py-[4px] md:px-[6px]  shadow-input-shadow border border-secondary-3 2xl:rounded-small lg:rounded-lg md:rounded-md hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <div className="flex justify-between items-center ">
              <div>
                <span className=" lg:w-[135px] md:w-[90px] justify-start flex  2xl:text-base xl:text-sm lg:text-xs text-body-color-2 ">
                  <span
                    className=" text-left text-ellipsis whitespace-nowrap inline-block overflow-hidden"
                    style={{ width: '95%' }}
                  >
                    {selected}
                  </span>
                </span>
              </div>
              <div>
                <UpArray className="ui-not-open:transform ui-not-open:rotate-180" />
              </div>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className=" overflow-y-scroll absolute z-10  mt-[2px] 2xl:w-[200px] 2xl:h-[220px]  lg:w-[170px] lg:h-[185px] md:w-[130px] md:h-[150px] origin-top-center  divide-gray-100 rounded-lg  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className=" md:px-[2px] md:py-[2px]">
                {setOrgData?.map((person, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `text-ellipsis whitespace-nowrap w-full cursor-pointer border-b 2xl:py-[10px] 2xl:px-5 xl:py-[8px] xl:px-[16px] lg:py-[8px] lg:px-[16px]  2xl:text-base xl:text-sm lg:text-xs`
                    }
                    value={person}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {person.org_name}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

import React from 'react';
import PagesHeader from '../shared/PagesHeader';
import ClientTableHeader from '../shared/ClientTableHeader';

const Home = () => {
  return (
    <div className="">
      <PagesHeader name="Verification Panel" secondHeading={"Nutrition Panel"} thirdHeading={"Verification Panel"} />
      <ClientTableHeader />
    </div>
  );
};

export default Home;

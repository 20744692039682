import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { getToken } from '../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const defaultClient = {
  Customer_Id: -1,
  ClientName: -1,
  EmailAddress: -1,
  DateCreated: -1,
  Status: -1,
  UserId: -1,
};

const initialProgg = Array(12).fill({
  customer_id: -1,
  user_name: -1,
  user_email: -1,
  user_phone: -1,
  org_name: -1,
  last_updated: -1,
});

const defaultClients = [
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
  defaultClient,
];

let initialState = {
  clientsTableData: defaultClients,
  progressTracker: [],
  isError: false,
  fetchOrgnisationData: { data: [] },
  orgId: '',
  totalCount: 0,
  searchQuery: '',
  isLoading: false,
  isNewSearch: false,
  getDataStatus: 'idle',
  dataCount: 0,
  finalCount: 0,
  progressTrackerSearch: '',
  isNewSearchPrg: false,
  prgOrgId: '',
  prgCurrentIndex: 1,
  clientName: '',
  PostDataAdd: [],
};

export const fetchProgressData = createAsyncThunk(
  'fetchProgressData',
  async (data, { getState }) => {
    try {
      const token = getToken();
      const config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/users?page=${
          getState().ClientTable.prgCurrentIndex
        }&size=100&search=${getState().ClientTable.progressTrackerSearch}&org_id=${
          getState().ClientTable.prgOrgId
        }`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.request(config);
      return response.data.data;
    } catch (err) {
      if (err.response.data.code === '401' || err.response.data.message === 'Invalid token !!') {
        const navigate = useNavigate();
        localStorage.clear();
        navigate('/login');
        // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
      }
      throw err;
    }
  }
);

export const fetchOrgnisationData = createAsyncThunk('fetchOrgnisationData', async () => {
  try {
    const token = getToken();
    const config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/user/getOrgList`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.request(config);
    return response.data;
  } catch (err) {
    const navigate = useNavigate();
    localStorage.clear();
    navigate('/login');
    // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
    toast.error(err.message);
    throw err;
  }
});

// export const PostAddData = createAsyncThunk('PostAddData', async () => {
//   try {
//     let token = getToken();
//     const config = {
//       baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/add_manual`,
//       method: 'post',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };
//     const response = await axios.post(config);
//     return response.data;
//   } catch (err) {
//     toast.error(err.message);
//   }
// });

export const ClientTableSlice = createSlice({
  name: 'ClientTableSlice',
  initialState: {
    ...initialState,
  },

  reducers: {
    updateQuery: (data, action) => {
      data.progressTrackerSearch = action.payload;
      data.progressTracker[0].plans = initialProgg;
    },
    updateResults: (data, action) => {
      data.progressTracker = action.payload;
    },
    resetDeaultClient: (data, action) => {
      data.clientsTableData = defaultClients;
    },
    setClientsTable: (data, action) => {
      data.clientsTableData = action.payload;
    },
    setOrgId: (data, action) => {
      data.orgId = action.payload;
    },
    setTotalCount: (data, action) => {
      data.totalCount = action.payload;
    },
    setSearchQuery: (data, action) => {
      data.searchQuery = action.payload.searchQuery;
      data.isNewSearch = action.payload.isNewSearch;
    },

    setPrgOrgId: (data, action) => {
      data.prgOrgId = action.payload;
    },
    setPrgCurrentIndex: (data, action) => {
      data.prgCurrentIndex = action.payload;
    },
    setClientName: (data, action) => {
      data.clientName = action.payload;
    },
    setPostAddData: (data, action) => {
      data.PostDataAdd = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProgressData.fulfilled, (data, action) => {
        data.getDataStatus = 'suc';
        data.progressTracker = action.payload;
        data.dataCount = action.payload[0].total_count;
        data.finalCount = action.payload[0].given_records;
        data.isLoading = false;
      })
      .addCase(fetchProgressData.pending, (data, action) => {
        data.isLoading = true;
      })
      .addCase(fetchProgressData.rejected, (data, action) => {
        data.isError = true;
      })
      .addCase(fetchOrgnisationData.rejected, (data, action) => {
        alert('Promise Rejec');
      })
      .addCase(fetchOrgnisationData.fulfilled, (data, action) => {
        data.getDataStatus = 'suc';
        data.fetchOrgnisationData = action.payload;
      });
  },
});

export const {
  updateQuery,
  updateResults,
  setClientsTable,
  setOrgId,
  setTotalCount,
  setSearchQuery,
  resetDeaultClient,
  setTotalCounts,
  setprogressTrackerSearch,
  setPrgOrgId,
  setPrgCurrentIndex,
  setClientName,
  setPostAddData,
  isLoading,
} = ClientTableSlice.actions;
export default ClientTableSlice.reducer;

export const getprogressTracker = (state) => {
  return state.ClientTable.progressTracker.length > 0
    ? state.ClientTable.progressTracker[0].plans
    : initialProgg;
};

export const getOrgData = (state) => {
  return state.ClientTable.fetchOrgnisationData.data;
};

export const getClientName = (state) => {
  return state.ClientTable.clientName;
};

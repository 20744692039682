import React, { Fragment } from 'react';
import { useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ReactComponent as Expand } from '../../assets/images/expand_more_dark.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken } from '../../Utils/Authenticate';

const MeasuringDisclose = ({
  Measurement,
  masterMeasurement = [],
  selectedMesurement,
  setSelectedMesurement,
  count,
  planId,
  meal_type_id,
  recipe_id,
  setEnableApprove,
  setnutritionProfile,
  setOverlay,
  setRefreshData,
  setServing,
  setUserData,
}) => {
  const [open, setOpen] = useState(false);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    for (let i = 0; i < masterMeasurement.length; i++) {
      const { measurement_unit } = masterMeasurement[i];
      if (measurement_unit === Measurement) {
        setSelectedMesurement(masterMeasurement[i]);
      }
    }
  }, [masterMeasurement, Measurement]);

  return (
    <>
      <div>
        <div className="  lg:w-36 md:w-32 ">
          <Listbox
            value={selectedMesurement}
            onChange={(value) => {
              console.log('value', value);
              let token = getToken();
              let config = {
                baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/update_recipe_serving`,
                method: 'PATCH',
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json',
                },
                data: {
                  plan_id: planId,
                  meal_type_id: meal_type_id,
                  recipe_id: recipe_id,
                  serving: count,
                  type: 'measurement',
                  unit: value.measurement_unit,
                  measurement: value,
                },
              };
              setOverlay(true);
              axios
                .request(config)
                .then(({ data }) => {
                  setUserData((prevUserData) => {
                    return prevUserData.map((item) => {
                      if (item.meal_type_id === meal_type_id) {
                        return {
                          ...item,
                          nutrition_profile: data.data[0].nutrient_profile,
                          suggested_recipes: item.suggested_recipes.map((recipe) => {
                            if (recipe.recipe_id === recipe_id) {
                              return { ...recipe, measurement: data.data[0].measurement };
                            }
                            return recipe;
                          }),
                        };
                      }
                      return item;
                    });
                  });
                  // toast.success(data.message);
                  setOverlay(false);
                  setRefreshData((old) => !old);
                })
                .catch((err) => {
                  toast(err);
                });
              setOpen((prevVal) => !prevVal);
              setSelectedMesurement(value);
              setChanged(!changed);
            }}
          >
            <div className="relative ">
              <Listbox.Button
                className="relative w-full cursor-default rounded bg-white 2xl:py-[7px]  xl:py-[4px]  lg:py-[4px]   md:px-[6px] text-left   2xl:text-xs xl:text-[10px] lg:text-[8px] font-Regular "
                onClick={() => setOpen(!open)}
              >
                <div className="flex justify-between items-center truncate">
                  <div>{selectedMesurement?.measurement_unit}</div>
                  <div>
                    <Expand className="ui-open:transform ui-open:rotate-180 lg:w-3 lg:h-3 md:w-1 md:h-1" />
                  </div>
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <div className=" text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute mt-1 border-b z-10 w-full overflow-auto rounded bg-white    2xl:text-xs xl:text-[10px] lg:text-[8px] font-Regular">
                  {masterMeasurement &&
                    masterMeasurement?.map((value, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none lg:py-[8px] lg:px-4 md:py-[6px] md:px-3 ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={value}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {value.measurement_unit}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center  text-amber-600">
                                <div className="" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
    </>
  );
};

export default MeasuringDisclose;

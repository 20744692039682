import { Dialog, Transition } from '@headlessui/react';

import React from 'react';
import { Fragment } from 'react';
import Button from '../common/Button';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CLOSING } from 'ws';

const DialogModel = ({ openis, closeis, TimeDiet, setCallApi, CallApi }) => {
  const { planId } = useParams();
  return (
    <div>
      <Transition appear show={openis} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeis}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="2xl:w-[600px] 2xl:h-[334px]  lg:w-[450px] md:w-[400px]  2xl:px-[40px] 2xl:py-[64px] xl:px-[30.94px] xl:py-[49.5px] lg:py-[41.5px] lg:px-[25.74px]  transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="h3"
                    className="text-xl  font-medium justify-center flex text-body-color-1"
                  >
                    Approve Diet Plan
                  </Dialog.Title>

                  <div className="2xl:mt-5 xl:mt-[15.67px] lg:mt-[13.3px]">
                    <p className="text-base  text-center text-body-color-2">
                      Are you sure you want to approve diet plan of {TimeDiet} for the user? You can
                      make changes later in case of any new updates.
                    </p>
                  </div>

                  <div className="2xl:mt-10 xl:mt-[31.95px] lg:mt-[29.13px] flex justify-center gap-5">
                    <Button
                      className={classNames('bg-white text-shades-2 border text-base ')}
                      onClick={closeis}
                    >
                      Deny
                    </Button>
                    <Button
                      className={classNames('text-white bg-shades-2 text-base ')}
                      onClick={() => {
                        let token = getToken();
                        let config = {
                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/approve_plan`,
                          method: 'POST',
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                          data: {
                            plan_id: parseInt(planId),
                          },
                        };

                        axios
                          .request(config)
                          .then(
                            ({ data }) => {
                              toast.success(data.message);
                              setCallApi(CallApi + 1);
                              closeis();
                            },
                            (err) => {
                              const message = err.response.data.message;
                              // const formattedMessage = message.replace(/(\([^()]+\))/g, (match) => {
                              //   const innerContent = match.substring(1, match.length - 1);
                              //   const lines = innerContent.split(',').map((item) => item.trim());
                              //   return lines.join('<br>');
                              // });
                              // debugger;
                              // toast.error(<pre>{formattedMessage}</pre>);
                              const multilineToast = (message) => {
                                // Extract the portion of the string within parentheses
                                const match = message.match(/(.*?)\(([^)]+)\)(.*)/);
                                if (!match) {
                                  toast.error(message);
                                  return;
                                }
                                // Split the contents of the parentheses by comma
                                const lines = match[2].split(',').map((line, i) => (
                                  <div className="text-brand-color" key={i}>
                                    {line.trim()}
                                  </div>
                                ));
                                // Create the toast with the text before, inside and after the parentheses
                                toast(
                                  <div className="text-sm">
                                    {match[1].trim()}
                                    {lines}
                                    {match[3].trim()}
                                  </div>,
                                  {
                                    // Other toast options if needed
                                  }
                                );
                              };
                              multilineToast(message);
                              closeis();
                            }
                          )
                          .catch((err) => {
                            toast.error(err);
                          });
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default DialogModel;

import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import Button from '../common/Button';
import MealNoteModal from './MealNoteModal';
import { getToken } from '../../Utils/Authenticate';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ClipLoader, HashLoader } from 'react-spinners';
import ParagraphWithMoreLess from '../shared/ViewMore';

const ViewNotes = ({ isOpen, closeModal, userId, planId, type, setOpenNote }) => {
  const [openmeal, setOpenMeal] = useState(false);
  const [storeplan, setStorePlan] = useState([]);
  const [refresh, setrefresh] = useState(true);
  const [hasData, setHasData] = useState(true);
  const [overlay, setOverlay] = useState(false);
  useEffect(() => {
    //if (!isOpen) return;

    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/notes?user_id=${userId}&plan_id=${planId}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then(
        ({ data }) => {
          setStorePlan(data.data);
          setHasData(true);
        },
        (err) => {
          setStorePlan([]);
          if (isOpen) {
            closeModal();
            opneMealNote();
          }
        }
      )
      .catch((err) => {
        toast.error(err);
      });
  }, [refresh, isOpen]);

  // useEffect(() => {
  //   // debugger
  //   if (isOpen) {
  //     if (storeplan?.length == 0) {
  //       closeModal()
  //       opneMealNote()
  //     }
  //   }
  // }, [isOpen, storeplan])

  useEffect(() => {
    if (isOpen) {
      if (storeplan?.length == 0) {
        closeModal();
        opneMealNote();
      }
    }
  }, [hasData]);

  function opneMealNote() {
    setOpenMeal(true);
  }

  function CloseNoteModal() {
    setOpenMeal(false);
    // setOpenNote((old) => !old);
  }

  const adminData = useSelector((state) => state.UserData.adminData);

  return (
    <div>
      {overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
        </div>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="2xl:w-[694px] 2xl:h-[566px] xl:w-[536.81px] xl:h-[437.8px] lg:w-[447.34px] lg:h-[364.83px]  transform overflow-hidden 2xl:rounded-2xl lg:rounded-xl md:rounded-lg bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="  font-medium justify-between    items-center border-b flex  text-gray-900"
                  >
                    <div></div>
                    <div className="2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] font-medium justify-between  border-b items-center flex 2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px] text-shades-2">
                      Meal Notes
                    </div>
                    <div onClick={closeModal} className=" 2xl:pr-5 xl:pr-4 lg:pr-3">
                      <Close className="cursor-pointer 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px] " />
                    </div>
                  </Dialog.Title>

                  <div className="border-b 2xl:pt-[10px] 2xl:pb-[20px] xl:pt-[7.7px] xl:pb-[15.47px] lg:pb-[12.89px] lg:pt-[6.69px]  2xl:px-10 xl:px-[30.94px] lg:px-[25.78px]  2xl:h-[410px] xl:h-[300px] lg:h-[250px]  overflow-y-auto">
                    {storeplan?.map((value, id) => {
                      return (
                        <>
                          <div
                            className=" 2xl:py-[10px] xl:py-[7.73px] lg:py-[6.05px] border-b"
                            key={id}
                          >
                            <ul className="list-outside ">
                              <li className=" 2xl:text-SmallRegular xl:text-[9.28px] lg:text-[7.74px] list-disc break-all break-words font-Medium text-body-color-1 list-outside ">
                                {value.description?.length > 100 ? (
                                  <ParagraphWithMoreLess text={value.description} limit={100} />
                                ) : (
                                  value.description
                                )}

                                <div className="flex   justify-between items-center 2xl:pr-5 xl:pr-[16px] lg:pr-[14px]">
                                  <div className=" 2xl:text-SmallRegular xl:text-[9.28px] lg:text-[7.74px] font-Regular text-body-color-2 mt-[2px] items-center">
                                    {value.date +
                                      ' , Day ' +
                                      value.day_number +
                                      ' - ' +
                                      value.section_name}
                                  </div>
                                  <div>
                                    <Delete
                                      className="cursor-pointer"
                                      onClick={() => {
                                        let token = getToken();
                                        let config = {
                                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/notes?id=${value.id}`,
                                          method: 'DELETE',
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                          },
                                        };
                                        axios
                                          .request(config)
                                          .then(
                                            ({ data }) => {
                                              toast.success(data.message);
                                              setrefresh(!refresh);
                                            },
                                            (err) => {
                                              setrefresh(!refresh);
                                            }
                                          )
                                          .catch((err) => {
                                            toast.error(`${err}`);
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </>
                      );
                    })}
                  </div>

                  <div className=" flex gap-5 justify-center 2xl:py-5 xl:pt-[15.47px]  lg:pt-[12.89px]">
                    <Button
                      type="button"
                      className="inline-flex justify-center rounded-small border  bg-white 2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-base    font-Regular outline-none text-shades-2"
                      onClick={() => {
                        setOverlay(true);
                        let url = '';
                        let token = getToken();
                        let config = {
                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/download/meal_notes`,
                          method: 'POST',
                          data: {
                            user_id: userId,
                            admin_user_id: adminData.user_id,
                            plan_id: planId,
                          },
                          headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-type': 'application/json',
                          },
                        };
                        axios
                          .request(config)
                          .then(({ data }) => {
                            // debugger;
                            setOverlay(false);
                            //console.log(data.data[0].report_url)
                            url = data.data[0].report_url;
                            window.open(data.data[0].report_url, '_blank');
                            toast.success('Meal Notes Downloaded Successfully');
                            //window.location.href =
                          })
                          .catch((err) => {
                            toast.error(err);
                            setOverlay(false);
                          })
                          .finally(() => {
                            setOverlay(false);
                          });

                        // fetch(url).then(response => {
                        //   response.blob().then(blob => {
                        //     // Creating new object of PDF file
                        //     const fileURL = window.URL.createObjectURL(blob);
                        //     // Setting various property values
                        //     let alink = document.createElement('a');
                        //     alink.href = fileURL;
                        //     console.log(fileURL)
                        //     // alink.download = fileURL;
                        //     alink.target = "_blank"
                        //     alink.click();

                        //   })
                        // })
                      }}
                    >
                      Download As PDF
                    </Button>
                    <Button
                      type="button"
                      className="inline-flex justify-center rounded-small border  bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]     font-medium text-white"
                      onClick={() => {
                        opneMealNote();
                        closeModal();
                      }}
                    >
                      Add New Note
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <MealNoteModal
        Openis={openmeal}
        Closeis={CloseNoteModal}
        type={type}
        setRefresh={setrefresh}
        setOpenNote={setOpenNote}
      />
    </div>
  );
};

export default ViewNotes;

import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Down } from '../../assets/images/down.svg';
import { ReactComponent as Edit } from '../../assets/images/editfordash.svg';
import { ReactComponent as Delete } from '../../assets/images/delete.svg';
import { ReactComponent as Add } from '../../assets/images/add_icon.svg';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getToken } from '../../Utils/Authenticate';
import PagesHeader from '../shared/PagesHeader';
import { AddUser } from '../shared/AddUser';
import { CreateNewRole } from '../shared/CreateNewRole';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

export default function RoleManagement() {
  const token = getToken();
  const openCardRef = useRef(null);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);
  const rolesPer = permission?.role_permissions?.find(
    (permission) => permission.name === 'Role Management'
  );

  const [OpenUser, setOpenUser] = useState(false);
  const [Loader, setLoader] = useState(true);
  const [UserDetails, setUserDetails] = useState({});
  const [Sections, setSections] = useState([]);
  const [RoleId, setRoleId] = useState('');
  const [RoleDetails, setRoleDetails] = useState({
    role_name: '',
    role_description: '',
  });
  const [Type, setType] = useState('');
  const [TypeForRole, setTypeForRole] = useState('');
  const [NewRole, setNewRole] = useState(false);
  const [Userfor, setUserfor] = useState(0);
  const [Roles, setRoles] = useState([]);
  const [CardOpenStates, setCardOpenStates] = useState([]);
  const [Data, setData] = useState({
    name: '',
    email: '',
    role_id: Userfor,
    educational_qualification: '',
    year_of_experience: '',
    region: '',
    phone: '',
    user_id: '',
  });
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleFeatures = (index) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [index]: !prevExpandedRows[index],
    }));
  };

  const toggleCardOpen = (index) => {
    setCardOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
    if (!CardOpenStates[index]) {
      setExpandedRows((prevExpandedRows) => {
        const newExpandedRows = {};
        for (const key in prevExpandedRows) {
          newExpandedRows[key] = false;
        }
        return newExpandedRows;
      });
    }
  };

  const getRoles = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/user_with_roles`, { headers: headers })
      .then((response) => {
        const res = response.data;
        setRoles(res.data);
        if (res.data.length !== Roles.length) {
          setCardOpenStates(Array(res.data.length).fill(false));
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const deleteUser = (userId) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/admin/user/remove?user_id=${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const res = response.data;
        toast.success(res.message);
        getRoles();
      })
      .catch((error) => {
        const err = error.response.data;
        toast.error(err.message);
      });
  };

  useEffect(() => {
    setLoader(true);
    getRoles();
  }, []);

  return (
    <div>
      <PagesHeader
        name="Role Management"
        secondHeading={'Role Management'}
        thirdHeading={'Role Management'}
        setNewRole={setNewRole}
        setTypeForRole={setTypeForRole}
        roles={rolesPer}
      />
      <div className="px-5 py-5 bg-white shadow-card-shadow rounded-medium table-card overflow-auto">
        {Loader ? (
          Array.from({ length: 15 }, (_, index) => (
            <Skeleton key={index} width="100%" height="6%" baseColor="#e9d39633" />
          ))
        ) : Roles.length !== 0 ? (
          Roles?.map((roles, index) => (
            <Card
              key={roles.role_id}
              ref={index === Roles.length - 1 ? openCardRef : null}
              className="font-poppins"
              sx={{
                minWidth: 300,
                borderRadius: '10px',
                background: '#FFF',
                boxShadow: 'none',
                margin: '7px 0px',
                boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.14)',
              }}
            >
              <CardHeader
                title={
                  <Tooltip
                    title={roles.role_name.length > 60 ? roles.role_name : ''}
                    placement="top"
                    arrow
                  >
                    <span>
                      {roles.role_name.length > 60
                        ? `${roles.role_name.slice(0, 60)}...`
                        : roles.role_name}
                    </span>
                  </Tooltip>
                }
                id={`card-header-${index}`}
                sx={{
                  backgroundColor: '#FFF',
                  padding: '10px 15px',
                  borderRadius: '10px',
                  '& .MuiCardHeader-action': {
                    margin: 0,
                  },
                  position: 'relative',
                  zIndex: 1,
                }}
                titleTypographyProps={{
                  style: {
                    color: '#343434',
                    fontSize: '15px',
                    fontFamily: 'Poppins',
                  },
                }}
                action={
                  <div id="scroll-container" className="flex justify-center items-center">
                    {rolesPer?.can_create && (
                      <>
                        <div
                          className="flex cursor-pointer justify-center items-center pl-[5px] pr-[10px] py-1 rounded-[10px] text-body-color-3 bg-brand-color"
                          onClick={() => {
                            setOpenUser(true);
                            setUserfor(roles.role_id);
                            setData({
                              ...Data,
                              name: '',
                              email: '',
                              role_id: roles.role_id,
                              educational_qualification: '',
                              year_of_experience: '',
                              region: '',
                              phone: '',
                              user_id: '',
                            });
                            setType('add');
                          }}
                          style={{ boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.12)' }}
                        >
                          <Add className="mr-[5px]" /> Add User
                        </div>
                        <Edit
                          className="w-5 h-5 cursor-pointer ml-[20px] mr-[10px]"
                          onClick={() => {
                            setNewRole(true);
                            setSections(roles.role_permissions);
                            setRoleId(roles.role_id);
                            setTypeForRole('edit');
                            setRoleDetails({
                              ...RoleDetails,
                              role_name: roles.role_name,
                              role_description: roles.role_description,
                            });
                          }}
                          style={{ marginRight: roles?.users.length > 0 ? '' : 35 }}
                        />
                      </>
                    )}
                    {roles?.users && roles?.users.length > 0 && (
                      <div
                        className={`cursor-pointer transition-transform duration-300 transform ${
                          CardOpenStates[index] ? 'rotate-180' : ''
                        }`}
                        onClick={() => {
                          toggleCardOpen(index);
                        }}
                      >
                        <Down />
                      </div>
                    )}
                  </div>
                }
              />
              <Collapse
                in={CardOpenStates[index] && roles?.users && roles?.users.length > 0}
                sx={{ position: 'relative', zIndex: 0, top: '-7px' }}
                timeout="auto"
                unmountOnExit
              >
                <CardContent
                  sx={{
                    borderRadius: '0px 0px 10px 10px',
                    padding: '7px 0px 0px !important',
                    backgroundColor: '#fafafa',
                  }}
                >
                  <div>
                    <table className="w-full table-auto mb-5">
                      <thead className="bg-[#F4F4F4]">
                        <th className="border-r p-3">Name</th>
                        <th className="border-l border-r p-3">Created By</th>
                        <th className="border-l border-r p-3">Created Date</th>
                        <th className="border-l border-r p-3">E-mail</th>
                        <th className="border-l p-3 w-[40%]">Access Features</th>
                        <th className="w-[3%]"></th>
                      </thead>
                      <tbody className="text-[14px]">
                        {roles?.users?.map((user, index) => (
                          <tr
                            key={index}
                            style={{ backgroundColor: index % 2 === 0 ? '' : '#f2f2f2' }}
                            className="cursor-pointer"
                          >
                            {['name', 'created_by', 'created_date', 'email', 'access_features'].map(
                              (field, cellIndex) => (
                                <td
                                  key={cellIndex}
                                  onClick={() => {
                                    if (rolesPer?.can_create) {
                                      if (field !== 'access_features') {
                                        setOpenUser(true);
                                        setUserDetails(user);
                                        setData({
                                          ...Data,
                                          role_id: roles.role_id,
                                          email: user.email === 'NA' ? '' : user.email,
                                          name: user.name === 'NA' ? '' : user.name,
                                          educational_qualification:
                                            user.educational_qualification === 'NA'
                                              ? ''
                                              : user.educational_qualification,
                                          region: user.region === 'NA' ? '' : user.region,
                                          year_of_experience:
                                            user.year_of_experience === 'NA'
                                              ? ''
                                              : user.year_of_experience,
                                          phone: user.phone === 'NA' ? '' : user.phone,
                                          user_id: user.user_id,
                                        });
                                        setUserfor(roles.role_id);
                                        setType('edit');
                                      }
                                    }
                                  }}
                                  className={`border-${cellIndex > 0 ? 'l ' : 'r '}p-3`}
                                >
                                  {field === 'access_features' ? (
                                    <>
                                      {expandedRows[user.user_id] ? (
                                        <>
                                          {user[field].join(', ')}
                                          {user[field].length > 4 && (
                                            <button
                                              onClick={() => toggleFeatures(user.user_id)}
                                              className="text-blue-500 ml-2 outline-none"
                                            >
                                              View Less
                                            </button>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {user[field].slice(0, 4).join(', ')}
                                          {user[field].length > 4 && (
                                            <button
                                              onClick={() => toggleFeatures(user.user_id)}
                                              className="text-blue-500 ml-2 outline-none"
                                            >
                                              View More
                                            </button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    user[field]
                                  )}
                                </td>
                              )
                            )}
                            <td>
                              {user.email !== permission.email && rolesPer?.can_create && (
                                <Delete
                                  onClick={() => {
                                    if (roles?.users.length === 1) {
                                      setCardOpenStates(Array(Roles?.length).fill(false));
                                    }
                                    deleteUser(user.user_id);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Collapse>
            </Card>
          ))
        ) : (
          <div className="flex items-center justify-center">
            <ClientError className="w-[50%] h-[50%]" />
          </div>
        )}
      </div>
      <AddUser
        OpenUser={OpenUser}
        setOpenUser={setOpenUser}
        Userfor={Userfor}
        setUserfor={setUserfor}
        headers={headers}
        UserDetails={UserDetails}
        Type={Type}
        getRoles={getRoles}
        Data={Data}
        setData={setData}
      />
      <CreateNewRole
        NewRole={NewRole}
        setNewRole={setNewRole}
        headers={headers}
        Sections={Sections}
        setSections={setSections}
        TypeForRole={TypeForRole}
        setTypeForRole={setTypeForRole}
        RoleDetails={RoleDetails}
        setRoleDetails={setRoleDetails}
        getRoles={getRoles}
        RoleId={RoleId}
      />
    </div>
  );
}

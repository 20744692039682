import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { getToken } from '../../Utils/Authenticate';
import { useParams } from 'react-router-dom';
import Button from '../common/Button';
import { Divider } from '@mui/material';
import axios from 'axios';
import TemplateImport from '../common/TemplateImport';
import NewTemplate from '../layout/AddNewTemplate';
import { toast } from 'react-toastify';

export default function ConsolutionGuideCopy({ fetchGuideLines, GuideLines, verification }) {
  let { userId } = useParams();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState('');
  const [NameOfGuide, setNameOfGuide] = useState();
  const [selectedValue, setSelectedValue] = useState('');
  const [NewTemplateModal, setNewTemplateModal] = useState(false);
  const [EditGuideline, setEditGuideline] = useState(false);
  const [EditId, setEditId] = useState();
  const [ClearText, setClearText] = useState(0);
  let accessToken = getToken();
  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    const contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContent(contentHtml);
  };
  const customToolbar = {
    options: ['inline'],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
  };
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const optionTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const addNewGuideLine = () => {
    const guidelineBody = {
      user_id: userId,
      name: NameOfGuide,
      guideline: content,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/guideline`, guidelineBody, {
        headers: headers,
      })
      .then((response) => {
        toast.success(response.data.message);
        setEditorState(EditorState.createEmpty());
        setNameOfGuide('');
        fetchGuideLines();
      })
      .catch((error) => {
        // console.error('Error adding guideline:', error);
        toast.error(error.response.data.message);
      });
  };

  const editGuideLine = () => {
    const guidelineBody = {
      name: NameOfGuide,
      guideline: content,
    };
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/guideline/${EditId}`,
        guidelineBody,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setEditGuideline(false);
        setEditId('');
        setContent('');
        setEditorState(EditorState.createEmpty());
        setNameOfGuide('');
        fetchGuideLines();
      })
      .catch((error) => {
        // console.error('Error updating guideline:', error);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    const guidelineBody = {
      user_id: userId,
      template_id: selectedValue.id,
    };
    if (selectedValue) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/import_guideline`,
          guidelineBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          toast.success(response.data.message);
          fetchGuideLines();
          setSelectedValue('');
        })
        .catch((error) => {
          // console.error('Error adding guideline:', error);
          toast.error(error.response.data.message);
        });
    }
  }, [selectedValue]);

  useEffect(() => {
    if (ClearText > 0) {
      setContent('');
      setEditorState(EditorState.createEmpty());
      setNameOfGuide('');
    }
  }, [ClearText]);

  return (
    <>
      <div
        as="h3"
        className=" font-medium justify-center items-center border-b flex  text-gray-900"
      >
        <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] text-shades-2 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px] border-r">
          View Guidelines
        </div>
        <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] text-shades-2 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px]">
          Add Guidelines
        </div>
      </div>
      <div className="content-diet-plan2">
        <div className="font-medium flex h-full text-gray-900">
          <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px] border-r overflow-auto">
            {GuideLines && GuideLines.length > 0 ? (
              <ul className="w-full">
                {GuideLines.map((item, index) => (
                  <div
                    className={`${
                      EditId === item.id ? 'w-[100%] bg-[#80808080] pointer-events-none ' : ''
                    } flex border-b p-[10px]`}
                  >
                    <div className="pr-[5px]">{index + 1}.</div>
                    <div className="w-[100%]">
                      <div
                        className="text-justify text-[14px]"
                        dangerouslySetInnerHTML={{ __html: item.guideline }}
                      ></div>
                      <div className="flex justify-between text-[#616161] text-[10px] text-justify mt-[5px]">
                        <div>
                          {item.created_by},{' '}
                          {new Intl.DateTimeFormat('en-US', options).format(
                            new Date(item.created_at)
                          )}{' '}
                          {new Intl.DateTimeFormat('en-US', optionTime).format(
                            new Date(item.created_at)
                          )}
                        </div>
                        {verification?.can_create && (
                          <div className="flex justify-between">
                            <Edit
                              className="cursor-pointer mr-[10px]"
                              onClick={() => {
                                setEditGuideline(true);
                                const blocksFromHtml = htmlToDraft(item.guideline);
                                const contentState =
                                  ContentState.createFromBlockArray(blocksFromHtml);
                                const newEditorState = EditorState.createWithContent(contentState);
                                setEditorState(newEditorState);
                                setContent(item.guideline);
                                setNameOfGuide(item.name);
                                setEditId(item.id);
                              }}
                            />
                            <Delete
                              onClick={() => {
                                axios
                                  .delete(
                                    `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/guideline/${item.id}`,
                                    {
                                      headers: headers,
                                    }
                                  )
                                  .then((response) => {
                                    fetchGuideLines();
                                    setEditGuideline(false);
                                    setEditId('');
                                    setContent('');
                                    setNameOfGuide('');
                                  })
                                  .catch((error) => {
                                    // console.error('delete', error);
                                    toast.error(error.response.data.message);
                                  });
                              }}
                              className="cursor-pointer"
                            />
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                ))}
              </ul>
            ) : (
              <div className="text-[#919191]">
                Import from template or add new guidelines to view here.
              </div>
            )}
          </div>
          <div className="w-1/2 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px]">
            <div className=" px-[10px]">
              <div className="flex justify-between items-center text-[14px]">
                Select from templates
                <TemplateImport
                  setSelectedValue={setSelectedValue}
                  verification={verification}
                  selectedValue={selectedValue}
                />
              </div>
              <div className="flex text-[#919191] pt-[15px] pb-[5px] justify-center items-center">
                <div
                  style={{
                    width: 32,
                    height: 1,
                    background: '#E6E6E6',
                  }}
                ></div>
                <div className="text-[12px]"> or add new </div>
                <div
                  style={{
                    width: 32,
                    height: 1,
                    background: '#E6E6E6',
                  }}
                ></div>
              </div>
              <div className="flex justify-between items-center text-[14px]">
                Name of the guideline
                <div className="relative inline-block bg-white w-1/2">
                  <input
                    value={NameOfGuide}
                    disabled={!verification?.can_create}
                    onChange={(e) => {
                      setNameOfGuide(e.target.value);
                    }}
                    placeholder="Name of the guideline"
                    className='relative border-b w-auto justify-center text-[11.852px] 2xl:py-[10px] lg:py-[13px] md:py-[4px} min-w-[250px] hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"'
                  />
                </div>
              </div>
              <div className="mt-[15px] text-[14px]">Guidelines</div>
            </div>
            <div>
              <div>
                <Editor
                  readOnly={!verification?.can_create}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName h-[40px]"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName border-b note"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={customToolbar}
                  placeholder="Enter your text here"
                  style={{ padding: 10 }}
                />
              </div>
              {verification?.can_create && (
                <div className="flex justify-center items-center lg:h-[48px] xl:h-[50px]">
                  <div className="text-shades-2 text-base mr-[10px]">
                    <Button
                      disabled={!verification?.can_create}
                      onClick={() => {
                        if (verification?.can_create) {
                          if (!NameOfGuide) {
                            toast.error('Name of the Guideline is required');
                          } else if (!content) {
                            toast.error('Guideline is required');
                          } else {
                            setNewTemplateModal(true);
                          }
                        }
                      }}
                      className="cursor-pointer flex justify-center items-center h-[30px] text-[12px] rounded-[10px] border-[1px] border-[#999]"
                    >
                      Save As Template
                    </Button>
                  </div>
                  <NewTemplate
                    NewTemplateModal={NewTemplateModal}
                    setNewTemplateModal={setNewTemplateModal}
                    content={content}
                    NameOfGuide={NameOfGuide}
                    setClearText={setClearText}
                    ClearText={ClearText}
                  />
                  <Button
                    disabled={!verification?.can_create}
                    className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                    onClick={() => {
                      if (verification?.can_create) {
                        if (EditGuideline === false) addNewGuideLine();
                        else editGuideLine();
                      }
                    }}
                  >
                    {EditGuideline ? 'Edit Guideline' : 'Add Guideline'}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

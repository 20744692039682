import React, { useEffect, useRef } from 'react';
import { ReactComponent as Home } from '../../assets/images/home.svg';
import { ReactComponent as DropRightIcon } from '../../assets/images/DropRightIcon.svg';
import { ReactComponent as Add } from '../../assets/images/add_icon.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ExpandIcon } from '../../assets/images/zoom_out_map.svg';
import { ReactComponent as CollapseIcon } from '../../assets/images/zoom_in_map.svg';
import { useState } from 'react';

const PagesHeader = ({
  name,
  array,
  secondHeading,
  thirdHeading,
  setNewRole,
  setTypeForRole,
  roles,
}) => {
  const navigate = useNavigate();
  const titleRef = useRef(null);
  const handleResize = () => {
    let titleHeight = 0;
    if (titleRef.current) {
      titleHeight = titleRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--title-height', titleHeight);
    }
  };

  const [isFull, setIsFull] = useState(false);

  const openFullScreen = () => {
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE/Edge */
      document.msExitFullscreen();
    }
  }
  function isFullscreen() {
    return (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const nameCss = name === 'Verification Panel' ? `xl:text-2xl lg:text-xl md:text-sm` : `text-2xl`;
  const paddingCss =
    name === 'Verification Panel' ? `py-[20px]` : `2xl:py-[20px] xl:py-[10px] lg:py-[14px]`;
  const optionsCss =
    name === 'Verification Panel' ? `2xl:text-base xl:text-sm lg:text-xs` : `text-base`;

  return (
    <>
      <div ref={titleRef} className={`flex justify-between items-center py-[10px]`}>
        <div className={`flex items-center gap-0`}>
          {array ? (
            <div
              onClick={() => {
                if (name === 'Add Ingredient' || name === 'Edit Ingredient') {
                  navigate('/nutrition');
                } else {
                  navigate(-1);
                }
              }}
              className="flex items-center gap-5 xl:h-4 xl:w-4 lg:h-[26.67px] lg:w-[26.67px] md:w-[10px] md:h-[10px] mr-4"
            >
              {array}
            </div>
          ) : (
            <></>
          )}

          <div className={`font-Medium text-body-color-1 lg:text-2xl lg:leading-[36px]`}>
            {name}
          </div>
        </div>
        {name === 'Role Management' && roles?.can_create && (
          <button
            className="flex justify-center items-center pl-[5px] py-[5px] pr-[10px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
            style={{ boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.12)' }}
            onClick={() => {
              setNewRole(true);
              setTypeForRole('add');
            }}
          >
            <Add className="mr-[5px]" /> Add Role
          </button>
        )}
        {/* <div className="flex items-center justify-center gap-[17px] ">
          <Home className="cursor-pointer  xl:h-4 xl:w-4 lg:h-[17.78px] lg:w-[17.78px] md:w-[10px] md:h-[10px]" />
          <DropRightIcon className="   xl:h-4 xl:w-4 lg:h-[17.78px] lg:w-[17.78px] md:w-[10px] md:h-[10px] " />
          <div className={`font-Regular text-body-color-2 ${optionsCss} cursor-pointer`}>
            {secondHeading}
          </div>
          <DropRightIcon className="  xl:h-4 xl:w-4 lg:h-[17.78px] lg:w-[17.78px] md:w-[10px] md:h-[10px]" />
          <div className={`font-Regular text-body-color-2 ${optionsCss} cursor-pointer`}>
            {thirdHeading}
          </div>
        </div> */}
      </div>

      <button
        onClick={() => {
          if (!isFullscreen()) {
            openFullScreen();
          } else {
            closeFullscreen();
          }
          setIsFull((prev) => !prev);
        }}
        className={`fixed ${
          array ? 'bottom-[50px]' : 'bottom-[50px]'
        } right-2 hover:scale-125 duration-500`}
      >
        {isFull ? <CollapseIcon /> : <ExpandIcon />}
      </button>
    </>
  );
};

export default PagesHeader;

import React, { Fragment, useState } from 'react';
import { ReactComponent as Close } from '../../../../assets/images/close.svg';
import { ReactComponent as MacroSelect } from '../../../../assets/images/select_for_role.svg';
import { ReactComponent as MacroDeSelect } from '../../../../assets/images/deselect.svg';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const CustomMeasurement = ({
  headers,
  CusotmPop,
  setCusotmPop,
  getMeasurements,
  IngridientDetails,
  setIngridientDetails,
}) => {
  const [CustomValue, setCustomValue] = useState({
    name: '',
    value: '',
    unit: '',
    is_multiply: false,
  });
  const units = [
    {
      display_value: 'Mililitre',
      map_value: 'ml',
    },
    {
      display_value: 'Litre',
      map_value: 'l',
    },
    {
      display_value: 'Miligram',
      map_value: 'mg',
    },
    {
      display_value: 'Gram',
      map_value: 'gm',
    },
    {
      display_value: 'Kilogram',
      map_value: 'kg',
    },
    {
      display_value: 'Inch',
      map_value: 'inch',
    },
  ];

  const addCustomMeasurement = () => {
    if (CustomValue.name) {
      if (CustomValue.value) {
        if (CustomValue.unit) {
          axios
            .post(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/units`, CustomValue, {
              headers: headers,
            })
            .then((response) => {
              const getData = response.data;
              setIngridientDetails((prevDetails) => {
                const existingIndex = prevDetails.measurement.findIndex(
                  (item) => item.id === '' && item.name === '' && item.value === ''
                );

                if (existingIndex !== -1) {
                  return {
                    ...prevDetails,
                    measurement: prevDetails.measurement.map((item, index) =>
                      index === existingIndex ? getData.data[0] : item
                    ),
                  };
                } else {
                  return {
                    ...prevDetails,
                    measurement: [...prevDetails.measurement, getData.data[0]],
                  };
                }
              });
              setCusotmPop(false);
            })
            .catch((error) => {
              const getData = error.response.data;
              toast.error(getData.message);
            });
        } else {
          toast.error('Select a Unit');
        }
      } else {
        toast.error('Value should be greater than 0');
      }
    } else {
      toast.error('Name is Empty');
    }
  };

  return (
    <Transition appear show={CusotmPop} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        onClose={() => {
          // setCusotmPop(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" w-[30%] flex flex-col items-center transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center head border-b w-full p-4 2xl:text-[18px] xl:text-[16px] lg:text-[14px] font-semibold">
                  Add Custom Measurements
                  <Close
                    className="cursor-pointer"
                    onClick={() => {
                      setCusotmPop(false);
                    }}
                  />
                </div>
                <div className="main-content w-full">
                  <div className="text-[#6F6F6F] 2xl:text-[18px] xl:text-[16px] lg:text-[14px] p-3 text-center">
                    Add detail about the custom measurements
                  </div>
                  <div className="form flex flex-col px-6 pt-4">
                    <span className="text-[#6F6F6F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px]">
                      Name
                      <input
                        className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                        type="text"
                        placeholder="Cup"
                        value={CustomValue.name}
                        onChange={(e) => {
                          setCustomValue({ ...CustomValue, name: e.target.value });
                        }}
                      />
                    </span>
                    <br />
                    <span className="text-[#6F6F6F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px]">
                      Value
                      <div className="border border-[#E6E6E6] py-[5px] px-[10px] rounded-[10px]">
                        <input
                          className="w-full outline-none border-b"
                          type="text"
                          placeholder="in gm"
                          value={CustomValue.value}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            if (/^\d*$/.test(newValue)) {
                              setCustomValue({ ...CustomValue, value: Number(newValue) });
                            }
                          }}
                        />
                      </div>
                    </span>
                    <br />
                    <span className="text-[#6F6F6F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px]">
                      Unit
                      <select
                        className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                        type="text"
                        placeholder="e.g Millilitre"
                        value={CustomValue.unit}
                        onChange={(e) => {
                          setCustomValue({ ...CustomValue, unit: e.target.value });
                        }}
                      >
                        <option value="" disabled>
                          Choose
                        </option>
                        {units.map((unit) => (
                          <option value={unit.map_value}>{unit.display_value}</option>
                        ))}
                      </select>
                    </span>
                    <br />
                    <div
                      className="flex text-[#6F6F6F] items-center 2xl:text-[18px] xl:text-[16px] lg:text-[14px] cursor-pointer"
                      onClick={() => {
                        setCustomValue((prevCustomValue) => ({
                          ...prevCustomValue,
                          is_multiply: !prevCustomValue.is_multiply,
                        }));
                      }}
                    >
                      {!CustomValue.is_multiply ? (
                        <MacroDeSelect className="mr-[5px]" />
                      ) : (
                        <MacroSelect className="mr-[5px]" />
                      )}
                      is the unit multipliable ?
                    </div>
                    <br />
                    <div className="flex justify-center">
                      <div
                        className="cursor-pointer text-center w-[80%] text-[#FFFFFF] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] py-[5px]"
                        onClick={() => {
                          addCustomMeasurement();
                        }}
                      >
                        Confirm
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

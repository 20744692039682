import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';

import Auth from './components/Auth';
import AppRoutes from './Router/AppRoutes';
// import { getToken } from './Utils/Authenticate';
// import DietPlan from './components/pages/DietPlanPage';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as Logo } from './assets/images/logo.svg';

// import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import { Login } from './components/Authentication/Login';
import { PrivateComponent } from './Router/ProtectedRoute';
import { ResetPassword } from './components/Authentication/ResetPassword';
import { Delete } from './components/Authentication/DeleteUser';

const ErrorComponent = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100vh',
    }}
  >
    <Logo />
    <p style={{ paddingTop: 10 }}>This site is not accessible on mobile devices</p>
  </div>
);

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 1000;
      setIsMobile(isMobileView);
    };

    const checkUserAgent = () => {
      if (
        /Android/.test(window.navigator.userAgent) ||
        /iPhone|iPad|iPod/.test(window.navigator.userAgent)
      ) {
        setIsError(true);
      }
    };

    handleResize();
    checkUserAgent();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get('page');
    const isThereAToken = searchParams.get('auth-token');
    if (page) {
      navigate(`/${page}`);
    } else if (isThereAToken) {
      navigate('/');
    }
  }, [location.search, navigate]);

  if (isMobile && location.pathname !== '/delete-user') {
    return <ErrorComponent />;
  }

  if (isError && location.pathname !== '/delete-user') {
    return <ErrorComponent />;
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/delete-user" element={<Delete />}></Route>
        <Route element={<PrivateComponent />}>
          <Route path="/*" element={<AppRoutes />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import { useEffect, useState, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import axios from 'axios';

export default function RecipePanel({ headers, userId }) {
  const fetchHistory = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/consultations/${userId}`, {
        headers: headers,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    // fetchHistory();
  }, []);

  const uniqueStyles = `
  li:nth-child(odd) {
    background: #e6e6e633;
  }
`;

  return <div className="w-full">hello</div>;
}

import { Dialog, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { Fragment } from 'react';
import Button from '../common/Button';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { CLOSING } from 'ws';

const NewTemplate = ({
  setNewTemplateModal,
  NewTemplateModal,
  content,
  NameOfGuide,
  setClearText,
  ClearText,
}) => {
  const { planId } = useParams();
  const [TemplateName, setTemplateName] = useState();
  let accessToken = getToken();

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const saveAsTemplate = () => {
    const guidelineBody = {
      guideline: content,
      name: NameOfGuide,
      template_name: TemplateName,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/save_guideline`,
        guidelineBody,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setNewTemplateModal(false);
        setTemplateName('');
        setClearText(ClearText + 1);
      })
      .catch((error) => {
        // console.error('Error adding guideline:', error.response.data.message);
        toast.error(`${error.response.data.message}`, {
          closeButton: false,
        });
      });
  };

  return (
    <div>
      <Transition appear show={NewTemplateModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setNewTemplateModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="2xl:h-[334px] w-[447px] transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="h3"
                    className=" font-medium justify-center items-center flex  text-gray-900 border-b"
                  >
                    <div className="2xl:text-2xl xl:text-[18.59px] lg:text-[15.47px] text-shades-2 2xl:p-5 xl:p-[10px] lg:p-[12.89px]">
                      Save as Template
                    </div>
                    <Close
                      className="cursor-pointer absolute right-[20px] h-[9px]"
                      onClick={() => {
                        setNewTemplateModal(false);
                      }}
                    />
                  </Dialog.Title>

                  <div className="flex justify-between items-center p-[15px] border-b">
                    <p className="text-[14px]">Enter Template Name</p>
                    <div className="relative inline-block bg-white w-1/2 ">
                      <input
                        value={TemplateName}
                        onChange={(e) => {
                          setTemplateName(e.target.value);
                        }}
                        placeholder="Template Name"
                        className='relative border-b w-auto text-[10.313px] justify-center text-xs 2xl:py-[10px] lg:py-[13px] md:py-[4px] min-w-[195px] hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"'
                      />
                    </div>
                  </div>

                  <div className="flex justify-center gap-5 my-[12px]">
                    <div
                      className="cursor-pointer rounded-[10px] text-white bg-shades-2 text-base px-[40px] py-[4px] text-[14px]"
                      onClick={() => {
                        if (!TemplateName) {
                          toast.error('Name of Template is required');
                          return;
                        }
                        saveAsTemplate();
                      }}
                    >
                      Save
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default NewTemplate;

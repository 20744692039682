import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

const Footer = ({ open }) => {
  const navigate = useNavigate();
  const [state, setState] = useState('Active');
  const [count, setCount] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [ElapsedTime, setElapsedTime] = useState(0);

  const onIdle = () => {
    localStorage.clear();
    navigate('/login');
  };

  const onActive = () => {
    setState('Active');
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = Math.ceil(getRemainingTime() / 1000);
      setRemaining(remainingTime);
      const elapsed = 15 * 60 - remainingTime;
      setElapsedTime(elapsed);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60);
    const seconds = milliseconds % 60;

    const padZero = (num) => (num < 10 ? `0${num}` : num);

    return `${padZero(minutes)}:${padZero(seconds)}`;
  };
  return (
    <>
      <div
        className={`  ${
          open ? 'pl-80' : 'pl-40 '
        }  flex justify-center bg-[#FFFFFF] fixed left-0 bottom-0  w-[100%] z-[1]`}
      >
        <h1 className="2xl:py-[7px] lg:pt-[5.19px] lg:pb-[5.41px] text-body-color-2 font-Regular 2xl:text-SmallerBodyDisplayText 2xl:leading-[30px] lg:text-[14.8148px] lg:leading-[22px]">
          Powered by unlock.fit{' '}
          <span className="bg-brand-color text-black">
            You have been inactive for {formatTime(ElapsedTime)} mins, you will be logged out in{' '}
            {formatTime(remaining)} mins.
          </span>
        </h1>
      </div>
    </>
  );
};

export default Footer;

import { useEffect, useState, useMemo } from 'react';
import { ReactComponent as Tag } from '../../../assets/images/tagged.svg';
import { ReactComponent as UnTag } from '../../../assets/images/untagged.svg';
import SortIcon from '../../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import axios from 'axios';

export default function Referal({ headers, userId }) {
  const initialRows = Array(15).fill({
    referred_name: -1,
    remainder_sent: -1,
    referral_status: -1,
    referral_date: -1,
    coupon_code_sent: -1,
    reward_received: -1,
    reward_redeemed: -1,
  });
  const [Refer, setRefer] = useState({
    columns: [
      { id: 0, column_name: 'Referred Name', map_column: 'referred_name', type: 'string' },
      { id: 1, column_name: `Reminder's Sent`, map_column: 'remainder_sent', type: 'string' },
      { id: 1, column_name: 'Referral Status', map_column: 'referral_status', type: 'string' },
      { id: 1, column_name: 'Referral Date', map_column: 'referral_date', type: 'string' },
      { id: 1, column_name: 'Coupon code sent', map_column: 'coupon_code_sent', type: 'string' },
      { id: 1, column_name: 'Rewards Received', map_column: 'reward_received', type: 'string' },
      { id: 1, column_name: 'Reward Redeemed', map_column: 'reward_redeemed', type: 'string' },
    ],
    rows: initialRows,
  });
  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => {
    const { columns } = Refer;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Refer]);

  const table = useReactTable({
    data: Refer.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchReferal = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/referrals/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data;
        setRefer({ ...Refer, rows: finalData });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchReferal();
  }, []);

  return (
    <div className="w-full">
      <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
        <div className="items-center flex  border-b">
          <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
            Referral History
          </div>
        </div>
        <table className="min-w-full table-margin">
          <thead className="sticky top-[0px] items-center ">
            {table?.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=" flex  ">
                {headerGroup.headers.map((header, index, array) => {
                  return (
                    <th
                      key={header.id}
                      className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                      ${
                        header.column.id === 'referred_name'
                          ? 'w-[13%]'
                          : header.column.id === 'remainder_sent'
                          ? 'w-[15%]'
                          : header.column.id === 'referral_status'
                          ? 'w-[13%]'
                          : header.column.id === 'referral_date'
                          ? 'w-[15%]'
                          : header.column.id === 'coupon_code_sent'
                          ? 'w-[15%]'
                          : header.column.id === 'reward_received'
                          ? 'w-[15%]'
                          : header.column.id === 'reward_redeemed'
                          ? 'w-[15%]'
                          : ''
                      }
                      }`}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center justify-between w-full">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        {array?.length - 1 === index + 10
                          ? null
                          : {
                              asc: (
                                <img
                                  src={SortIcon}
                                  // height={20}
                                  className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  style={{ transform: 'rotate(180deg)' }}
                                  alt="SortIcon"
                                />
                              ),
                              desc: (
                                <img
                                  src={SortIcon}
                                  className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                  alt="SortIcon"
                                />
                              ),
                              false: <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />,
                            }[header.column.getIsSorted()]}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="duration-1000 sale-table">
            {table.getRowModel().rows.map((row, rId) => (
              <tr
                key={row.id}
                className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
              >
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={`duration-1000  text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
                    ${
                      cell.column.id === 'referred_name'
                        ? 'w-[13%]'
                        : cell.column.id === 'remainder_sent'
                        ? 'w-[15%]'
                        : cell.column.id === 'referral_status'
                        ? 'w-[13%]'
                        : cell.column.id === 'referral_date'
                        ? 'w-[15%]'
                        : cell.column.id === 'coupon_code_sent'
                        ? 'w-[15%]'
                        : cell.column.id === 'reward_received'
                        ? 'w-[15%]'
                        : cell.column.id === 'reward_redeemed'
                        ? 'w-[15%]'
                        : ''
                    }
                    `}
                  >
                    {cell.column.id === 'referral_status' ? (
                      <div
                        className={`${
                          cell.row?.original?.referral_status === 'Subscribed'
                            ? 'text-[#20940D]'
                            : 'text-[#FF6F6F]'
                        }`}
                      >
                        {cell.row?.original?.referral_status === -1 ? (
                          <div style={{ width: '100%', border: 'none' }}>
                            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                          </div>
                        ) : (
                          cell.row?.original?.referral_status
                        )}
                      </div>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import React from 'react';
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PdfViewer = () => {
    let query = useQuery();
    const pdfUrl = query.get("pdf");
    const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`;

    return (
        <iframe
            src={viewerUrl}
            style={{ width: '100%', height: '1000px' }}
            frameborder="0"
        />
    );
}

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

// const PdfViewer = () => {
//     let query = useQuery();
//     console.log("pdf", query.get("pdf"))

//     return (
//         <iframe
//             src={query.get("pdf")}
//             style={{ width: '100vw', height: '100vh' }}
//             frameborder="0"
//         />

//     );
// }
export default PdfViewer;

import { useEffect, useState, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import axios from 'axios';
import SortIcon from '../../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as ClientError } from '../../../assets/images/ClientError.svg';
import { ReactComponent as Swap } from '../../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

export default function AuditTrial({ setOpenModal, OpenModal, headers, userId }) {
  const initialRows = Array(5).fill({
    user_name: -1,
    new_plan: -1,
    old_plan: -1,
    new_plan_duration: -1,
    old_plan_duration: -1,
    new_plan_expiry: -1,
    old_plan_expiry: -1,
  });
  const [Reschedule, setReschedule] = useState({
    columns: [
      { id: 0, column_name: 'User Name', map_column: 'user_name', type: 'string' },
      { id: 1, column_name: `New Plan`, map_column: 'new_plan', type: 'string' },
      { id: 1, column_name: 'Old Plan', map_column: 'old_plan', type: 'string' },
      { id: 1, column_name: 'New Plan Duration', map_column: 'new_plan_duration', type: 'string' },
      { id: 1, column_name: 'Old Plan Duration', map_column: 'old_plan_duration', type: 'string' },
      { id: 1, column_name: 'New Plan Exp.', map_column: 'new_plan_expiry', type: 'string' },
      { id: 1, column_name: 'Old Plan Exp.', map_column: 'old_plan_expiry', type: 'string' },
    ],
    rows: initialRows,
  });
  const [sorting, setSorting] = useState([]);

  const columnHelper = createColumnHelper();

  const fetchAuditLog = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/audit_logs/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data;
        setReschedule({ ...Reschedule, rows: finalData });
      });
  };

  useEffect(() => {
    OpenModal && fetchAuditLog();
  }, [OpenModal]);

  const columns = useMemo(() => {
    const { columns } = Reschedule;
    return columns?.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Reschedule]);

  const table = useReactTable({
    data: Reschedule.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <Transition appear show={OpenModal} as={Fragment}>
        <Dialog
          className="relative z-10"
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="rounded-2xl w-[80%] ml-20 bg-white shadow-xl">
                  <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
                    <div className="items-center flex justify-between border-b">
                      <div className=" font-Regular text-[24px] md:leading-5 text-body-color-2 mr-[5px] pl-[40px] py-[20px]">
                        Audit Trails
                      </div>
                      <div className="pr-10 2xl:h-[24px] 2xl:w-[24px] xl:h-[18.56px] xl:w-[18.56px] lg:h-[15.47px] lg:w-[15.47px]">
                        <Close
                          onClick={() => {
                            setOpenModal(false);
                          }}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                    {Reschedule.rows.length !== 0 ? (
                      <table className="min-w-full table-margin">
                        <thead className="sticky top-[0px] items-center ">
                          {table?.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className=" flex  ">
                              {headerGroup.headers.map((header, index, array) => {
                                return (
                                  <th
                                    key={header.id}
                                    className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs 
                                  ${
                                    header.column.id === 'user_name'
                                      ? 'w-[12%]'
                                      : header.column.id === 'new_plan'
                                      ? 'w-[14%]'
                                      : header.column.id === 'old_plan'
                                      ? 'w-[14%]'
                                      : header.column.id === 'new_plan_duration'
                                      ? 'w-[17%]'
                                      : header.column.id === 'old_plan_duration'
                                      ? 'w-[17%]'
                                      : header.column.id === 'new_plan_expiry'
                                      ? 'w-[13%]'
                                      : header.column.id === 'old_plan_expiry'
                                      ? 'w-[13%]'
                                      : ''
                                  }
																		}`}
                                    onClick={header.column.getToggleSortingHandler()}
                                  >
                                    <div className="flex items-center justify-between w-full">
                                      {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header)}
                                      {array?.length - 1 === index + 10
                                        ? null
                                        : {
                                            asc: (
                                              <img
                                                src={SortIcon}
                                                // height={20}
                                                className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                                style={{ transform: 'rotate(180deg)' }}
                                                alt="SortIcon"
                                              />
                                            ),
                                            desc: (
                                              <img
                                                src={SortIcon}
                                                className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                                alt="SortIcon"
                                              />
                                            ),
                                            false: (
                                              <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                            ),
                                          }[header.column.getIsSorted()]}
                                    </div>
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                        </thead>
                        <tbody className="duration-1000 audit-table">
                          {table.getRowModel().rows.map((row, rId) => (
                            <tr
                              key={row.id}
                              className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
                            >
                              {row.getVisibleCells().map((cell, index) => (
                                <td
                                  key={cell.id}
                                  className={`duration-1000  text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs
																${
                                  cell.column.id === 'user_name'
                                    ? 'w-[12%]'
                                    : cell.column.id === 'new_plan'
                                    ? 'w-[14%]'
                                    : cell.column.id === 'old_plan'
                                    ? 'w-[14%]'
                                    : cell.column.id === 'new_plan_duration'
                                    ? 'w-[17%]'
                                    : cell.column.id === 'old_plan_duration'
                                    ? 'w-[17%]'
                                    : cell.column.id === 'new_plan_expiry'
                                    ? 'w-[13%]'
                                    : cell.column.id === 'old_plan_expiry'
                                    ? 'w-[13%]'
                                    : ''
                                }
																`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="flex items-center justify-center">
                        <ClientError className="w-[30%] h-[30%]" />
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import PagesHeader from '../shared/PagesHeader';
import { ReactComponent as Search } from '../../assets/images/ic_search.svg';
import DropDown from '../common/DropDown';
import ProgressTrackerTable from '../layout/ProgressTrackerTable';
import { fetchProgressData, getprogressTracker, updateQuery } from '../../Store/ClientTableSlice';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';

export default function ProgressTrackerHome() {
  const tableHeader = useRef(null);
  const tableFilter = useRef(null);
  const dispatch = useDispatch();

  const query = useSelector((state) => state.ClientTable.progressTrackerSearch);

  const progressTrackerData = useSelector(getprogressTracker);

  const handleResize = () => {
    let tableHeaderHeight = 0;
    let tableFilterHeight = 0;
    if (tableHeader.current) {
      tableHeaderHeight = tableHeader.current.getBoundingClientRect().height;
    }
    if (tableFilter.current) {
      tableFilterHeight = tableFilter.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty(
      '--table-header-height',
      tableHeaderHeight + tableFilterHeight
    );
  };

  const handleChange = (e) => {
    const value = e.target.value;
    dispatch(updateQuery(value));
    dispatch(fetchProgressData());
    // dispatch(
    //   setprogressTrackerSearch({ searchQuery: e.target.value, isNewSearch: true })
    // )
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <PagesHeader
        name={'Progress Tracker'}
        //   array={<ArrowBack className="cursor-pointer" />}
        secondHeading={'Progress Tracker'}
        thirdHeading={'Progress Tracker'}
      />
      <div className="px-5 bg-white shadow-card-shadow rounded-medium table-card">
        {/* <div ref={tableHeader} className="items-center border-b divide-y ">
          <div className=" font-Regular   xl:text-2xl lg:text-xl md:text-[12px] md:leading-5 text-body-color-2   py-[10px] md:py-2  ">
            List Of Clients
          </div>
        </div> */}
        <div
          ref={tableFilter}
          className=" xl lg:pt-[10px] md:pt-2 justify-between flex flex-wrap gap-3"
        >
          <div>
            <DropDown />
          </div>
          <div className="relative flex ">
            <div className="flex items-center  rounded-[10px]">
              <Search className="absolute left-[10px] " />
              <input
                placeholder="Search "
                className=" pl-[36px] h-[48px] py-[10px] shadow-input-shadow  border outline-none border-secondary-3  lg:rounded-lg md:rounded-md   lg:w-[223px] xl:w-[267px] 2xl:w-[300px] 2xl:text-base xl:text-sm lg:text-xs  md:text-[9px]   "
                value={query}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        {/* <Table /> */}
        {/* <ClientTable /> */}
        <ProgressTrackerTable />
      </div>
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import { ReactComponent as Search } from '../../assets/images/ic_search.svg';
import { CustomSelect } from '../layout/CustomSelect';
import ClientTable from '../pages/ClientTable';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery } from '../../Store/ClientTableSlice';

const ClientsList = () => {
  let dispatch = useDispatch();
  let search = useSelector((state) => state.ClientTable.searchQuery);
  const tableHeader = useRef(null);
  const tableFilter = useRef(null);
  const handleResize = () => {
    let tableHeaderHeight = 0;
    let tableFilterHeight = 0;
    if (tableHeader.current) {
      tableHeaderHeight = tableHeader.current.getBoundingClientRect().height;
    }
    if (tableFilter.current) {
      tableFilterHeight = tableFilter.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty(
      '--table-header-height',
      tableHeaderHeight + tableFilterHeight
    );
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="px-5 bg-white shadow-card-shadow rounded-medium table-card">
        {/* <div ref={tableHeader} className="items-center border-b divide-y ">
          <div className=" font-Regular   xl:text-2xl lg:text-xl md:text-[12px] md:leading-5 text-body-color-2   py-[10px] md:py-2  ">
            List Of Clients
          </div>
        </div> */}
        <div
          ref={tableFilter}
          className=" xl lg:pt-[10px] md:pt-2 justify-between flex flex-wrap gap-3"
        >
          <div>
            <CustomSelect />
          </div>
          <div className="relative flex ">
            <div className="flex items-center ">
              <Search className="absolute left-[10px] " />
              <input
                placeholder="Search by Name or email id"
                value={search}
                className=" pl-[36px] h-[48px] py-[10px] shadow--shadow border outline-none border-secondary-3  lg:rounded-lg md:rounded-md   lg:w-[223px] xl:w-[267px] 2xl:w-[300px] 2xl:text-base xl:text-sm lg:text-xs  md:text-[9px] "
                onChange={(e) =>
                  dispatch(setSearchQuery({ searchQuery: e.target.value, isNewSearch: true }))
                }
              />
            </div>
          </div>
        </div>
        <ClientTable />
      </div>
    </>
  );
};

export default ClientsList;

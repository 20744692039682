import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';

export default function PopUpForOverView({ OpenModal, setOpenModal, macros }) {
  return (
    <div className="">
      <Transition appear show={OpenModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden 2xl:rounded-2xl lg:rounded-xl md:rounded-lg bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="p-[15px] font-medium justify-between items-center flex  text-gray-900"
                  >
                    Activity level overview
                    <div
                      onClick={() => {
                        setOpenModal(false);
                      }}
                      className="2xl:pr-5 xl:pr-[16px] lg:pr-[14px] absolute top-[7%] right-[2%] "
                    >
                      <Close className="cursor-pointer 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px]" />
                    </div>
                  </Dialog.Title>
                  <div className="p-[20px] 2xl:text-sm xl:text-[12px]">
                    <table className="w-full border-collapse">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border border-gray-300 bg-[#D7AF4133]">
                            Activity Level
                          </th>
                          <th className="py-2 px-4 border border-gray-300 bg-[#D7AF4133]">
                            Activity Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {macros?.map((macro, index) => (
                          <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                            <td
                              className={`py-2 px-4 border border-gray-300 ${
                                macro.is_selected ? 'font-bold text-[#2DD253]' : ''
                              }`}
                            >
                              {macro.text}
                            </td>
                            <td
                              className={`py-2 px-4 border border-gray-300 ${
                                macro.is_selected ? 'font-bold text-[#2DD253]' : ''
                              }`}
                            >
                              {macro.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserId } from '../../Store/ProgressAnalysisSlice';
import { useMemo } from 'react';
import moment from 'moment';
import { setClientName } from '../../Store/ClientTableSlice';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

export default function AnalysisAboutDialog({ isOpen, isClose, viewMore }) {
  const [usraboutdata, SetUsrAboutData] = useState([]);
  const [progressdetails, setProgressDetails] = useState([]);

  const { userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/user_details?user_id=${userId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((data) => {
        SetUsrAboutData(data.data.data[0].user);
        dispatch(setClientName(data.data.data[0].user.name));
        setProgressDetails(data.data.data[0].current_progress_details);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    dispatch(setUserId(userId));
  });

  const userData = useMemo(() => {
    let data = new Array();
    let keys = Object.entries(usraboutdata);
    keys = keys.map((arr) => {
      if (arr[0] === 'date_of_birth') {
        arr[0] = 'Date of Birth';
      } else if (arr[0] === 'last_updated') {
        arr[0] = 'Last Updated';
        const timeAgo = new TimeAgo('en-IN');
        const dateUtc = new Date(arr[1]);
        const localDate = new Date(dateUtc.getTime() + dateUtc.getTimezoneOffset() * 60000);
        arr[1] = timeAgo.format(localDate);
      }

      return arr;
    });
    keys.shift();
    return keys;
  }, [usraboutdata]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={isClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="2xl:w-[1117px]  xl:w-[864px]  lg:w-[720px] rounded-2xl bg-white shadow-xl">
                  <Dialog.Title as="h3" className=" font-medium leading-6 text-gray-900">
                    <div className="flex justify-between items-center border-b px-5">
                      <div></div>
                      <div className="text-shades-2 2xl:text-LargeTitleText xl:text-[18.564px] xl:leading-[28px]  lg:text-[15.47px] lg:leading-[23px] font-Medium 2xl:py-[20px] xl:pt-[15.47px] xl:pb-[15.32px] lg:pt-[12.89px] lg:pb-[13.1px]">
                        {viewMore ? 'Progress so Far' : 'About Client'}
                      </div>
                      <div className=" 2xl:h-[24px] 2xl:w-[24px]  xl:h-[18.56px] xl:w-[18.56px] lg:h-[15.47px] lg:w-[15.47px]">
                        <Close onClick={isClose} className="cursor-pointer" />
                      </div>
                    </div>
                  </Dialog.Title>
                  {!viewMore && (
                    <div className="card_color">
                      <div className="flex justify-between text-start items-center border-b border-t bg-[#E6E6E633] 2xl:px-10 2xl:py-[20px] xl:px-[30px] xl:py-[15px] lg:px-[20px] lg:py-[10px]">
                        {userData.map((val, id) => {
                          return (
                            <>
                              <div className="">
                                <div className="font-normal capitalize lg:mb-[2.24px] 2xl:text-base xl:text-[12.376px] xl:leading-[19px] lg:text-[10.3133px] lg:leading-[15px] text-[#666666]">
                                  {' '}
                                  {val[0]}{' '}
                                </div>
                                <div className="font-medium  2xl:text-base xl:text-[12.376px] xl:leading-[19px] lg:text-[10.3133px] lg:leading-[15px] text-black">
                                  {' '}
                                  {val[1]}{' '}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      viewMore
                        ? 'text-start  2xl:px-[40px] 2xl:py-[20px] xl:px-[30px] xl:py-[15px] lg:px-[20px] lg:py-[10px]'
                        : 'mt-7 text-start  2xl:px-[40px] 2xl:py-[20px] xl:px-[30px] xl:py-[15px] lg:px-[20px] lg:py-[10px]'
                    }
                  >
                    <div class="grid grid-cols-4 gap-5">
                      {progressdetails.map((val, id) => {
                        return (
                          <>
                            <div key={id} className="py-[2.59px] border-b">
                              <div className="text-body-color-2  2xl:text-SmallerBodyDisplayText xl:text-[12.376px] lg:text-[10.3133px] font-Regular">
                                {val.label}
                              </div>
                              <div className="font-medium 2xl:max-w-[280px] xl:max-w-[220px] lg:max-w-[200px] 2xl:text-SmallBodyDisplayText xl:text-base lg:text-sm text-body-color-1  break-words ">
                                {val.answer}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  {viewMore && (
                    <div className="p-2 flex justify-center items-center">
                      <div
                        className="w-[13%] h-10 text-SmallerBodyDisplayText flex justify-center items-center cursor-pointer rounded-full font-Regular  bg-brand-color text-white rounded-[10px] border"
                        onClick={() => {
                          window.open(`/progressAnalysis/${userId}`, '_blank');
                        }}
                      >
                        View More
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import { ReactComponent as Note } from '../../assets/images/add_notes.svg';
import { ReactComponent as NoData } from '../../assets/images/noDataWithText.svg';
import axios from 'axios';
import { getToken } from '../../Utils/Authenticate';
import { toast } from 'react-toastify';
import MealNoteModal from './MealNoteModal';
import UpdateModal from './UpdateModal';
import { useParams } from 'react-router-dom';
import InpDropDown from '../shared/InpDropDown';
import CountSectionRow from './CountSectionRow';
import SaveTempletModal from './SaveTempletModal';
import { SaveMeal } from '../shared/SaveMeal';

export const EatTime = ({
  userdata,
  setCallApi,
  CallApi,
  planDetails,
  setOverlay,
  setEditMeal,
  EditMeal,
  planId,
  isOpen,
  setIsOpen,
  setUserData,
  setRefreshData,
  refreshdata,
}) => {
  const [openMeal, setOpenMeal] = useState(false);
  const [OpenSaveMeal, setOpenSaveMeal] = useState(false);
  const [MealPass, setMealPass] = useState({});
  const [MealId, setMealId] = useState('');
  const [MealRecipe, setMealRecipe] = useState('');
  const [TempType, setTempType] = useState('');
  const [selected, setSelected] = useState({});
  const [inputMealVal, setInputMealVal] = useState('');
  const [inputTimeVal, setInputTimeVal] = useState('');
  const [query, setQuery] = useState('');
  const [QueryForInp, setQueryForInp] = useState('');
  const [queryIng, setQueryIng] = useState('');
  const [meal_id, setmeal_id] = useState('');
  const [fetched, setFetched] = useState(['']);
  const [selectedIng, setSelectedIng] = useState('');
  const [fetchedIng, setFetchedIng] = useState(['']);
  const [temopen, setSavOpen] = useState(false);
  const [selectedTemp, setSelectedTemp] = useState();

  function CloseNoteModal() {
    setOpenMeal(false);
    setMealId('');
  }

  const [data, setData] = useState([]);

  let { userId } = useParams();

  let token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-type': 'application/json',
  };

  const formattedNutrients = (nutritionProfile) => {
    if (
      !nutritionProfile ||
      nutritionProfile.length === 0 ||
      nutritionProfile.every((nutrient) => !nutrient.nutritent_name)
    ) {
      return (
        <>
          <span>Calories- 0kcal, Carbs - 0g, Proteins- 0g, Fat- 0g</span>
        </>
      );
    } else {
      return nutritionProfile.map((nutrient, index) => {
        const { nutritent_name, consumed_value, unit } = nutrient;
        const formattedValue = consumed_value || 0;
        const displayUnit = unit ? ` ${unit}` : '';

        return (
          <span key={index}>
            {nutritent_name} - {formattedValue}
            {displayUnit}
            {index !== nutritionProfile.length - 1 && ', '}
          </span>
        );
      });
    }
  };

  const deleteMeal = (mealId) => {
    setOverlay(true);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/remove_bulk_meal?meal_type_id=${mealId}&plan_id=${planId}`,
        { headers: headers }
      )
      .then((res) => {
        const response = res.data;
        console.log('data1', response);
        // setCallApi(CallApi + 1);
        setUserData((prevUserData) => prevUserData.filter((user) => user.meal_type_id !== mealId));
        setOverlay(false);
        setRefreshData((old) => !old);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const editMealTime = () => {
    const data = {
      plan_id: planId,
      user_id: userId,
      meal_id: meal_id ? meal_id : selected.id,
      meal_name: inputMealVal,
      meal_time: inputTimeVal,
      type: EditMeal ? 'edit' : 'add',
    };
    axios
      .patch(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/edit_date_time`, data, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data;
        const timeArray = finalData.meal_time.split(' ');
        const time = timeArray[0];
        const period = timeArray[1];
        const [hours, minutes] = time.split(':');
        let formattedTime = parseInt(hours);
        if (period === 'PM') {
          formattedTime += 12;
        }
        formattedTime = formattedTime * 60 + parseInt(minutes);

        if (EditMeal) {
          setCallApi(CallApi + 1);
        } else {
          setUserData((prevUserData) => {
            const updatedUserData = [...prevUserData, finalData];
            updatedUserData.sort((a, b) => {
              const timeArrayA = a.meal_time.split(' ');
              const timeArrayB = b.meal_time.split(' ');
              const timeA = timeArrayA[0];
              const timeB = timeArrayB[0];
              const periodA = timeArrayA[1];
              const periodB = timeArrayB[1];
              const [hoursA, minutesA] = timeA.split(':');
              const [hoursB, minutesB] = timeB.split(':');
              let formattedTimeA = parseInt(hoursA);
              let formattedTimeB = parseInt(hoursB);
              if (periodA === 'PM') {
                formattedTimeA += 12;
              }
              if (periodB === 'PM') {
                formattedTimeB += 12;
              }
              formattedTimeA = formattedTimeA * 60 + parseInt(minutesA);
              formattedTimeB = formattedTimeB * 60 + parseInt(minutesB);
              return formattedTimeA - formattedTimeB;
            });
            return updatedUserData;
          });
        }
        setIsOpen(false);
        // setCallApi(CallApi + 1);
        setInputMealVal('');
        setInputTimeVal('');
        setSelected('');
        setRefreshData((old) => !old);
        //data.data.length > 0 ? setSelectedTemp(data.data[0]) : "";
      })
      .catch((err) => {
        const error = err.response;
        toast.error(error);
        setOverlay(false);
        if (error) {
          setInputMealVal('');
          setInputTimeVal('');
          setSelected({ meal_time: '', meal_name: '' });
          setQuery('');
        }
      });
  };

  useEffect(() => {
    if (query) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/meal_types?search=${query}`,
          { headers: headers }
        )
        .then(({ data }) => {
          // console.log('data', data.data);
          setData(data.data);
          //data.data.length > 0 ? setSelectedTemp(data.data[0]) : "";
        })
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [query]);

  useEffect(() => {
    if (selected && selected.meal_time && selected.meal_name) {
      setInputTimeVal(selected.meal_time);
      setInputMealVal(selected.meal_name);
    }
  }, [selected]);

  useEffect(() => {
    const controller = new AbortController();
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe_data?search=${QueryForInp}&page=1&size=200`,
      method: 'get',
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (QueryForInp) {
      axios
        .request(config)
        .then(({ data }) => {
          setFetched(data.data[0].rows);
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    return () => {
      controller.abort();
    };
  }, [QueryForInp]);

  useEffect(() => {
    const controller = new AbortController();
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/ingredientV1?search=${queryIng}&page=1&size=200`,
      method: 'get',
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (queryIng) {
      axios
        .request(config)
        .then(({ data }) => {
          setFetchedIng(data.data);
          // console.log('dat', data.data);
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    return () => {
      controller.abort();
    };
  }, [queryIng]);

  const onQueryChange = (e) => {
    setQueryForInp(e.target.value);
  };
  const onQueryChangeIng = (e) => {
    setQueryIng(e.target.value);
  };

  const filteredData =
    QueryForInp === ''
      ? fetched
      : fetched.filter((rec) =>
          rec.name
            ?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(QueryForInp?.toLowerCase().replace(/\s+/g, ''))
        );

  const filteredIng =
    queryIng === ''
      ? fetchedIng
      : fetchedIng.filter((ing) =>
          ing.name
            ?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(queryIng?.toLowerCase().replace(/\s+/g, ''))
        );

  function CloseSavTem() {
    setSavOpen(false);
  }

  function OpenSaveTem() {
    setSavOpen(true);
  }

  return (
    <>
      {userdata.length === 0 && (
        <div className="flex items-center justify-center  bg-white w-full h-full p-12 rounded-2xl ">
          <NoData className="object-cover w-full h-full" />
        </div>
      )}
      {userdata?.map((mealWithTime, index) => (
        <>
          <div className="MealSection 2xl:px-[20px] xl:px-[17px] lg:px-[14px] border-b">
            <div className="flex justify-between lg:py-[8px] xl:py-[13px] 2xl:py-[16px] items-center">
              <div className="flex items-center  lg:gap-[14px] md:gap-[10px] gap-10px ">
                <div className="MealInput flex items-center gap-2 ">
                  <div className={`2xl:text-base xl:text-[12.22px] lg:text-[11.85px] font-medium`}>
                    <span className="inline-flex border-b">
                      <span
                        className={`truncate-custom overflow-hidden text-ellipsis whitespace-nowrap lg:max-w-[120px] xl:max-w-[120px] 2xl:max-w-[120px]`}
                      >
                        {mealWithTime.meal_type}
                      </span>
                      <span>
                        {!mealWithTime.meal_time ? '' : ' - '}
                        {mealWithTime.meal_time}
                      </span>
                    </span>
                  </div>
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      // setIsEditMode(true);
                      setEditMeal(true);
                      // setSelected({
                      //   meal_name: mealWithTime?.meal_type,
                      //   meal_time: mealWithTime?.meal_time,
                      // });
                      setmeal_id(mealWithTime?.meal_type_id);
                      setInputMealVal(mealWithTime?.meal_type);
                      setInputTimeVal(mealWithTime?.meal_time);
                      setIsOpen(true);
                    }}
                  >
                    {<Edit />}
                  </button>
                </div>
              </div>
              <div
                className={`text-[4px] sm:text-[4px] md:text-[4px] lg:text-[8px] xl:text-xs 2xl:text-xs`}
              >
                {formattedNutrients(mealWithTime.nutrition_profile)}
              </div>
              {/* {!(ele?.editable && show) && (
                <div
                  className=" 2xl:text-base text-shades-2 font-Regular cursor-pointer"
                  onClick={() => {
                    OpenSaveTem();
                    setMealRecipe(mealWithTime?.recipes)
                    setTempType('Section Wise');
                  }}
                >
                  Save as Template
                </div>
              )} */}
              <div className="flex items-center  lg:gap-[14px] md:gap-[10px] gap-16px ">
                {mealWithTime.suggested_recipes.length > 0 &&
                  window.location.origin !== 'https://admin-v2.unlock.fit' && (
                    <div
                      className=" 2xl:text-base text-shades-2 font-Regular cursor-pointer"
                      onClick={() => {
                        setOpenSaveMeal(true);
                        setMealPass(mealWithTime);
                      }}
                    >
                      Save
                    </div>
                  )}
                <div className="flex  lg:gap-[14px] md:gap-[10px] gap-16px  ">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenMeal(true);
                      setTempType('Meal Note Wise');
                      setMealId(mealWithTime?.meal_type_id);
                    }}
                  >
                    {<Note />}
                  </div>
                  <div className="cursor-pointer">
                    {
                      <Delete
                        onClick={() => {
                          deleteMeal(mealWithTime?.meal_type_id);
                        }}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {mealWithTime?.suggested_recipes?.map((rec, indx) => {
            return (
              <div
                className=" 2xl:px-[20px] xl:px-[17px] lg:px-[14px] 2xl:py-[13px] xl:py-[9px] lg:py-[7px]  "
                key={indx}
              >
                <div className="border-b ">
                  <CountSectionRow
                    meal_type_id={rec?.meal_type_id}
                    recipe_id={rec?.recipe_id}
                    recipe_name={rec?.recipe_name}
                    danger_message={rec?.dangerous_message}
                    serving={rec?.serving}
                    Measurement={rec?.measurement}
                    masterMeasurement={rec?.master_measurements}
                    setRecipes={mealWithTime?.suggested_recipes}
                    setnutritionProfile={mealWithTime?.nutrition_profile}
                    index={indx}
                    dangerous={rec?.is_dangerous}
                    setCallApi={setCallApi}
                    CallApi={CallApi}
                    setUserData={setUserData}
                    userdata={userdata}
                    setRefreshData={setRefreshData}
                  />
                </div>
              </div>
            );
          })}

          <div className="flex justify-center items-center lg:gap-[14px] md:gap-[10px] gap-16px  md:px-3 lg:px-4 lg:pb-[8px] md:pb-[6px] border-b ">
            <InpDropDown
              heading={'Type Recipe Name'}
              onChange={onQueryChange}
              records={filteredData}
              query={QueryForInp}
              onSetQuery={() => setQueryForInp('')}
              selected={selected}
              setSelected={setSelected}
              planId={planId}
              meal_type_id={mealWithTime.meal_type_id}
              type="recipe"
              setOverlay={setOverlay}
              editable={mealWithTime?.editable}
              time={mealWithTime?.meal_time}
              meal={mealWithTime?.meal_type}
              setData={mealWithTime?.suggested_recipes}
              setnutritionProfile={mealWithTime?.nutrition_profile}
              setCallApi={setCallApi}
              CallApi={CallApi}
              setRefreshData={setRefreshData}
              userdata={userdata}
              setUserData={setUserData}
            />
            <InpDropDown
              heading={'Type Ingredients Name'}
              onChange={onQueryChangeIng}
              records={filteredIng}
              query={queryIng}
              onSetQuery={() => setQueryIng('')}
              selected={selectedIng}
              setSelected={setSelectedIng}
              planId={planId}
              meal_type_id={mealWithTime.meal_type_id}
              type="ingredient"
              setOverlay={setOverlay}
              editable={mealWithTime?.editable}
              time={mealWithTime?.meal_time}
              meal={mealWithTime?.meal_type}
              setData={mealWithTime?.suggested_recipes}
              setnutritionProfile={mealWithTime?.nutrition_profile}
              setCallApi={setCallApi}
              CallApi={CallApi}
              setRefreshData={setRefreshData}
              userdata={userdata}
              setUserData={setUserData}
            />
          </div>
        </>
      ))}
      <UpdateModal
        isOpen={isOpen}
        closeModal={() => {
          setInputMealVal('');
          setInputTimeVal('');
          setSelected('');
          setIsOpen(false);
        }}
        data={data}
        inputMealVal={inputMealVal}
        setInputMealVal={setInputMealVal}
        inputTimeVal={inputTimeVal}
        setInputTimeVal={setInputTimeVal}
        selected={selected}
        setSelected={setSelected}
        query={query}
        editMealTime={editMealTime}
        mealtype={EditMeal ? inputMealVal : ''}
        setQuery={setQuery}
      />
      <MealNoteModal
        Openis={openMeal}
        Closeis={CloseNoteModal}
        meal_type_id={MealId}
        type={TempType}
      />
      <SaveTempletModal
        isOpen={temopen}
        closeModal={CloseSavTem}
        type={TempType}
        meal_type_id={MealId}
        rec={MealRecipe}
      />
      <SaveMeal
        OpenSave={OpenSaveMeal}
        setOpenSave={setOpenSaveMeal}
        headers={headers}
        MealPass={MealPass}
      />
    </>
  );
};

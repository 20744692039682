import React, { useEffect, useState, Fragment } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../Utils/Authenticate';
import { ReactComponent as Expand } from '../../assets/images/expand_more_dark.svg';
import { ReactComponent as Verified } from '../../assets/images/verified.svg';
import { Listbox, Combobox, Transition } from '@headlessui/react';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';

const VersionList = ({ setVersion, SelectedVersion, setSelectedVersion, Versions }) => {
  let { userId } = useParams();
  const [open, setOpen] = useState(false);

  return (
    <>
      {Versions && Versions.length > 0 && (
        <div className="pl-[15px]">
          <div className="w-[250px] inline-block">
            <Combobox value={SelectedVersion} open={open}>
              <div className="relative">
                <div
                  className={`relative w-full cursor-default rounded bg-white   text-left   font-Regular 2xl:text-base xl:text-[14.22px] lg:text-[11.85px]`}
                >
                  <Combobox.Input
                    readOnly
                    className={`w-full border-none  text-gray-900 focus:outline-none 2xl:text-base xl:text-[14.22px] lg:text-[11.85px]`}
                    displayValue={(data) => data.name}
                    placeholder={'Select Version'}
                  />
                  <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                    onClick={() => setOpen(!open)}
                  >
                    <div className="flex justify-between items-center truncate">
                      <div>
                        <UpArray
                          className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
                        />
                      </div>
                    </div>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <div className=" text-gray-400" aria-hidden="true" />
                    </span>
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  // afterLeave={() => setQuery('')}
                >
                  <Combobox.Options
                    className={`${
                      Versions.length > 0 || Versions.length > 1 ? 'border' : ''
                    }  absolute mt-1 max-h-[15rem] w-auto overflow-auto  border-[#E6E6E6] shadow-[4px 4px 8px 2px rgba(0, 0, 0, 0.12)] rounded-lg bg-white z-50  2xl:text-xs 2xl:leading-[18px] xl:text-sm lg:text-xs md:text-[10px] font-Regular`}
                  >
                    {Versions.map((template, id) => (
                      <Combobox.Option
                        key={id}
                        onClick={() => {
                          setSelectedVersion(template);
                          localStorage.setItem('last-opened', JSON.stringify(template));
                          setVersion(template.id === 0 ? '0' : template.id);
                        }}
                        className={({ active }) =>
                          `relative cursor-pointer select-none border-b ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={template}
                      >
                        {({ selected, active }) => (
                          <>
                            <div
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              <div className="p-[10px]">
                                <div className="flex justify-between items-center">
                                  <div className="text-[#BD9528] text-[15px] mt-[5px] cursor-pointer">
                                    {template.name}
                                  </div>
                                  {template.is_verified && (
                                    <Tooltip title="This version is verified" placement="top">
                                      <Verified />
                                    </Tooltip>
                                  )}
                                </div>
                                <div className="flex justify-between mt-[10px] text-[12px]">
                                  <div className="mr-[10px]">{template.created_by}</div>
                                  <div>{template.created_at}</div>
                                </div>
                              </div>
                            </div>

                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }'
                                  }`}
                              >
                                <div className=" lg:h-4 lg:w-4 md:h-3 md:w-3" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
        </div>
      )}
    </>
  );
};

export default VersionList;

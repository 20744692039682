import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { getToken } from '../../Utils/Authenticate';
import axios, { all } from 'axios';
import { toast } from 'react-toastify';

export default function AddNewAllergyModal({ isOpen, isClose, setSelectedAllergies, setSave }) {
  const [allergyName, setAllergyName] = useState('');
  const [desc, setDesc] = useState('');

  useEffect(() => {
    setAllergyName('');
    setDesc('');
  }, [isOpen]);

  const token = getToken();

  const saveAllergy = () => {
    let allergy = allergyName;
    if (allergyName) {
      allergy = allergy?.slice(0, 1)?.toUpperCase() + allergy?.slice(1);
    }
    const allergyBody = {
      name: allergy,
      description: desc,
    };
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/allergies`, allergyBody, {
        headers: headers,
      })
      .then((response) => {
        setSelectedAllergies((old) => [...old, allergy]);
        toast.success(response.data.data.message);
        isClose();
        setSave(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10  " onClose={isClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="  transform 2xl:w-[694px] 2xl:h-[520px] xl:w-[536.81px] xl:h-[420.23px] lg:w-[447.34px] lg:h-[360.86px] overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="div"
                    className=" font-medium  flex justify-between border-b  items-center text-shades-2"
                  >
                    <div></div>
                    <div className="2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] 2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px]  text-shades-2">
                      Add New Allergy
                    </div>
                    <div onClick={isClose} className=" 2xl:pr-5 xl:pr-4 lg:pr-3">
                      <Close className="cursor-pointer 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px] " />
                    </div>
                  </Dialog.Title>
                  <div className="border-b 2xl:p-10 xl:p-[30.97px] lg:p-[25.78px]">
                    <div className="flex justify-between items-center 2xl:text-base xl:text-sm lg:text-xs   text-body-color-2">
                      <p className="w-[30%]">Allergy Name</p>
                      <input
                        type="text"
                        className=" w-[70%] py-[10px] border-b border-black text-body-color-1  2xl:text-base xl:text-sm lg:text-xs font-Medium focus:outline-none"
                        placeholder="Allergy Name"
                        value={allergyName}
                        onChange={(e) => setAllergyName(e.target.value)}
                      />
                    </div>
                    <div className="2xl:mt-[30px] xl:mt-[25px] lg:mt-[22px] 2xl:text-base xl:text-sm lg:text-xs   text-body-color-2">
                      <p>Allergy Description</p>
                      <textarea
                        name=""
                        className="2xl:mt-[20px] xl:mt-[16px] lg:mt-[12px] 2xl:p-[10px] xl:p-[7.74px] lg:p-[6.45px]  2xl:w-[614px] 2xl:h-[160px] xl:w-[474px] xl:h-[123.76px] lg:w-[395.77px] lg:h-[103.73px] rounded-small text-body-color-2 border shadow-grid-col outline-none   2xl:text-SmallRegular xl:text-[9.28px] lg:text-[7.74px]"
                        id=""
                        onChange={(e) => {
                          setDesc(e.target.value);
                        }}
                        value={desc}
                        placeholder="Write description here"
                        style={{ resize: 'none' }}
                        maxLength={'200'}
                      >
                        Write your notes here
                      </textarea>
                      <div className="-mt-[30px] z-40 absolute right-[45px] text-body-color-2   2xl:text-SmallRegular xl:text-[9.28px] lg:text-[7.74px]">
                        {desc?.length}/200
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center 2xl:py-[20px] xl:py-[17px] lg:py-[14px] ">
                    <button
                      className="inline-flex justify-center rounded-md border border-transparent bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  2xl:text-base xl:text-sm lg:text-xs font-Regular text-white "
                      onClick={saveAllergy}
                    >
                      Save
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

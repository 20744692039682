import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ProgressAnalysis, setMonthId, setYear } from '../../Store/ProgressAnalysisSlice';

function PrgAnlDropDown({ ApiTrigger }) {
  const [userdata, setUserData] = useState([]);
  const { userId } = useParams();

  const callDropDownApi = () => {
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/months?user_id=${userId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then((data) => {
        if (userdata.length !== data?.data?.data?.length) {
          setUserData(data?.data?.data);
          data = data?.data?.data;
          data.forEach((month) => {
            if (month.default) {
              dispatch(setMonthId(month?.month_id));
              dispatch(setYear(month?.year));
              dispatch(ProgressAnalysis());
              setSelected(month);
            }
          });
        }
      })
      .catch((err) => {
        toast.error('err');
        console.log('error', err);
      });
  };
  useEffect(() => {
    callDropDownApi();
  }, [ApiTrigger]);
  const [selected, setSelected] = useState({
    display_value: 'Options',
    month_id: '',
    year: '',
  });
  const dispatch = useDispatch();

  return (
    <div className="">
      <Listbox
        value={selected}
        onChange={(data) => {
          dispatch(setMonthId(data?.month_id));
          dispatch(setYear(data?.year));
          dispatch(ProgressAnalysis());
          setSelected(data);
        }}
        className="relative inline-block bg-white"
      >
        <div className="relative ">
          <Listbox.Button className=" border-secondary-3 border shadow-input-shadow rounded-[10px]  text-right">
            <div className="flex justify-between items-center px-2  ">
              <div className="py-1">
                <span className=" lg:w-[135px] md:w-[90px] justify-start flex  2xl:text-sm xl:text-sm lg:text-xs text-body-color-2 ">
                  <span
                    className=" text-left text-ellipsis whitespace-nowrap  inline-block overflow-hidden"
                    style={{ width: '95%' }}
                  >
                    {selected?.display_value}
                  </span>
                </span>
              </div>
              <div>
                <UpArray className="ui-not-open:transform ui-not-open:rotate-180" />
              </div>
            </div>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className=" overflow-y-scroll absolute z-10  mt-[1px] 2xl:w-[165px]   lg:w-[170px]  md:w-[130px] max-h-[150px] origin-top-center  divide-gray-100 rounded-lg  bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
              <div className=" md:px-[2px] md:py-[2px]">
                {userdata?.map((val, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      `text-ellipsis whitespace-nowrap w-full cursor-pointer  border-b 2xl:py-[5px] 2xl:px-5 xl:py-[8px] xl:px-[16px] lg:py-[8px] lg:px-[16px]  2xl:text-base xl:text-sm lg:text-xs`
                    }
                    value={val}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {val?.display_value}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default PrgAnlDropDown;

import React, { useRef, useState, useEffect } from 'react';
import Button from '../common/Button';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Divider } from '@mui/material';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { ReactComponent as FormatBoldIcon } from '../../assets/images/format_bold.svg';
import { ReactComponent as FormatItalicIcon } from '../../assets/images/format_italic.svg';
import { ReactComponent as FormatUnderlinedIcon } from '../../assets/images/format_underlined.svg';
import { ReactComponent as FormatStrikeThroughIcon } from '../../assets/images/format_strikethrough.svg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export default function ExpertNotesModalCopy({ verification }) {
  const [notes, setNotes] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef();
  const [content, setContent] = useState('');
  const [EditGuideline, setEditGuideline] = useState(false);
  const [EditId, setEditId] = useState();

  let { userId } = useParams();
  const token = getToken();

  const fetchNotes = () => {
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/expert/notes?user_id=${userId}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then(({ data }) => {
        setNotes(data?.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const onEditorStateChange = function (editorState) {
    setEditorState(editorState);
    const contentHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContent(contentHtml);
  };
  const customToolbar = {
    options: ['inline'],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
  };

  return (
    <>
      <div
        as="h3"
        className=" font-medium justify-center items-center border-b flex  text-gray-900"
      >
        <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] text-shades-2 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px] border-r">
          Expert's Notes
        </div>
        <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] text-shades-2 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px]">
          Add Notes
        </div>
      </div>
      <div className="content-diet-plan2">
        <div className="font-medium flex h-full text-gray-900">
          <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px] border-r overflow-auto">
            {notes && notes.length > 0 ? (
              <ul className="w-full">
                {notes.map((item, index) => (
                  <div
                    className={`${
                      EditId === item.id ? 'w-[100%] bg-[#80808080] pointer-events-none ' : ''
                    } flex border-b p-[10px]`}
                  >
                    <div className="pr-[5px]">{index + 1}.</div>
                    <div className="w-[100%]">
                      <div
                        className="text-justify text-[14px]"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></div>
                      <div className="flex justify-between text-[#616161] text-[10px] text-justify mt-[5px]">
                        <div>
                          {item.expert_name},{item.created_at}
                        </div>
                        {verification?.can_create && (
                          <div className="flex justify-between">
                            <Edit
                              className="cursor-pointer mr-[10px]"
                              onClick={() => {
                                setEditGuideline(true);
                                const blocksFromHtml = htmlToDraft(item.description);
                                const contentState =
                                  ContentState.createFromBlockArray(blocksFromHtml);
                                const newEditorState = EditorState.createWithContent(contentState);
                                setEditorState(newEditorState);
                                setContent(item.description);
                                setEditId(item.id);
                              }}
                            />
                            <Delete
                              onClick={() => {
                                let config = {
                                  baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/expert/notes/${item.id}`,
                                  method: 'DELETE',
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                  },
                                };
                                axios
                                  .request(config)
                                  .then(({ data }) => {
                                    toast.success(data.message);
                                    setContent('');
                                    fetchNotes();
                                  })
                                  .catch((err) => {
                                    toast.error(`${err}`);
                                  });
                              }}
                              className="cursor-pointer"
                            />
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  </div>
                ))}
              </ul>
            ) : (
              <div className="text-[#919191]">Add Notes to View</div>
            )}
          </div>
          <div className="w-1/2 flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] border-r overflow-auto">
            <div className="w-full">
              <div>
                <Editor
                  readOnly={!verification?.can_create}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName h-[40px]"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName border-b expert-note"
                  onEditorStateChange={onEditorStateChange}
                  toolbar={customToolbar}
                  placeholder="Enter your text here"
                  style={{ padding: 10 }}
                />
              </div>
              <div className="flex justify-center items-center lg:h-[48px] xl:h-[50px]">
                {verification?.can_create && (
                  <Button
                    type="button"
                    disabled={!verification?.can_create}
                    className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
                    onClick={() => {
                      if (verification?.can_create) {
                        if (EditGuideline === false) {
                          if (content?.trim()?.length < 1) {
                            toast.error('Please Enter Some Note to Proceed');
                          } else {
                            let config = {
                              baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/expert/notes`,
                              method: 'POST',
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                              data: {
                                user_id: userId,
                                description: content,
                              },
                            };
                            axios
                              .request(config)
                              .then(
                                ({ data }) => {
                                  toast.success(data.message);
                                  fetchNotes();
                                  setContent('');
                                  setEditorState(EditorState.createEmpty());
                                  setDeleted((old) => !old);
                                },
                                (err) => {
                                  toast.error(err);
                                }
                              )
                              .catch((err) => {
                                toast.error(err);
                              });
                          }
                        } else {
                          const guidelineBody = {
                            description: content,
                          };
                          axios
                            .patch(
                              `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/expert/notes/${EditId}`,
                              guidelineBody,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                            .then((response) => {
                              toast.success(response.data.message);
                              fetchNotes();
                              setEditGuideline(false);
                              setEditorState(EditorState.createEmpty());
                              setEditId('');
                              setContent('');
                            })
                            .catch((error) => {
                              toast.error(error.data.message);
                            });
                        }
                      }
                    }}
                  >
                    {EditGuideline ? 'Edit Note' : 'Add Note'}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

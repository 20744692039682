import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as Correct } from '../../assets/images/correct.svg';
import { ReactComponent as Incorrect } from '../../assets/images/incorrect.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from './ErrorMessages';
import { verifyToken } from '../../Utils/Authenticate';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 434,
    padding: '20px !important',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    boxShadow: '0px 0px 23.3px 0px rgba(32, 31, 31, 0.15)',
  },
  '& .MuiTooltip-arrow': {
    color: '#FFF',
  },
}));

export const WarningMessage = ({
  setAuthToken,
  AuthToken,
  change,
  OpenWarning,
  setOpenWarning,
  type,
  setType,
  UserData,
  setUserData,
}) => {
  const navigate = useNavigate();
  const [CurrentPassword, setCurrentPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [PasswordVisible, setPasswordVisible] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [OpenError, setOpenError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [PopOver, setPopOver] = useState(false);
  const [SamePassword, setSamePassword] = useState(false);
  const [Validation, setValidation] = useState({
    Length: false,
    Special: false,
    Case: false,
    Digit: false,
    Differ: false,
  });

  const checkPasswordStrength = (value) => {
    const regexWeak = /^(?=.*[a-zA-Z])/;
    const regexMedium = /^(?=.*\d)(?=.*[a-zA-Z])/;
    const regexStrong = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*])/;

    if (regexStrong.test(value)) {
      return 'Strong';
    } else if (regexMedium.test(value)) {
      return 'Medium';
    } else if (regexWeak.test(value)) {
      return 'Weak';
    } else {
      return '';
    }
  };

  const checkPassword = (value) => {
    setPopOver(true);

    const newPasswordValid = {
      Length: value.length >= 8,
      Case: /[a-z]/.test(value) && /[A-Z]/.test(value),
      Digit: /\d/.test(value),
      Special: /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value),
      Differ: CurrentPassword ? CurrentPassword !== value : true,
    };

    setValidation(newPasswordValid);

    const isValid = Object.values(newPasswordValid).every((condition) => condition);
    setPopOver(!isValid);

    return isValid;
  };

  const handleInputChange = (e, isConfirmPassword = false) => {
    const value = e.target.value.trim();
    if (isConfirmPassword) {
      setConfirmPassword(value);
      setSamePassword(checkConfirmPasswordMatch(value));
    } else {
      setNewPassword(value);
      checkPassword(value);
    }
  };

  const checkConfirmPasswordMatch = (confirmPassword) => {
    return confirmPassword === NewPassword;
  };

  const passwordStrength = checkPasswordStrength(NewPassword);

  useEffect(() => {
    if (OpenError === false) {
      setOpenWarning(false);
      setAuthToken('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordVisible({
        ...PasswordVisible,
        confirm: false,
        new: false,
        current: false,
      });
    }
  }, [OpenError]);

  const resetPassword = () => {
    const body = {
      old_password: CurrentPassword,
      password: ConfirmPassword,
    };
    const headers = {
      Authorization: `Bearer ${AuthToken}`,
    };
    const isPasswordValid = checkPassword(NewPassword);
    const isConfirmPasswordValid = checkConfirmPasswordMatch(ConfirmPassword);

    if (CurrentPassword || NewPassword || ConfirmPassword) {
      if (CurrentPassword) {
        if (NewPassword) {
          if (ConfirmPassword) {
            if (isPasswordValid) {
              if (isConfirmPasswordValid) {
                axios
                  .post(`${process.env.REACT_APP_BASE_URL}/admin/user/change_password`, body, {
                    headers: headers,
                  })
                  .then((response) => {
                    const userDetails = response.data;
                    if (change) {
                      setOpenWarning(false);
                      setAuthToken('');
                      setCurrentPassword('');
                      setNewPassword('');
                      setConfirmPassword('');
                      setPasswordVisible({
                        ...PasswordVisible,
                        confirm: false,
                        new: false,
                        current: false,
                      });
                      toast.success(userDetails.message);
                    } else {
                      setOpenError(true);
                      setErrorMessage(userDetails.message);
                    }
                  })
                  .catch((error) => {
                    const userRetry = error.response.data;
                    if (change) {
                      toast.error(userRetry.message);
                    } else {
                      setOpenError(true);
                      setErrorMessage(userRetry.message);
                    }
                  });
              } else {
                if (change) {
                  toast.error('New Password and Confirm Password are Not Matching');
                } else {
                  setOpenError(true);
                  setErrorMessage('New Password and Confirm Password are Not Matching');
                }
              }
            } else {
              if (change) {
                toast.error('All Password Rules Should Match');
              } else {
                setOpenError(true);
                setErrorMessage('All Password Rules Should Match');
              }
            }
          } else {
            if (change) {
              toast.error('Confirm Password is empty');
            } else {
              setOpenError(true);
              setErrorMessage('Confirm Password is empty');
            }
          }
        } else {
          if (change) {
            toast.error('New Password is empty');
          } else {
            setOpenError(true);
            setErrorMessage('New Password is empty');
          }
        }
      } else {
        if (change) {
          toast.error('Current Password is empty');
        } else {
          setOpenError(true);
          setErrorMessage('Current Password is empty');
        }
      }
    } else {
      if (change) {
        toast.error('Fill out all fields');
      } else {
        setOpenError(true);
        setErrorMessage('Fill out all fields');
      }
    }
  };
  return (
    <Transition appear show={OpenWarning} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (change) {
            setOpenWarning(false);
            setAuthToken('');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setPasswordVisible({ ...PasswordVisible, confirm: false, new: false, current: false });
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[40%] flex justify-center items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className={`flex justify-center items-center flex-col w-[65%]`}>
                  <br />
                  {type === 'reset' ? (
                    <div className="flex items-center justify-center flex-col">
                      <span className="text-[#4E4E4E] text-[30px] font-Semi-Bold text-center">
                        {change ? 'Recreate Your Password' : 'Password Expire'}
                      </span>
                      <br />
                      {!change && (
                        <span className="text-[18px] text-[#575757] text-center">
                          Your Admin Password is set to expire every 90 days. It’s time for a
                          renewal.
                        </span>
                      )}
                      <br />
                      <form
                        className="flex flex-col justify-start w-full"
                        onSubmit={(e) => {
                          e.preventDefault();
                          resetPassword();
                        }}
                      >
                        <div className="flex relative flex-col">
                          <span className="text-[#9F9F9F]">Current Password</span>
                          <div className="relative flex items-center">
                            <input
                              className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                              type={PasswordVisible.current ? 'text' : 'password'}
                              value={CurrentPassword}
                              onChange={(e) => setCurrentPassword(e.target.value.trim())}
                            />
                            <div
                              className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-[5px]"
                              onClick={() => {
                                setPasswordVisible({
                                  ...PasswordVisible,
                                  current: !PasswordVisible.current,
                                });
                              }}
                            >
                              {!PasswordVisible.current ? (
                                <VisibilityIcon className="text-[#9F9F9F]" />
                              ) : (
                                <VisibilityOffIcon className="text-[#9F9F9F]" />
                              )}
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="flex relative flex-col">
                          <span className="text-[#9F9F9F]">New Password</span>
                          <div className="relative flex items-center">
                            <HtmlTooltip
                              title={
                                <Fragment>
                                  <div className="text-[13px]">
                                    <div
                                      className={`flex items-center mb-[2px] ${
                                        Validation.Length ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                                      }`}
                                    >
                                      {Validation.Length ? <Correct /> : <Incorrect />}
                                      <span className="ml-2">
                                        Passwords must be at least 8 characters long
                                      </span>
                                    </div>
                                    <div
                                      className={`flex items-center mb-[2px] ${
                                        Validation.Case ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                                      }`}
                                    >
                                      {Validation.Case ? <Correct /> : <Incorrect />}
                                      <span className="ml-2">
                                        Use a combination of uppercase and lowercase letters
                                      </span>
                                    </div>
                                    <div
                                      className={`flex items-center mb-[2px] ${
                                        Validation.Digit ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                                      }`}
                                    >
                                      {Validation.Digit ? <Correct /> : <Incorrect />}
                                      <span className="ml-2">
                                        Include at least one numeric digit (0-9)
                                      </span>
                                    </div>
                                    <div
                                      className={`flex items-center mb-[2px] ${
                                        Validation.Special ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                                      }`}
                                    >
                                      {Validation.Special ? <Correct /> : <Incorrect />}
                                      <span className="ml-2">
                                        Include at least one special character (e.g., !, @, #, $, %)
                                      </span>
                                    </div>
                                    <div
                                      className={`flex items-center mb-[2px] ${
                                        Validation.Differ ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                                      }`}
                                    >
                                      {Validation.Differ ? <Correct /> : <Incorrect />}
                                      <span className="ml-2">
                                        Current And New Password Should be Different
                                      </span>
                                    </div>
                                  </div>
                                </Fragment>
                              }
                              open={PopOver && NewPassword.length > 0}
                              placement="right-start"
                              arrow
                            >
                              <input
                                className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                                type={PasswordVisible.new ? 'text' : 'password'}
                                value={NewPassword}
                                onChange={(e) => handleInputChange(e)}
                              />
                            </HtmlTooltip>
                            <div
                              className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-[5px]"
                              onClick={() => {
                                setPasswordVisible({
                                  ...PasswordVisible,
                                  new: !PasswordVisible.new,
                                });
                              }}
                            >
                              {!PasswordVisible.new ? (
                                <VisibilityIcon className="text-[#9F9F9F]" />
                              ) : (
                                <VisibilityOffIcon className="text-[#9F9F9F]" />
                              )}
                            </div>
                          </div>
                          <div
                            className={`flex items-center w-full justify-between mt-2 transition-all ease-in-out overflow-hidden ${
                              NewPassword ? 'opacity-100 max-h-[200px]' : 'opacity-0 max-h-0'
                            }`}
                          >
                            <div
                              className={`w-[20%] text-[14px] ${
                                passwordStrength === 'Weak'
                                  ? 'text-[#FF1C1C]'
                                  : passwordStrength === 'Medium'
                                  ? 'text-yellow-500'
                                  : passwordStrength === 'Strong'
                                  ? 'text-[#63B72F]'
                                  : ''
                              }`}
                            >
                              {passwordStrength}
                            </div>
                            <div className="w-[80%] flex">
                              <div
                                className={`border mx-[3px]
                      ${
                        passwordStrength === 'Weak'
                          ? 'border-[#FF1C1C] w-full h-full rounded-[5px]'
                          : passwordStrength === 'Medium'
                          ? 'border-yellow-500 w-full h-full rounded-[5px]'
                          : passwordStrength === 'Strong'
                          ? 'border-[#63B72F] w-full h-full rounded-[5px]'
                          : 'border-[#D1D1D1] w-full h-full rounded-[5px]'
                      }
                    `}
                              />
                              <div
                                className={`border mx-[3px]
										${
                      passwordStrength === 'Medium'
                        ? 'border-yellow-500 w-full h-full rounded-[5px]'
                        : passwordStrength === 'Strong'
                        ? 'border-[#63B72F] w-full h-full rounded-[5px]'
                        : 'border-[#D1D1D1] w-full h-full rounded-[5px]'
                    }
											`}
                              />
                              <div
                                className={`border mx-[3px]
										${
                      passwordStrength === 'Strong'
                        ? 'border-[#63B72F] w-full h-full rounded-[5px]'
                        : 'border-[#D1D1D1] w-full h-full rounded-[5px]'
                    }
											`}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="flex relative flex-col">
                          <span className="text-[#9F9F9F]">Confirm Password</span>
                          <div className="relative flex items-center">
                            <HtmlTooltip
                              title={
                                <Fragment>
                                  <div className="text-[13px]">
                                    <div
                                      className={`flex items-center mb-[2px] ${
                                        SamePassword ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                                      }`}
                                    >
                                      {SamePassword ? <Correct /> : <Incorrect />}
                                      <span className="ml-2">
                                        New Password and Confirm Password are Not Matching
                                      </span>
                                    </div>
                                  </div>
                                </Fragment>
                              }
                              open={!SamePassword && ConfirmPassword.length > 0}
                              placement="right-start"
                              arrow
                            >
                              <input
                                className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                                type={PasswordVisible.confirm ? 'text' : 'password'}
                                value={ConfirmPassword}
                                onChange={(e) => handleInputChange(e, true)}
                              />
                            </HtmlTooltip>
                            <div
                              className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-[5px]"
                              onClick={() => {
                                setPasswordVisible({
                                  ...PasswordVisible,
                                  confirm: !PasswordVisible.confirm,
                                });
                              }}
                            >
                              {!PasswordVisible.confirm ? (
                                <VisibilityIcon className="text-[#9F9F9F]" />
                              ) : (
                                <VisibilityOffIcon className="text-[#9F9F9F]" />
                              )}
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <button
                          type="submit"
                          className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                        >
                          Change Password
                        </button>
                      </form>
                    </div>
                  ) : type === 'reminder' ? (
                    <div className="flex items-center justify-center flex-col">
                      <span className="text-[#4E4E4E] text-[30px] font-Semi-Bold">
                        Password Expire
                      </span>
                      <br />
                      <span className="text-[18px] text-[#575757] text-center">
                        Your Admin Password is set to expire every 90 days for security reasons.
                        This is a reminder to change your password
                      </span>
                      <br />
                      <br />
                      <div className="flex justify-between w-full">
                        <button
                          onClick={() => {
                            setType('reset');
                          }}
                          className="p-[5px] w-full rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                        >
                          Change Password
                        </button>

                        <button
                          onClick={() => {
                            setOpenWarning(false);
                            verifyToken(AuthToken).then(() => navigate('/customer/dashboard'));
                            localStorage.setItem('user', JSON.stringify(UserData));
                          }}
                          className="p-[5px] w-full ml-3 rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                        >
                          Do it Later
                        </button>
                      </div>
                      {/* <button
                        onClick={() => {}}
                        className="p-[5px] w-full rounded-[10px] text-body-color-3 bg-brand-color outline-none"
                      >
                        Change Password
                      </button> */}
                    </div>
                  ) : null}
                  <br />
                  <br />
                </div>
                <ErrorMessages
                  OpenError={OpenError}
                  setOpenError={setOpenError}
                  ErrorMessage={ErrorMessage}
                  setErrorMessage={setErrorMessage}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

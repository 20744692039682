import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setSelectedDate } from '../../Store/ProgressAnalysisSlice';
import { MobileDatePicker } from '@mui/x-date-pickers';

const tomorrow = dayjs().add(1, 'day');
const DatePickerMUI = ({ disabled, value }) => {
  const [dateValue, setDateValue] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (value?.length > 1) {
      let _value = value.split('-');
      setDateValue(dayjs(new Date(_value[2], _value[1] - 1, _value[0])));
    }
  }, []);
  const handleDateChange = (date) => {
    setDateValue(date);
    let _date = moment(date?.$d).format('DD/MM/YYYY');
    dispatch(setSelectedDate(_date));
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          sx={{
            // .MuiInputBase-root-MuiFilledInput-root.Mui-error:before, .MuiInputBase-root-MuiFilledInput-root.Mui-error:after
            '& .MuiInputBase-root.MuiFilledInput-root.Mui-error': {
              '&:before': {
                borderBottomColor: 'black !important',
              },
              '&:after': {
                borderBottomColor: 'black !important',
                borderBottom: '1px solid black',
              },
            },

            '& .MuiInputBase-root': {
              bgcolor: 'white !important',
              '& .MuiInputBase-input': {
                color: 'black',
                fontWeight: '500',
                fontSize: '12px',
                width: '163px',
              },
              '& .MuiSvgIcon-root': {
                color: 'black',
              },

              '& .MuiFilledInput-input': {
                paddingTop: '0px !important',
              },
              '& .MuiIconButton-root': {
                paddingBottom: '13px !important',
              },
            },
          }}
          slotProps={{ textField: { variant: 'filled' } }}
          format="DD/MM/YYYY"
          disabled={disabled}
          value={dateValue} // Replace with your initial date value if needed
          onChange={handleDateChange}
          defaultValue={tomorrow}
          disableFuture
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePickerMUI;

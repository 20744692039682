import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import IngredientsUsed from './IngredientsUsed';
import NutritionProfile from './NutritionProfile';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';





const RacipeForm = ({ isOpen, isclose, postData, ingredientData, nutritionData, recipeName }) => {
  // const [ingredientData, setIngredientData] = useState([]);
  // const [nutritionData, setNutritionData] = useState([]);
  // const [recipeName, setRecipeName] = useState("");
  // useEffect(() => {
  //   if (isOpen) {
  //     let token = getToken();
  //     let config = {
  //       baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe/breakdown`,
  //       method: 'POST',
  //       data: postData,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-type': 'application/json',
  //       },
  //     };
  //     axios
  //       .request(config)
  //       .then(({ data }) => {
  //         console.log("data", data.data[0]?.nutrition_profile)
  //         setIngredientData(data.data[0]?.ingredients)
  //         setNutritionData(data.data[0]?.nutrition_profile);
  //         setRecipeName(data.data[0]?.recipe_name);
  //       })
  //       .catch((err) => {
  //         toast(err, {
  //           position: 'top-center',
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'light',
  //         });
  //       });
  //   }
  // }, [isOpen])
  // console.log("nutData", nutritionData, ingredientData)

  return (
    <>
      <div>
        <Transition appear show={isOpen}>
          <Dialog className="relative z-10" onClose={isclose}>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-70" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className={`rounded-2xl bg-white shadow-xl 2xl:h-[844px]  2xl:w-[1117px]   lg:w-[720px] xl:w-[864px] xl:h-[652px] lg:h-[544px]`}>
                    <Dialog.Title>
                      <div className="flex justify-between items-center 2xl:p-5 border-b 2xl:px-5 xl:px-4 lg:px-3">
                        <div></div>
                        <div className="text-shades-2 font-medium 2xl:text-2xl 2xl:my-[20px] xl:my-[15px] lg:my-[13px]">
                          {recipeName}
                        </div>
                        <div className="cursor-pointer lg:px-5 xl:px-7 2xl:px-10">
                          <span>
                            <Close onClick={isclose} className=' 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px]' />
                          </span>
                        </div>
                      </div>
                      <div className="block sm:block md:flex lg:flex 2xl:gap-5 xl:gap-4 lg:gap-3 2xl:px-[8px] xl:px-[8px] lg:px-[8px] text-left">
                        <div className="lg:w-3/5 2xl:h-[717px] xl:h-[594px] lg:h-[495px] ">
                          <IngredientsUsed ingData={ingredientData} />
                        </div>
                        <div className="lg:w-2/5 sm:justify-center">
                          <NutritionProfile nutData={nutritionData} />
                        </div>
                      </div>

                    </Dialog.Title>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

export default RacipeForm;

import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactComponent as EditIcon } from '../../assets/images/edit_icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { resetDeaultClient, setClientsTable, setTotalCount } from '../../Store/ClientTableSlice';
import { formatedate } from '../../Utils/FormattingTime';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SortIcon from '../../assets/images/des.png';
import { useNavigate } from 'react-router';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';

const columnHelper = createColumnHelper();

const ClientTable = () => {
  const [indexbutton, setIndexButton] = useState([
    {
      id: 1,
    },
  ]);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let data = useSelector((state) => state.ClientTable.clientsTableData);
  let orgid = useSelector((state) => state.ClientTable.orgId);
  let search = useSelector((state) => state.ClientTable.searchQuery);
  let isNewSearch = useSelector((state) => state.ClientTable.isNewSearch);
  const [sorting, setSorting] = useState([]);

  let totalCount = useSelector((state) => state.ClientTable.totalCount);

  const [currentIndex, setCurrentIndex] = React.useState(1);

  const [userIdArray, setUserIdArray] = useState([]);

  const headingRef = useRef(null);
  const handleResize = () => {
    let headerHeight = 0;
    if (headingRef.current) {
      headerHeight = headingRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--table-heading-height', headerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const columns = [
    columnHelper.accessor((row) => row.Customer_Id, {
      id: 'Customer_Id',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span className="">Client Id</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.ClientName, {
      id: 'ClientName',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize break-all">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Client Name</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.EmailAddress, {
      id: 'Email Address',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="lowercase break-all">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Email Address</span>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.DateCreated, {
      id: 'DateCreated',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Date Created</span>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.Status, {
      id: 'Status',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Status</span>
        </div>
      ),
    }),
  ];
  useEffect(() => {
    const controller = new AbortController();
    let token = getToken();
    if (token) {
      dispatch(resetDeaultClient());
      let config = {
        baseURL: `${
          process.env.REACT_APP_BASE_URL
        }/admin/nutrition_plans/v1/diet_plans?size=50&page=${currentIndex}&search=${
          search || ''
        }&org_id=${orgid ? orgid.orgId : ''}`,
        method: 'get',
        signal: controller.signal,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((res) => {
          let { data: response } = res;

          if (response.code && response.code === '401') {
            window.location.assign('/auth-token');
          }

          if (response.result && response.result.toLowerCase() === 'success') {
            // console.log(response);
            // set_TotalCount(response.data[0].total_count);

            let plans = response.data[0].plans;

            let userIds = new Array();

            for (let i = 0; i < plans?.length; i++) {
              userIds.push(plans[i].user_id);
            }

            let newData = new Array();

            for (let i = 0; i < plans?.length; i++) {
              let obj = {
                Customer_Id: plans[i].customer_id,
                ClientName: plans[i].user_name.toLowerCase(),
                EmailAddress: plans[i].user_email,
                DateCreated: plans[i].created_at,
                Status: plans[i].verified,
                UserId: plans[i].user_id,
              };
              newData.push(obj);
            }

            // let newData = response.data[0].plans.map((plan) => {
            //   return {
            //     Customer_Id: plan.customer_id,
            //     ClientName: plan.user_name.toLowerCase(),
            //     EmailAddress: plan.user_email,
            //     DateCreated: plan.created_at,
            //     Status: plan.verified ? 'Verified' : 'Not Verified',
            //      UserId: plan.user_id,
            //   };
            // });

            setUserIdArray(userIds);

            // newData.sort(sortClientsByName);

            dispatch(setTotalCount(response.data[0].total_count));

            // console.log(newData);
            dispatch(setClientsTable(newData));
          }
        })
        .catch((err) => {
          if (
            err.response.data.code === '401' ||
            err.response.data.message === 'Invalid token !!'
          ) {
            navigate('/login');
            // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
          }
          // console.log('clients table Error', err.response.data.message);
        });
    } else {
      navigate('/login');
      // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
    }
    return () => {
      controller.abort();
    };
  }, [currentIndex, search, dispatch, orgid]);

  localStorage.removeItem('last-opened');

  useEffect(() => {
    setCurrentIndex(1);
  }, [isNewSearch]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    setSorting([]);
  }, [data]);

  useEffect(setPagination, [orgid]);

  useEffect(setPagination, [totalCount]);

  function setPagination() {
    if (data.length <= 0 || totalCount <= 0) {
      return;
    }
    let no_of_pages = Math.ceil(totalCount / 50);
    // console.log('no_of_pages', totalCount, data.length, no_of_pages);
    setIndexButton(
      Array(no_of_pages)
        .fill()
        .map((_, index) => ({ id: index + 1 }))
    );

    setCurrentIndex(1);
  }

  function getEntriesCount() {
    let remainder = totalCount % 50;

    if (remainder === 0 || currentIndex < indexbutton.length) {
      return data.length * currentIndex;
    } else {
      if (currentIndex === indexbutton.length) {
        return (currentIndex - 1) * 50 + remainder;
      }
    }
  }

  return (
    <div className={`lg:mt-[5px] table-client`}>
      {data.length > 0 ? (
        <>
          <div className="tableFixHead">
            <table className="min-w-[-webkit-fill-available] client-table table-margin ">
              <thead ref={headingRef} className="sticky top-0 items-center thead">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="overflow-hidden flex ">
                    {headerGroup.headers.map((header, index, array) => {
                      return (
                        <th
                          key={header.id}
                          className={`text-start col-${index} bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-5 last-of-type:border-r-0  py-[9px]  font-medium   2xl:text-xl xl:text-sm lg:text-xs  ${
                            header.id === 'Email Address' && ''
                          }`}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          <div className="flex items-center justify-between w-full">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header)}
                            {array.length - 1 === index
                              ? null
                              : {
                                  asc: (
                                    <img
                                      src={SortIcon}
                                      // width={20}
                                      // height={20}
                                      className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                      style={{ transform: 'rotate(180deg)' }}
                                      alt="SortIcon"
                                    />
                                  ),
                                  desc: (
                                    <img
                                      src={SortIcon}
                                      className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                      alt="SortIcon"
                                    />
                                  ),
                                  false: (
                                    <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                  ),
                                }[header.column.getIsSorted()]}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>

              <tbody className="duration-1000 tbody">
                {table.getRowModel().rows.map((row, ind) => (
                  <tr
                    key={row.id}
                    className="cursor-pointer clienttable_row even:bg-secondary-3/20 mb-1 flex w-full"
                    onClick={() => {
                      let userId = row.original.UserId;
                      navigate(`/dietplan/${userId}`);
                    }}
                  >
                    {row.getVisibleCells().map((cell, ind) => (
                      <td
                        key={cell.id}
                        className={`duration-1000 col-${ind} text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs  `}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-between md:py-3 text-secondary-1  ">
            <div className="flex ">
              <span className="text-body-color-1 font-Regular 2xl:text-base lg:text-xs">
                Showing {getEntriesCount()} of {totalCount} entries
              </span>
            </div>
            <div className="flex items-center ">
              <button
                disabled={currentIndex === 1}
                className={`${
                  currentIndex !== 1 ? ' hover:bg-shades-2  hover:text-white ' : 'diabledBtnBg '
                }duration-200 buttons border first-of-type:rounded-s-[10px]   2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                onClick={() => {
                  if (currentIndex > 1) setCurrentIndex(currentIndex - 1);
                }}
              >
                Previous
              </button>
              <div className="flex">
                {indexbutton.map((value, id) => {
                  return (
                    <>
                      <button
                        key={id}
                        className={`${
                          currentIndex === value.id
                            ? ' bg-shades-2 text-white duration-500 '
                            : 'hover:bg-gray-100 duration-300'
                        }  duration-200    buttons border-t border-b  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                        onClick={() => setCurrentIndex(value.id)}
                      >
                        <div className="flex justify-center items-center 2xl:w-[16px] 2xl:h-[24px] xl:w-[19.18px] xl:h-[18px] lg:w-[16px] lg:h-[18px]">
                          {value.id}
                        </div>
                      </button>
                    </>
                  );
                })}
              </div>
              <button
                disabled={currentIndex === indexbutton.length}
                className={`${
                  currentIndex !== indexbutton.length
                    ? ' hover:bg-shades-2  hover:text-white '
                    : 'diabledBtnBg'
                } duration-200    border nxtbutton  last-of-type:rounded-e-[10px]  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                onClick={() => {
                  if (currentIndex < indexbutton.length) setCurrentIndex(currentIndex + 1);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="w-full h-full">
          <ClientError className="w-full h-full" />
        </div>
      )}
    </div>
  );
};

export default ClientTable;

export const sortOrganizations = function (a, b) {
  //   return a.org_name - b.org_name;
  if (a.org_name.trim().toLowerCase() < b.org_name.trim().toLowerCase()) {
    return -1;
  }
  if (a.org_name.trim().toLowerCase() > b.org_name.trim().toLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortClientsByName = function (a, b) {
  //   return a.ClientName - b.ClientName;

  if (a.ClientName.trim().toLowerCase() < b.ClientName.trim().toLowerCase()) {
    return -1;
  }
  if (a.ClientName.trim().toLowerCase() > b.ClientName.trim().toLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortClientsByNameDesc = function (a, b) {
  //   return a.ClientName - b.ClientName;

  if (b.ClientName.trim().toLowerCase() < a.ClientName.trim().toLowerCase()) {
    return -1;
  }
  if (b.ClientName.trim().toLowerCase() > a.ClientName.trim().toLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortClientsByEmail = function (a, b) {
  //   return a.ClientName - b.ClientName;

  if (a.EmailAddress.trim().toLowerCase() < b.EmailAddress.trim().toLowerCase()) {
    return -1;
  }
  if (a.EmailAddress.trim().toLowerCase() > b.EmailAddress.trim().toLowerCase()) {
    return 1;
  }
  return 0;
};

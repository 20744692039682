import React, { useMemo, useState, useEffect } from 'react';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SortIcon from '../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as Cancel } from '../../assets/images/cancle.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

export default function ActivityLog() {
  const [ActivityLog, setActivityLog] = useState({
    columns: [
      { id: 0, column_name: 'Name', map_column: 'admin_name', type: 'string' },
      { id: 1, column_name: 'Description', map_column: 'description', type: 'string' },
      { id: 1, column_name: 'Date', map_column: 'created_at', type: 'string' },
    ],
    rows: [
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
      { admin_name: -1, description: -1, created_at: -1 },
    ],
  });
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const optionTime = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  let { userId } = useParams();
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const columns = useMemo(() => {
    const { columns } = ActivityLog;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [ActivityLog]);

  const table = useReactTable({
    data: ActivityLog.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const getActivityLog = () => {
    const bodyForFetch = {
      user_id: userId,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/activity/logs`, {
        headers: headers,
        params: bodyForFetch,
      })
      .then((response) => {
        const storeActivity = response.data;
        setActivityLog((prevLog) => ({
          ...prevLog,
          rows: storeActivity.data,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getActivityLog();
  }, []);

  return (
    <>
      {/* <div as="h3" className="font-medium justify-center items-center flex  text-gray-900">
        <div className="w-full flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] text-shades-2 2xl:p-[14px] xl:p-[10px] lg:p-[14.89px] border-b">
          Activity Log
        </div>
      </div> */}
      <div className="content-rda">
        <div className="font-medium flex h-full text-gray-900">
          <div className="w-full flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] p-[10px] border-r overflow-auto">
            <table className="min-w-full client-table table-margin">
              <tbody className="duration-1000 tbody_rda">
                {table.getRowModel().rows.map((row, rId) => (
                  <tr
                    key={row.id}
                    className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
                  >
                    {row.getVisibleCells()?.map((cell, index) => (
                      <td
                        key={cell.id}
                        className={`duration-1000 text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 font-Regular flex   2xl:text-base xl:text-sm lg:text-xs ${
                          cell.column.id === 'admin_name'
                            ? 'w-[5%] flex justify-center items-center'
                            : cell.column.id === 'description'
                            ? 'w-[80%]'
                            : cell.column.id === 'created_at'
                            ? 'w-[30%]'
                            : ''
                        }`}
                      >
                        {cell.column.id === 'admin_name' ? (
                          <div className="flex justify-center items-center h-[30px] w-[30px] bg-[#BD9528] text-white rounded-[50%]">
                            {typeof cell.row.original?.admin_name === 'string'
                              ? cell.row.original.admin_name.slice(0, 1)
                              : ''}
                          </div>
                        ) : cell.column.id === 'created_at' ? (
                          <div>
                            {cell.row?.original?.created_at
                              ? new Intl.DateTimeFormat('en-US', optionTime).format(
                                  new Date(cell.row?.original?.created_at)
                                )
                              : 'Invalid Date'}
                          </div>
                        ) : (
                          flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

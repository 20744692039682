import { combineReducers } from '@reduxjs/toolkit';
import ClientTableSlice from './ClientTableSlice';
import UserDataSlice from './UserDataSlice';
import ProgressAnalysisSlice from './ProgressAnalysisSlice';

export default combineReducers({
  ClientTable: ClientTableSlice,
  UserData: UserDataSlice,
  ProgressAnalysis: ProgressAnalysisSlice,
});

import React, { Fragment, forwardRef, useMemo, useState } from 'react';
import './Customedata';
import { ReactComponent as Warning } from '../../assets/images/warning.svg';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment/moment';
import { ReactComponent as Rightcircular } from '../../assets/images/right_circle.svg';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { ReactComponent as UnCheck } from '../../assets/images/select.svg';
import { ReactComponent as Checked } from '../../assets/images/selected.svg';
import { ReactComponent as Copy } from '../../assets/images/copy_icon.svg';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import { ReactComponent as Paste } from '../../assets/images/pasteIcon.svg';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useRef } from 'react';
import { ReactComponent as DietPageErrror } from '../../assets/images/DietPageErrror.svg';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { getToken } from '../../Utils/Authenticate';
import { ReactComponent as Add } from '../../assets/images/add_.svg';
import axios from 'axios';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import RacipeForm from './RacipeForm';
import { Dialog, Transition } from '@headlessui/react';

const DietPlaneTable = forwardRef(
  (
    {
      setCopyFrom,
      copyFrom,
      planDetails: userdata,
      loading,
      setLoading,
      setRefreshData,
      setTableHeadingHeight,
      setDietPlans,
      DietPlans,
      setUserData,
      setCallApi,
      CallApi,
      verification,
    },
    ref
  ) => {
    const navigate = useNavigate();
    const [openCnfm, setOpenCnfm] = useState(false);
    const [cnfmData, setCnfmData] = useState({});
    const [openRecipeModal, setOpenRecipeModal] = useState(false);
    const [postData, setPostData] = useState({});
    const [ingredientData, setIngredientData] = useState([]);
    const [nutritionData, setNutritionData] = useState([]);
    const [recipeName, setRecipeName] = useState('');
    const [showPaste, setShowPaste] = useState(false);
    const [copiedData, setCopiedData] = useState([]);
    const [pasteData, setPasteData] = useState(-1);
    const [OpenListForReport, setOpenListForReport] = useState(false);
    function closeRecipeModal() {
      setOpenRecipeModal(!openRecipeModal);
    }

    const headerRow = useRef(null);

    let { userId } = useParams();

    const handleResize = () => {
      if (headerRow.current) {
        setTableHeadingHeight(headerRow.current.getBoundingClientRect().height);
      }
    };

    const handleIconClick = (plans) => {
      setDietPlans((prevDietPlans) => {
        if (prevDietPlans.includes(plans)) {
          return prevDietPlans.filter((selectedPlans) => selectedPlans !== plans);
        } else {
          return [...prevDietPlans, plans];
        }
      });
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const rowWiseData = useMemo(() => {
      if (!userdata || userdata?.length === 0) return [];
      if (copiedData.length > 0 && userdata[pasteData]) {
        userdata[pasteData].sections = copiedData;
      }
      let rowSize = userdata[0]?.sections?.length;
      let max = 0;
      for (let i = 0; i < userdata?.length; i++) {
        max = Math.max(max, userdata[i]?.sections?.length);
      }
      rowSize = max;
      let columnSize = userdata?.length;
      let table = [];
      for (let i = 0; i < rowSize; i++) {
        table.push([]);
        for (let j = 0; j < columnSize; j++) {
          table[i].push({});
        }
      }
      const undefinedIndex = {};
      for (let i = 0; i < rowSize; i++) {
        for (let j = 0; j < columnSize; j++) {
          const sections = userdata[j]?.sections;
          if (sections[i] === undefined && !undefinedIndex[j]) {
            if (i === 0) {
              undefinedIndex[j] = true;
              table[i][j] = 'rowSpan';
            } else {
              table[i][j] = 'empty';
            }
          } else {
            table[i][j] = sections[i];
          }
        }
      }

      return table;
    }, [userdata, pasteData]);

    const openCnfmModal = () => {
      setOpenCnfm(true);
    };

    const closeDeleteCnfm = () => {
      setOpenCnfm(!openCnfm);
    };

    const deletePlan = (planId) => {
      setLoading(true);
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/remove_diet_plan`,
        method: 'DELETE',
        data: {
          plan_id: planId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        },
      };
      axios
        .request(config)
        .then(
          ({ data }) => {
            setRefreshData((old) => !old);
            // toast.success(data.message)
            // closeis();
          },
          (err) => {
            // toast.error(err.message)
            // closeis();
          }
        )
        .catch((err) => {
          // toast.error(err);
          // closeis();
        });
    };

    return (
      <>
        <div className=" overflow-scroll w-full">
          {!loading ? (
            <>
              {userdata?.length > 0 ? (
                <table ref={ref} className="overflow-x-auto">
                  <thead className="sticky top-0 items-center bg-white">
                    <tr ref={headerRow} className="border-t">
                      {userdata?.length > 0 &&
                        userdata.map((value, id) => {
                          return (
                            <>
                              <th
                                className="border-r  lg:min-w-[280px] md:min-w-[260px] p-0 border-b px-5 py-2 xl:px-[17.78px] 2xl:py-[10px]"
                                key={id}
                              >
                                <div className="flex justify-between gap-1">
                                  <div className="flex  text-start">
                                    <div>
                                      {value.date === '-1' ? (
                                        <div style={{ width: '150px', border: 'none' }}>
                                          <Skeleton width="150px" baseColor="#e9d39633"></Skeleton>
                                        </div>
                                      ) : (
                                        <div className=" min-w-[180px] max-w-[180px] 2xl:text-xl xl:text-[17px] lg:text-sm font-Regular truncate">
                                          {value.label}
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      {value.is_approved === null ? (
                                        <div style={{ width: '100%', border: 'none' }}>
                                          <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                        </div>
                                      ) : (
                                        <div
                                          className=" lg:mt-[8px] md:mt-2 md:mr-[5px] lg:text-[8px] md: text-[6px] "
                                          title={
                                            value?.is_approved === false
                                              ? 'Not Approved'
                                              : 'Approved'
                                          }
                                        >
                                          {value.is_approved === false ? (
                                            <Warning />
                                          ) : (
                                            <Rightcircular />
                                          )}
                                          <Tooltip id="my-tooltip" />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {value.plan_id === -1 ? (
                                    <div style={{ width: '50px', border: 'none' }}>
                                      <Skeleton
                                        width="50px"
                                        height="10px"
                                        baseColor="#e9d39633"
                                      ></Skeleton>
                                    </div>
                                  ) : (
                                    <>
                                      {verification?.can_create && (
                                        <div className="flex gap-[10px] mt-2 ">
                                          <div onClick={(e) => {}}>
                                            {DietPlans.includes(value.plan_id) ? (
                                              <Checked
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  handleIconClick(value.plan_id);
                                                }}
                                              />
                                            ) : (
                                              <>
                                                <UnCheck
                                                  className="cursor-pointer"
                                                  onClick={() => {
                                                    if (
                                                      DietPlans.length === 0 &&
                                                      userdata.length !== 1
                                                    ) {
                                                      setOpenListForReport(true);
                                                    }
                                                    handleIconClick(value.plan_id);
                                                  }}
                                                />
                                              </>
                                            )}
                                          </div>
                                          <div to="/creatediet">
                                            <Edit
                                              onClick={() => {
                                                const params = userId.split('/');
                                                const _userId = params[params.length - 1];
                                                navigate(`/createDiet/${_userId}/${value.plan_id}`);
                                              }}
                                              className={`${
                                                showPaste
                                                  ? ' pointer-events-none opacity-30'
                                                  : 'pointer-events-auto cursor-pointer '
                                              }`}
                                            />
                                          </div>
                                          {!showPaste ? (
                                            <div className="cursor-pointer">
                                              <Copy
                                                onClick={() => {
                                                  setCopyFrom(value);
                                                  setShowPaste(true);
                                                }}
                                                className={`${
                                                  value?.sections.length == 0
                                                    ? ' pointer-events-none opacity-30'
                                                    : 'pointer-events-auto cursor-pointer '
                                                }`}
                                              />
                                            </div>
                                          ) : (
                                            <div className="cursor-pointer my-anchor-element">
                                              <Tooltip anchorSelect=".my-anchor-element">
                                                Paste Here
                                              </Tooltip>

                                              <Paste
                                                className={`${
                                                  copyFrom.plan_id === value.plan_id
                                                    ? 'hidden '
                                                    : ' block'
                                                }`}
                                                onClick={() => {
                                                  let token = getToken();
                                                  let config = {
                                                    baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/paste_plan`,
                                                    method: 'POST',
                                                    data: {
                                                      copy_from: copyFrom.plan_id,
                                                      copy_to: value.plan_id,
                                                    },
                                                    headers: {
                                                      Authorization: `Bearer ${token}`,
                                                      'Content-type': 'application/json',
                                                    },
                                                  };
                                                  axios
                                                    .request(config)
                                                    .then((data) => {
                                                      const _newCopy = { ...copyFrom };
                                                      _newCopy.date = value.date;
                                                      _newCopy.label = value.label;
                                                      _newCopy.day = value.day;
                                                      _newCopy.day_number = value.day_number;

                                                      const current = {
                                                        ..._newCopy,
                                                        plan_id: value.plan_id,
                                                      };
                                                      setUserData([
                                                        ...userdata.slice(0, id),
                                                        current,
                                                        ...userdata.slice(id + 1),
                                                      ]);
                                                      setPasteData((old) => !old);
                                                      setPasteData(id);
                                                      setShowPaste(false);
                                                      setCallApi(CallApi + 1);
                                                    })
                                                    .catch((error) => {
                                                      toast.error(error);
                                                    });
                                                }}
                                              />
                                            </div>
                                          )}

                                          <div
                                            className={`${
                                              showPaste
                                                ? 'pointer-events-none opacity-30'
                                                : 'pointer-events-auto'
                                            }  cursor-pointer`}
                                          >
                                            <Delete
                                              onClick={() => {
                                                deletePlan(value?.plan_id);
                                                // openCnfmModal();
                                                // let data = {
                                                //   planId: value?.plan_id,
                                                //   date: moment(value.date, 'DD-MM-YYYY').format(
                                                //     'DD MMM YYYY'
                                                //   ),
                                                // };
                                                // setCnfmData(data);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>

                                <div className="flex">
                                  {value.day_number === -1 ? (
                                    <div style={{ width: '120px', border: 'none' }}>
                                      <Skeleton width="120px" baseColor="#e9d39633"></Skeleton>
                                    </div>
                                  ) : (
                                    <div className="flex font-medium text-body-color-2    2xl:text-xs xl:text-[10px] lg:text-[8px]">
                                      {moment(value.date, 'DD-MM-YYYY').format('DD MMM YYYY')}, Day
                                      {value.day_number},
                                    </div>
                                  )}

                                  {value.day === '-1' ? (
                                    <div style={{ width: '100%', border: 'none' }}>
                                      <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                    </div>
                                  ) : (
                                    <div className="font-medium text-body-color-2    2xl:text-xs xl:text-[10px] lg:text-[8px]">
                                      &nbsp;{value.day}
                                    </div>
                                  )}
                                </div>
                              </th>
                            </>
                          );
                        })}
                    </tr>
                  </thead>
                  <tbody className=" ">
                    {rowWiseData?.length > 0 &&
                      rowWiseData.map((data, index, heading) => {
                        // console.log("rwd", rowWiseData)
                        return (
                          <tr className="border-b" key={index}>
                            {data.map((fields = {}, id) => {
                              if (
                                fields?.hasOwnProperty('meal_time') &&
                                fields?.hasOwnProperty('recipes') &&
                                fields?.hasOwnProperty('meal')
                              ) {
                                const { meal_time, recipes, meal } = fields;
                                return (
                                  <td className="align-baseline  border-r">
                                    <div className="flex justify-around 2xl:p-5 xl:p-4 lg:p-3 md:p-2">
                                      {meal_time === '-1' ? (
                                        <div style={{ width: '100%', border: 'none' }}>
                                          <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                        </div>
                                      ) : (
                                        <div className=" lg:w-[70px] md:w-[60px] font-medium text-body-color-1    2xl:text-xs xl:text-[10.67px] lg:text-[8px]">
                                          {meal_time}
                                        </div>
                                      )}

                                      <div className="flex flex-col items-start justify-start  lg:w-[190px] md:w-[185px]">
                                        {meal === '-1' ? (
                                          <div style={{ width: '100%', border: 'none' }}>
                                            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
                                          </div>
                                        ) : (
                                          <div className="font-medium text-body-color-1  2xl:text-xs xl:text-[10.67px] lg:text-[8.89px]">
                                            {meal}
                                          </div>
                                        )}

                                        {recipes.map(
                                          ({
                                            recipe_name,
                                            serving,
                                            recipe_id,
                                            measurement,
                                            value_in_gm,
                                          }) => {
                                            return (
                                              <>
                                                <div className="flex items-center justify-start mt-1">
                                                  {recipe_name === '-1' ? (
                                                    <div style={{ width: '100%', border: 'none' }}>
                                                      <Skeleton
                                                        width="100%"
                                                        baseColor="#e9d39633"
                                                      ></Skeleton>
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="text-body-color-2   2xl:text-xs xl:text-[10.67px] lg:text-[8.89px] font-bold cursor-pointer"
                                                      onClick={() => {
                                                        let data = {
                                                          recipe_id: recipe_id,
                                                          serving: serving,
                                                          value_in_gm: value_in_gm,
                                                        };
                                                        setPostData(data);
                                                        let token = getToken();
                                                        let config = {
                                                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe/breakdown`,
                                                          method: 'POST',
                                                          data: data,
                                                          headers: {
                                                            Authorization: `Bearer ${token}`,
                                                            'Content-type': 'application/json',
                                                          },
                                                        };
                                                        axios
                                                          .request(config)
                                                          .then(
                                                            ({ data }) => {
                                                              setIngredientData(
                                                                data.data[0]?.ingredients
                                                              );
                                                              setNutritionData(
                                                                data.data[0]?.nutrition_profile
                                                              );
                                                              setRecipeName(
                                                                data.data[0]?.recipe_name
                                                              );

                                                              setOpenRecipeModal(!openRecipeModal);
                                                            },
                                                            (err) => {
                                                              setOpenRecipeModal(!openRecipeModal);
                                                            }
                                                          )
                                                          .catch((err) => {
                                                            setOpenRecipeModal(!openRecipeModal);
                                                            toast(err, {
                                                              position: 'top-center',
                                                              autoClose: 5000,
                                                              hideProgressBar: false,
                                                              closeOnClick: true,
                                                              pauseOnHover: true,
                                                              draggable: true,
                                                              progress: undefined,
                                                              theme: 'light',
                                                            });
                                                          });
                                                      }}
                                                    >
                                                      {recipe_name}({serving}&nbsp;
                                                      {measurement})
                                                    </div>
                                                  )}
                                                  {serving === -1 ? (
                                                    <div style={{ width: '100%', border: 'none' }}>
                                                      <Skeleton
                                                        width="100%"
                                                        baseColor="#e9d39633"
                                                      ></Skeleton>
                                                    </div>
                                                  ) : (
                                                    <div className='"text-body-color-2   lg:text-[8px] md: text-[6px] font-Regular'></div>
                                                  )}
                                                </div>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else if (fields === 'rowSpan') {
                                return (
                                  <td
                                    rowSpan={rowWiseData.length}
                                    className="2xl:p-5 xl:p-4 lg:p-3 md:p-2 border-r"
                                    onClick={() => {
                                      const params = userId.split('/');
                                      const _userId = params[params.length - 1];
                                      navigate(`/createDiet/${_userId}/${userdata[id].plan_id}`);
                                    }}
                                  >
                                    <div className="flex flex-col items-center gap-4 lg:w-[262px] md:w-[247px]">
                                      <Add className=" cursor-pointer" />
                                      <p className="text-center text-body-color-2 lg:text-[12px] md:text-[10px] font-Regular">
                                        Click on edit icon to add the diet plan
                                      </p>
                                    </div>
                                  </td>
                                );
                              } else if (fields === 'empty') {
                                return <td className="px-5 border-r"></td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              ) : (
                <div className="w-full h-full p-12 flex justify-center items-center ">
                  <DietPageErrror className="object-cover w-full h-full" />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="ml-9 mt-9">
                <Skeleton width="400px" baseColor="#e9d39633"></Skeleton>
                <Skeleton width="580px" baseColor="#e9d39633"></Skeleton>
                <Skeleton width="700px" baseColor="#e9d39633"></Skeleton>
              </div>
              <div className="ml-9 mt-9">
                <Skeleton width="440px" baseColor="#e9d39633"></Skeleton>
                <Skeleton width="580px" baseColor="#e9d39633"></Skeleton>
                <Skeleton width="700px" baseColor="#e9d39633"></Skeleton>
              </div>
            </>
          )}
        </div>
        {/* <DeleteConfirmationModal
          openis={openCnfm}
          closeis={closeDeleteCnfm}
          data={cnfmData}
          setRefreshData={setRefreshData}
        /> */}
        {/* <RacipeForm isOpen={openRecipeModal} isclose={closeRecipeModal} postData={postData} /> */}
        <RacipeForm
          isOpen={openRecipeModal}
          isclose={closeRecipeModal}
          postData={postData}
          ingredientData={ingredientData}
          nutritionData={nutritionData}
          recipeName={recipeName}
        />
        <Transition show={OpenListForReport} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center  text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="transform overflow-hidden text-[18px] w-[25%] h-[230px] rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px] text-center justify-center flex font-medium 2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] border-b items-center text-shades-2"
                    >
                      Confirmation
                    </Dialog.Title>
                    <div className="flex justify-center items-center text-base m-[20px] h-[22%]">
                      Do you want to select all ?
                    </div>
                    <div className="flex justify-center border-t absolute bottom-0 w-full my-[10px]">
                      <div
                        className="cursor-pointer mt-[10px] rounded-small border border-transparent mr-[12px] bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-medium text-white"
                        onClick={() => {
                          setDietPlans(() => {
                            const allPlanIdsSet = new Set(userdata.map((value) => value.plan_id));
                            const allPlanIds = Array.from(allPlanIdsSet);
                            return allPlanIds;
                          });
                          setOpenListForReport(false);
                        }}
                      >
                        Yes
                      </div>
                      <div
                        className="cursor-pointer rounded-small border border-transparent mt-[10px] bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-medium text-white"
                        onClick={() => {
                          setOpenListForReport(false);
                        }}
                      >
                        No
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  }
);

export default DietPlaneTable;

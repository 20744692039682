import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './Store/configStore';
import { ToastContainer } from 'react-toastify';
const root = ReactDOM.createRoot(document.getElementById('root'));
const toastRoot = document.createElement('div');
toastRoot.id = 'toast';
document.body.appendChild(toastRoot);
if (
  window.location.origin === 'https://admin-v2.unlock.fit' ||
  window.location.origin === 'https://admin-panelv2.staging.unlock.fit'
) {
  console.log = function () {};
}
ReactDOM.createRoot(toastRoot).render(
  <ToastContainer
    position="bottom-left"
    autoClose={2000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
    closeButton={false}
    zIndex={9999}
  />
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();

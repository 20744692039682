import React, { useEffect, useState } from 'react';
import { ReactComponent as Plus } from '../../assets/images/countadd.svg';
import { ReactComponent as Incres } from '../../assets/images/remove.svg';
import { toast } from 'react-toastify';
import MeasuringDisclose from '../shared/MeasuringDisclose';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as Cancle } from '../../assets/images/cancle.svg';
import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import { useParams } from 'react-router-dom';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { ClipLoader, HashLoader } from 'react-spinners';
import RacipeForm from './RacipeForm';
import { Tooltip } from '@mui/material';

const CountSectionRow = ({
  recipe_name,
  danger_message,
  serving,
  masterMeasurement,
  recipe_id,
  meal_type_id,
  Measurement,
  setRefreshData,
  refreshdata,
  setRecipes,
  index,
  setEnableApprove,
  setnutritionProfile,
  dangerous,
  setCallApi,
  CallApi,
  setUserData,
  userdata,
}) => {
  const [count, setCount] = useState(Number.parseFloat(serving?.toFixed(1)));
  const { planId } = useParams();
  const [overlay, setOverlay] = useState(false);
  const [hide, setHide] = useState(false);
  const [focused, setFocused] = useState(false);
  const [selectedMesurement, setSelectedMesurement] = useState();
  const [openRecipeModal, setOpenRecipeModal] = useState(false);
  const [postData, setPostData] = useState({});
  const [ingredientData, setIngredientData] = useState([]);
  const [nutritionData, setNutritionData] = useState([]);
  const [recipeName, setRecipeName] = useState('');

  function closeRecipeModal() {
    setOpenRecipeModal(!openRecipeModal);
  }

  useEffect(() => {
    setCount(Number.parseFloat(serving?.toFixed(1)));
  }, [serving]);

  // useEffect(() => {
  //   if (openRecipeModal) {
  //     let token = getToken();
  //     let config = {
  //       baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe/breakdown`,
  //       method: 'POST',
  //       data: postData,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-type': 'application/json',
  //       },
  //     };
  //     axios
  //       .request(config)
  //       .then(({ data }) => {
  //         console.log("data", data.data[0]?.nutrition_profile)
  //         setIngredientData(data.data[0]?.ingredients)
  //         setNutritionData(data.data[0]?.nutrition_profile);
  //         setRecipeName(data.data[0]?.recipe_name);
  //       })
  //       .catch((err) => {
  //         toast(err, {
  //           position: 'top-center',
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: 'light',
  //         });
  //       });

  //   }
  // }, [openRecipeModal])

  // useEffect(() => {
  //   if (count === serving) {
  //     setHide(false);
  //   }
  // }, [count, serving]);

  // useEffect(() => {
  //   setCount(selectedMesurement?.serving);
  // }, [selectedMesurement]);
  // useEffect(() => {
  //   setHide(true);
  // }, [selectedMesurement]);

  return (
    <>
      {overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
        </div>
      )}

      <div className="flex items-center justify-between ">
        <div className="flex items-center justify-between w-1/2">
          {/* 2xl:py-[13px] lg:py-[10px] md:py-[8px] */}
          <div
            className="  2xl:text-xs xl:text-[10px] lg:leading-0 lg:text-[8px]   font-Regular  cursor-pointer min-w-fit"
            onClick={() => {
              const matchingMeasurement = masterMeasurement.find(
                (item) => item.measurement_unit === Measurement
              );
              let data = {
                recipe_id: recipe_id,
                serving: count,
                value_in_gm: matchingMeasurement?.value_in_gm,
              };
              setPostData(data);
              let token = getToken();
              let config = {
                baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/recipe/breakdown`,
                method: 'POST',
                data: data,
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-type': 'application/json',
                },
              };
              axios
                .request(config)
                .then(
                  ({ data }) => {
                    setIngredientData(data.data[0]?.ingredients);
                    setNutritionData(data.data[0]?.nutrition_profile);
                    setRecipeName(data.data[0]?.recipe_name);

                    setOpenRecipeModal(!openRecipeModal);
                  },
                  (err) => {
                    setOpenRecipeModal(!openRecipeModal);
                  }
                )
                .catch((err) => {
                  setOpenRecipeModal(!openRecipeModal);
                  toast(err, {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                  });
                });
            }}
          >
            <Tooltip title={dangerous ? danger_message : ''} placement="top">
              <span
                className={
                  dangerous
                    ? `truncate-custom overflow-hidden text-ellipsis whitespace-break-spaces font-bold lg:max-w-[90px] xl:max-w-[160px] 2xl:max-w-[230px] text-secondary-4`
                    : `truncate-custom overflow-hidden text-ellipsis whitespace-break-spaces font-bold lg:max-w-[90px] xl:max-w-[160px] 2xl:max-w-[230px]`
                }
              >
                {recipe_name}
              </span>
            </Tooltip>
          </div>
          <div className="flex items-center 2xl:py-[6px] 2xl:px-2 lg:py-[4px] lg:px-[6px] md:px-1 ">
            <button
              className="border-t border-l border-b 2xl:py-[15px] 2xl:px-[15px] lg:py-[11px] lg:px-[8px] md:px-[11px] md:py-[10px] rounded-Count-Radius cursor-pointer"
              onClick={() => {
                if (count > 0.0) {
                  setOverlay(true);
                  setCount(count - 1.0);
                  let token = getToken();
                  let config = {
                    baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/update_recipe_serving`,
                    method: 'PATCH',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-type': 'application/json',
                    },
                    data: {
                      plan_id: planId,
                      meal_type_id: meal_type_id,
                      recipe_id: recipe_id,
                      serving: count - 1.0,
                      type: 'measurement',
                      unit: selectedMesurement?.measurement_unit,
                      measurement: selectedMesurement,
                    },
                  };
                  axios
                    .request(config)
                    .then((response) => {
                      // setCallApi(CallApi + 1);
                      const finalData = response.data.data[0];
                      setUserData((prevUserData) =>
                        prevUserData.map((user) => {
                          if (user.meal_type_id === finalData.meal_type_id) {
                            return {
                              ...user,
                              nutrition_profile: finalData.nutrient_profile,
                              suggested_recipes: user.suggested_recipes.map((recipe) => {
                                if (recipe.recipe_id === recipe_id) {
                                  return { ...recipe, serving: count - 1.0 };
                                }
                                return recipe;
                              }),
                            };
                          }
                          return user;
                        })
                      );
                      // setUserData((prevUserData) => {
                      //   return prevUserData.map((item) => {
                      //     if (item.meal_type_id === meal_type_id) {
                      //       return {
                      //         ...item,
                      //         nutrition_profile: data.data[0].nutrient_profile,
                      //       };
                      //     }
                      //     return item;
                      //   });
                      // });
                      setOverlay(false);
                      setRefreshData((old) => !old);
                      // setEnableApprove(data.data[0]?.is_approved);
                      // setnutritionProfile(data.data[0].nutrient_profile);
                      // // toast.success(data.message);
                    })
                    .catch((err) => {
                      toast(err);
                      setOverlay(false);
                    });
                  // setHide(true);
                } else {
                  toast.error('serving cannot be less than zero');
                  return;
                }
              }}
            >
              <Incres />
            </button>
            <div className="border-l border-b border-t 2xl:py-[8px] xl:py-[4.5px] lg:py-[6px] flex justify-center items-center">
              <input
                type="number"
                onChange={(e) => {
                  // setHide(true);
                  // if (e.target.value > 0) {
                  setCount(e.target.value);
                  // } else {
                  //   toast.error('value cannot be less than 0');
                  // }
                  setOverlay(true);
                  let token = getToken();
                  let config = {
                    baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/update_recipe_serving`,
                    method: 'PATCH',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-type': 'application/json',
                    },
                    data: {
                      plan_id: planId,
                      meal_type_id: meal_type_id,
                      recipe_id: recipe_id,
                      serving: parseFloat(e.target.value),
                      type: 'measurement',
                      unit: selectedMesurement.measurement_unit,
                      measurement: selectedMesurement,
                    },
                  };
                  axios
                    .request(config)
                    .then((response) => {
                      // setCallApi(CallApi + 1);
                      const finalData = response.data.data[0];
                      setUserData((prevUserData) =>
                        prevUserData.map((user) => {
                          if (user.meal_type_id === finalData.meal_type_id) {
                            return {
                              ...user,
                              nutrition_profile: finalData.nutrient_profile,
                              suggested_recipes: user.suggested_recipes.map((recipe) => {
                                if (recipe.recipe_id === recipe_id) {
                                  return { ...recipe, serving: parseFloat(e.target.value) };
                                }
                                return recipe;
                              }),
                            };
                          }
                          return user;
                        })
                      );
                      setRefreshData((old) => !old);
                      setOverlay(false);
                      // setEnableApprove(data.data[0]?.is_approved);
                      // setnutritionProfile(data.data[0].nutrient_profile);
                      // // toast.success(data.message);
                    })
                    .catch((err) => {
                      toast(err);
                      setOverlay(false);
                    });
                }}
                onFocus={() => {
                  setFocused(true);
                }}
                onBlur={() => {
                  // if (count == '' || count <= 0) {
                  //   toast.error('value cannot be less than 0');
                  // }
                  // setFocused(false);
                }}
                value={count == parseInt(count) && !focused ? count + '.0' : count}
                className="2xl:min-w-10 lg:min-w-[32px] md:w-7 px-2 outline-none     2xl:text-xs xl:text-[10px] lg:text-[8px] font-Regular  "
              />
            </div>
            <button
              className="border 2xl:py-[11px] 2xl:px-[12px] lg:py-[7px] lg:px-[8px] md:px-[8px] md:py-[5px] rounded-Incres-radius cursor-pointer"
              onClick={() => {
                setHide(true);
                setCount(Number(count) + 1.0);
                setOverlay(true);
                let token = getToken();
                let config = {
                  baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/update_recipe_serving`,
                  method: 'PATCH',
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-type': 'application/json',
                  },
                  data: {
                    plan_id: planId,
                    meal_type_id: meal_type_id,
                    recipe_id: recipe_id,
                    serving: Number(count) + 1.0,
                    type: 'measurement',
                    unit: selectedMesurement.measurement_unit,
                    measurement: selectedMesurement,
                  },
                };
                axios
                  .request(config)
                  .then((response) => {
                    // setCallApi(CallApi + 1);
                    setOverlay(false);
                    const finalData = response.data.data[0];
                    setUserData((prevUserData) =>
                      prevUserData.map((user) => {
                        if (user.meal_type_id === finalData.meal_type_id) {
                          return {
                            ...user,
                            nutrition_profile: finalData.nutrient_profile,
                            suggested_recipes: user.suggested_recipes.map((recipe) => {
                              if (recipe.recipe_id === recipe_id) {
                                return { ...recipe, serving: Number(count) + 1.0 };
                              }
                              return recipe;
                            }),
                          };
                        }
                        return user;
                      })
                    );
                    setRefreshData((old) => !old);
                    // setEnableApprove(data.data[0]?.is_approved);
                    // setnutritionProfile(data.data[0].nutrient_profile);
                    // // toast.success(data.message);
                  })
                  .catch((err) => {
                    toast(err);
                    setOverlay(false);
                  });
              }}
            >
              <Plus />
            </button>
          </div>
        </div>

        <div className="flex items-center justify-between  w-1/2 ml-5 2xl:py-[6px] 2xl:px-2 lg:py-[4px]">
          <div className={` border rounded  `}>
            <MeasuringDisclose
              selectedMesurement={selectedMesurement}
              setSelectedMesurement={setSelectedMesurement}
              Measurement={Measurement}
              masterMeasurement={masterMeasurement}
              setServing={setCount}
              count={count}
              meal_type_id={meal_type_id}
              planId={planId}
              recipe_id={recipe_id}
              setEnableApprove={setEnableApprove}
              setnutritionProfile={setnutritionProfile}
              setOverlay={setOverlay}
              setRefreshData={setRefreshData}
              setUserData={setUserData}
            />
          </div>
          {/* <div className="2xl:w-16 2xl:ml-2 lg:w-[54px] lg:ml-[6px] md:w-[44px] md:ml-[4px] pl-1">
            {hide && (
              <div className="flex items-center xl:gap-3 2xl:gap-5 lg:gap-2 ">
                {
                  <div
                    className="cursor-setSelected cursor-pointer"
                    onClick={() => {
                      if (count <= 0) {
                        setCount(1);
                        toast.error('servings cannot be zero');
                        return;
                      }
                      setHide(false);
                      setOverlay(true);
                      setFocused(false);
                      let token = getToken();
                      let config = {
                        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/update_recipe_serving`,
                        method: 'PATCH',
                        headers: {
                          Authorization: `Bearer ${token}`,
                          'Content-type': 'application/json',
                        },
                        data: {
                          plan_id: planId,
                          meal_type_id: meal_type_id,
                          recipe_id: recipe_id,
                          serving: count,
                          measurement: selectedMesurement,
                        },
                      };
                      axios
                        .request(config)
                        .then(({ data }) => {
                          setEnableApprove(data.data[0]?.is_approved);
                          setnutritionProfile(data.data[0].nutrient_profile);
                          // toast.success(data.message);
                          setOverlay(false);
                          setRefreshData((old) => !old);
                        })
                        .catch((err) => {
                          toast(err);
                          setOverlay(false);
                        });
                    }}
                  >
                    {
                      <Check
                        className={
                          '2xl:w-[17px] 2xl:h-[22px] xl:w-[15px] xl:h-[20px] lg:h-[11px] lg:w-[15px]'
                        }
                      />
                    }
                  </div>
                }
                <div
                  className="cursor-pointer "
                  onClick={() => {
                    setHide(false);
                    setCount(serving);
                    setFocused(false);
                    setSelectedMesurement(Measurement);
                  }}
                >
                  {
                    <Cancle
                      className={
                        '2xl:w-[15px] 2xl:h-[15px] xl:w-[13px] xl:h-[13px] lg:h-[10px] lg:w-[10px]'
                      }
                    />
                  }
                </div>
              </div>
            )}
          </div> */}

          <div className=" cursor-pointer">
            {
              <Delete
                onClick={() => {
                  setOverlay(true);
                  setHide(false);
                  let token = getToken();
                  let config = {
                    baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/remove_meal_recipe?meal_type_id=${meal_type_id}&recipe_id=${recipe_id}&plan_id=${planId}`,
                    method: 'DELETE',
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-type': 'application/json',
                    },
                  };
                  axios
                    .request(config)
                    .then((response) => {
                      // setCallApi(CallApi + 1);
                      const finalData = response.data.data[0];
                      setOverlay(false);
                      setUserData((prevUserData) =>
                        prevUserData.map((user) => {
                          if (user.meal_type_id === meal_type_id) {
                            const filteredRecipes = user.suggested_recipes.filter(
                              (recipe) => recipe.recipe_id !== recipe_id
                            );
                            return {
                              ...user,
                              nutrition_profile: finalData.nutrient_profile,
                              suggested_recipes: filteredRecipes,
                            };
                          }
                          return user;
                        })
                      );
                      setRefreshData((old) => !old);
                    })
                    .catch((err) => {
                      toast.error(err);
                      setOverlay(false);
                    });
                }}
              />
            }
          </div>
        </div>
        <RacipeForm
          isOpen={openRecipeModal}
          isclose={closeRecipeModal}
          postData={postData}
          ingredientData={ingredientData}
          nutritionData={nutritionData}
          recipeName={recipeName}
        />
      </div>
    </>
  );
};

export default CountSectionRow;

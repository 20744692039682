import React, { useRef, useState } from 'react';
import { ReactComponent as Add } from '../../assets/images/add.svg';
import DietPlaneTable from '../layout/DietPlaneTable';
import RacipeForm from '../layout/RacipeForm';
// import classNames from 'classnames';
import { ReactComponent as Edit } from '../../assets/images/darkedit.svg';
import Button from '../common/Button';
import classNames from 'classnames';
import axios from 'axios';
import { getToken } from '../../Utils/Authenticate';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserDetailsPanel from '../shared/UserDetailsPanel';
import { useDispatch } from 'react-redux';
import { setUser } from '../../Store/UserDataSlice';
import { Dummydata } from '../layout/Customedata';
import Skeleton from 'react-loading-skeleton';
import ImportTemplet from '../common/ImportTemplet';
import SaveTempletModal from '../layout/SaveTempletModal';
import SaveTemplet from '../common/SaveTemplet';
import ConsolutionGuideCopy from '../shared/ConsolutionTab';
import ExpertNotesModalCopy from '../shared/ExpertNotesTab';
import RDA from '../shared/RDA';
import ActivityLog from '../shared/ActivityLog';
import { ClipLoader, HashLoader } from 'react-spinners';
import VersionList from '../common/VersionList';
import VersionCreation from '../common/VersionCreation';
import moment from 'moment';

const DietPlan = () => {
  const [userData, setUserData] = useState([]);
  const [overlay, setOverlay] = useState(false);
  const [UserCooking, setUserCooking] = useState([]);
  const [UserPDF, setUserPDF] = useState();
  const [GuideLines, setGuideLines] = useState([]);
  const [DoctorDetails, setDoctorDetails] = useState([]);
  const [DietPlans, setDietPlans] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  const [CurrentTab, setCurrentTab] = useState('diet');

  const [copyFrom, setCopyFrom] = useState(0);
  const [planDetails, setPlanDetails] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  let [userName, setUserName] = useState('-1');
  const [refreshdata, setRefreshData] = useState(false);
  const [CallApi, setCallApi] = useState(0);
  const [tableHeadingHeight, setTableHeadingHeight] = useState(0);
  const [Version, setVersion] = useState('');
  const [VersionTrigger, setVersionTrigger] = useState();
  const [EnableCreation, setEnableCreation] = useState(false);
  const [SelectedVersion, setSelectedVersion] = useState('');
  const [Versions, setVersions] = useState([]);
  const [ChangeInDetails, setChangeInDetails] = useState(false);
  const headerRef = useRef(null);
  const tableRef = useRef(null);
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);

  const verification = permission?.role_permissions?.find(
    (permission) => permission.name === 'Verification Panel'
  );

  const planHeading = useRef(null);
  let token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const openedJson = localStorage.getItem('last-opened');
  const opened = openedJson ? JSON.parse(openedJson) : openedJson;

  const getVersion = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v2/version?user_id=${userId}&type=week`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const data = response.data;
        setVersions(data.data);
        if (!opened || opened === 'undefined') {
          setSelectedVersion(data.data[0]);
          data.data[0] && localStorage.setItem('last-opened', JSON.stringify(data.data[0]));
          setVersion(
            data.data && data.data.length > 0
              ? data.data[0].id === 0
                ? '0'
                : data.data[0].id
              : '0'
          );
        } else {
          setSelectedVersion(opened);
          setVersion(opened ? (opened.id === 0 ? '0' : opened.id) : '0');
        }
      })
      .catch((error) => {
        const err = error.response;
        console.error(err);
      });
  };

  useEffect(() => {
    getVersion();
  }, [VersionTrigger]);

  let { userId } = useParams();
  useEffect(() => {
    if (Version && userId) {
      setLoading(true);
      const params = userId.split('/');
      const _userId = params[params.length - 1];
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/plan_details?user_id=${_userId}&version_id=${Version}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then(({ data }) => {
          setLoading(false);
          let diet_plans = data.data[0];
          setUserName(diet_plans.user.name.split(' ')[0]);
          dispatch(setUser(diet_plans.user));
          setUserData(diet_plans.user);
          setPlanDetails(diet_plans.diet_plans);
          setDietPlans([]);
        })
        .catch((err) => {
          setLoading(false);
          toast(err);
        });
    }
  }, [userId, refreshdata, CallApi, Version]);

  const cookingMethod = () => {
    const fetchIds = [
      ...new Set(
        planDetails?.flatMap((data) =>
          data.is_approved === true
            ? data.sections.flatMap((section) => section.recipes.map((recipe) => recipe.recipe_id))
            : []
        )
      ),
    ].join(',');

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const bodyForFetch = {
      recipe_ids: fetchIds,
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/cooking_methods`, {
        headers: headers,
        params: bodyForFetch,
      })
      .then((response) => {
        const res = response.data;
        if (res.result === 'success') setUserCooking(res.data);
      });
  };

  const pdfGetMethod = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/plan_pdf/${userId}?version_id=${Version}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const res = response.data;
        setUserPDF(res.data[0].url);
      })
      .catch((error) => {
        const response = error.response;
        console.error(response.data.message);
        if (response.data.message === 'PDF not found') setUserPDF();
      });
  };

  const vesrionStatus = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v2/enable_create_version?user_id=${userId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const res = response.data.data;
        setEnableCreation(res[0].is_create_version);
      })
      .catch((error) => {
        const res = error.data;
      });
  };

  useEffect(() => {
    if (Version) {
      pdfGetMethod();
    }
  }, [Version]);

  const fetchGuideLines = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/guideline?user_id=${userId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setGuideLines(response.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/nutritionist_details?user_id=${userId}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const details = response.data;
        setDoctorDetails(details.data);
      })
      .catch((error) => {
        const details = error.response;
      });
  };

  const approvedData = planDetails?.filter((data) => data.is_approved === true);

  // useEffect(() => {
  //   if (approvedData && approvedData?.length !== 0) {
  //     console.log('hello', approvedData);
  //     cookingMethod();
  //   }
  // }, []);

  useEffect(() => {
    fetchGuideLines();
    vesrionStatus();
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleResize = () => {
    let planHeadingHeight;
    let titleHeaderHeight;
    if (planHeading.current) {
      planHeadingHeight = planHeading.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty('--plan-table-head-height', planHeadingHeight);
    if (headerRef.current) {
      titleHeaderHeight = headerRef.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty('--diet-head-height', titleHeaderHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getFormattedDate = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const today = new Date();
    const year = today.getFullYear();
    const month = months[today.getMonth()];
    const day = String(today.getDate()).padStart(2, '0');
    return `${day} ${month} ${year}`;
  };

  const pattern = new RegExp('\\n\\s*', 'g');
  const pat2 = new RegExp('"', 'g');

  const formattedDate = getFormattedDate();

  const content = `
  <html>
    <head>
      <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
      <title>Diet Plan PDF</title>
      <style type="text/css">
      * {
        font-family: "Trebuchet MS", sans-serif;
      }
      p {
        margin: 0px;
      }
      .head {
        font-weight: 700;
        font-size: 30px;
      }
      .head-flex {
        display: flex;
        justify-content: space-between;
      }
      .symbol {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .message {
        margin: 5px 0px 30px;
      }
      .bor {
        border: 1px solid #cacaca;
      }
      .user-details {
        font-weight: 400;
        display: flex;
        flex-wrap: wrap;
      }
      .space {
        margin-right: 10px;
        font-size: 12px;
      }
      .user {
        padding: 15px 0px;
        border-bottom: 1px solid #cacaca;
      }
      .user-date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .meals {
        width: 100%;
      }
      .day {
        display: flex;
        flex-direction: column;
        width: 33.3333333333%;
        border: 0.5px solid #eaeaea;
        border-radius: 5px;
        padding: 10px;
      }
      .time {
        width: 25%;
        color: #666666;
      }
      .recipe {
        width: 75%;
      }
      .what-to-consume {
        margin: 10px 10px;
        color: #666666;
      }
      .routine {
        font-weight: 700;
      }
      .quantity {
        font-weight: 700;
      }
      .one-time {
        display: flex;
        font-size: 12px;
        margin-bottom: 20px;
      }
      .day-number {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 700;
      }
      .recipe-name {
        margin-bottom: 10px;
        font-size: 16px;
      }
      .recipe-container {
        page-break-inside: avoid;
      }
      .main-content-header {
        padding: 20px 0px 15px;
        font-weight: 700;
        font-size: 20px;
      }
      .meal-container {
        page-break-after: always;
        display: flex;
      }
      .share-button {
        padding: 10px 20px;
        background-color: #8d6f24;
        color: #ffff;
        border-radius: 10px;
        border: transparent;
        cursor: pointer;
      }
      .disable-button {
        cursor: not-allowed;
      }
    </style>
    </head>
    <body>
      <div>
        <div class='head-flex'>
          <div>
            <div class='head'>${userData?.name}</div>
            <div class='message'>Diet Plan</div>
          </div>
          <div class='symbol'>
            <img
                height="40px"
                src='https://i.ibb.co/109VJht/logo.png'
              />
          </div>
        </div>
        <div class='bor'></div>
        <div class='user'>
          <div class='user-date'>
            <div></div>
            <div>Date: ${formattedDate}</div>
          </div>
          <div class='user-details'>
            <span class='space'>Age: ${userData?.age},</span>
            <span class='space'>Gender: ${userData?.gender},</span>
            <span class='space'>Weight: ${userData?.weight},</span>
            <span class='space'>Height: ${userData?.height},</span>
            <span class='space'>BMI: ${userData?.bmi?.value} ${userData?.bmi?.verdict},</span>
            <span class='space'>Medical Condition: ${
              userData?.medical_condition?.answer_data
            },</span>
            <span class='space'>Allergies: ${userData?.allergies?.answer_data}</span>
          </div>
        </div>
        <div class='user'>
          ${DoctorDetails.map(
            (details) => `
            <div>
            <div class='user-date'>
              <div>Nutritionist- ${details.name}</div>
            </div>
            <div class='user-details'>
              <span class='space'>Phone: ${details.phone},</span>
              <span class='space'>Email: ${details.email}</span>
            </div>
            </div>
          `
          )}
        </div>
        <div style='padding: 20px 0px 15px; font-weight: 700; font-size: 20px'>
          Diet Plans
        </div>
        <div class='meals'>
          ${approvedData
            ?.map(
              (dayData, index) => `
              ${index % 3 === 0 ? `<div style='page-break-after: always; display: flex'>` : ''}
            <div class='day'>
              <div class='day-number'>${dayData.label}</div>
              <div>
              ${dayData.sections
                ?.map(
                  (data) => `
              <div class='one-time'>
                <div class='time'>${data.meal_time}</div>
                <div class='recipe'>
                  <div class='routine'>${data.meal}</div>
                  ${data.recipes
                    ?.map(
                      (meal) => `
                  <div class='what-to-consume'>
                    <span class='quantity'>${meal.recipe_name}</span> - <span>${meal.serving} ${meal.measurement}</span>
                  </div>`
                    )
                    .join('')}
                </div>
              </div>
              `
                )
                .join('')}
              </div>
            </div>
            ${index % 3 === 2 || index === approvedData?.length - 1 ? '</div>' : ''}
            `
            )
            .join('')}
        </div>
      ${
        GuideLines && GuideLines.length > 0
          ? '<div class="main-content-header">Consulation Guideline</div>'
          : ''
      }
      ${
        GuideLines && GuideLines.length > 0
          ? `<div style="page-break-after: always">
      <div class="recipe-container">
      ${GuideLines.map(
        (data, index) => `
        <div class="recipe-container">
          <div style='display: flex;'>
            <div style='display: flex; ' class="recipe-name">${index + 1}. <div>${
          data.guideline
        }</div></div>
          </div>
        </div>`
      ).join('')}
        </div>
      </div>`
          : ''
      }
      </div>
    </body>  
  </html> 
  `;

  const sharePDF = () => {
    const pdfContentString = content.replace(pattern, ' ').replace(pat2, "'");
    const todo = {
      user_id: userId,
      version_id: Version,
      htmlCode: pdfContentString,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/plan_pdf`, todo, {
        headers: headers,
      })
      .then((response) => {
        pdfGetMethod();
        getVersion();
      })
      .catch((error) => {});
  };

  const [Called, setCalled] = useState(false);

  const uploadHtmlForPdf = () => {
    const newWindow = window.open('', '_blank', 'width=595,height=842');
    newWindow.document.write(content);
    newWindow.document.close();
    setOverlay(false);
    setCalled(false);
    sharePDF();
  };

  useEffect(() => {
    if (Called === true) uploadHtmlForPdf();
  }, [Called]);

  useEffect(() => {
    if (ChangeInDetails === true) {
      const verifiedVersion = Versions.find((version) => version.is_verified);
      setSelectedVersion(verifiedVersion || null);
      setVersion(verifiedVersion ? (verifiedVersion.id === 0 ? '0' : verifiedVersion.id) : '0');
      setChangeInDetails(false);
      setOverlay(true);
      setTimeout(() => {
        setCalled(true);
      }, 4000);
      setCallApi(CallApi + 1);
    }
  }, [ChangeInDetails]);

  return (
    <>
      {overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]">
            Generating Diet Plan PDF and Approving..
          </div>
        </div>
      )}
      <UserDetailsPanel
        name={'Diet Plan'}
        secondHeading={'Verification Panel'}
        thirdHeading={'Diet Plan'}
        userData={userData}
        setUserData={setUserData}
        setCallApi={setCallApi}
        CallApi={CallApi}
        verification={verification}
        setChangeInDetails={setChangeInDetails}
      />
      <div className="flex items-center bg-white 2xl:text-base xl:text-sm lg:text-[11.85px] font-Regular rounded-[10px] h-[41px] mb-[10px]">
        <div
          onClick={() => setCurrentTab('diet')}
          className={`cursor-pointer w-[160px] ml-[10px] h-[41px] flex justify-center items-center ${
            CurrentTab === 'diet' ? 'border-b-[3px] border-[#D2A62C] font-bold' : ''
          }`}
        >
          Diet Plan
        </div>
        <div
          onClick={() => setCurrentTab('expert')}
          className={`cursor-pointer w-[160px] h-[41px] flex justify-center items-center ${
            CurrentTab === 'expert' ? 'border-b-[3px] border-[#D2A62C] font-bold' : ''
          }`}
        >
          Expert{`'`}s Notes
        </div>
        <div
          onClick={() => setCurrentTab('consulation')}
          className={`cursor-pointer w-[245px] h-[41px] flex justify-center items-center ${
            CurrentTab === 'consulation' ? 'border-b-[3px] border-[#D2A62C] font-bold' : ''
          }`}
        >
          Consultation Guidelines
        </div>
        <div
          onClick={() => setCurrentTab('rda')}
          className={`cursor-pointer w-[210px] h-[41px] flex justify-center items-center ${
            CurrentTab === 'rda' ? 'border-b-[3px] border-[#D2A62C] font-bold' : ''
          }`}
        >
          Suggested RDA
        </div>
        <div
          onClick={() => setCurrentTab('log')}
          className={`cursor-pointer w-[150px] h-[41px] flex justify-center items-center ${
            CurrentTab === 'log' ? 'border-b-[3px] border-[#D2A62C] font-bold' : ''
          }`}
        >
          Activity Log
        </div>
      </div>
      {CurrentTab === 'diet' && (
        <div className="bg-white rounded-medium shadow-grid-col">
          <div>
            <div
              ref={headerRef}
              className="flex items-center justify-between 2xl:py-[10px] xl:py-[17px] lg:py-[14px] lg:px-4  md:px-3 "
            >
              <div className="flex items-center gap-5 ">
                <div className="border-b text-2xl font-normal  ">
                  {userName === '-1' ? (
                    <div style={{ width: '150px', border: 'none' }}>
                      <Skeleton width="150px" baseColor="#e9d39633"></Skeleton>
                    </div>
                  ) : (
                    <div className="flex">
                      <div className="flex items-end">{userName}'s Diet Plan</div>
                      <VersionList
                        setSelectedVersion={setSelectedVersion}
                        SelectedVersion={SelectedVersion}
                        Versions={Versions}
                        setVersion={setVersion}
                        VersionTrigger={VersionTrigger}
                      />
                    </div>
                  )}
                </div>
                {/* <div className="cursor-pointer">
                <Edit />
              </div> */}
              </div>
              {verification?.can_create && (
                <div className="flex items-center gap-2">
                  <div>
                    <ImportTemplet
                      Version={Version}
                      setRefreshData={setRefreshData}
                      getVersion={getVersion}
                    />
                  </div>
                  <div
                    onClick={() => openModal()}
                    className="cursor-pointer text-base text-shades-2"
                  >
                    <SaveTemplet
                      isOpen={isOpen}
                      closeModal={closeModal}
                      Version={Version}
                      planDetails={planDetails}
                    />
                  </div>
                  {UserPDF && (
                    <div
                      onClick={() => {
                        window.open(UserPDF, '_blank');
                      }}
                      className="inline-flex justify-center rounded-md text-shades-2 text-SmallerBodyDisplayText font-Regular mr-[10px]"
                    >
                      <button>Print</button>
                    </div>
                  )}
                  {EnableCreation && (
                    <div className="cursor-pointer text-base text-shades-2">
                      <VersionCreation
                        Versions={Versions}
                        Version={Version}
                        planDetails={approvedData}
                        setVersionTrigger={setVersionTrigger}
                        VersionTrigger={VersionTrigger}
                        setType="week"
                      />
                    </div>
                  )}
                  <div ref={planHeading} className="flex lg:gap-[15px] md:gap-[10px]  items-center">
                    <Button
                      className={classNames(
                        'bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]'
                      )}
                      onClick={() => {
                        const params = userId.split('/');
                        const _userId = params[params.length - 1];
                        let config = {
                          baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/add_new_days_plan`,
                          method: 'POST',
                          data: {
                            user_id: _userId,
                            version_id: Version,
                          },
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        };
                        axios
                          .request(config)
                          .then(({ data }) => {
                            // setRefreshData(!refreshdata);
                            setPlanDetails((prev) => [...prev, data.data[0]]);
                            getVersion();
                            setTimeout(() => {
                              // Scroll to the right when a new column is added
                              tableRef.current?.rows[0].cells[
                                tableRef.current.rows[0].cells.length - 1
                              ].scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest',
                                inline: 'end',
                              });
                            }, 200);

                            toast.success(data.message);
                          })
                          .catch((err) => {
                            toast.error(err);
                          });
                      }}
                    >
                      <span>
                        <Add className="lg:h-[16px] lg:w-[16px]" />
                      </span>
                      Add
                    </Button>
                    {DietPlans?.length !== 0 ? (
                      <Button
                        className={classNames(
                          'bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]'
                        )}
                        onClick={() => {
                          let token = getToken();
                          let config = {
                            baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/approve_plans`,
                            method: 'POST',
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                            data: {
                              plan_ids: DietPlans,
                            },
                          };

                          axios
                            .request(config)
                            .then(
                              ({ data }) => {
                                toast.success(data.message);
                                setCallApi(CallApi + 1);
                                setOverlay(true);
                                setTimeout(() => {
                                  setCalled(true);
                                }, 4000);
                                vesrionStatus();
                                getVersion();
                              },
                              (err) => {
                                const message = err.response.data.message;
                                // const formattedMessage = message.replace(/(\([^()]+\))/g, (match) => {
                                //   const innerContent = match.substring(1, match.length - 1);
                                //   const lines = innerContent.split(',').map((item) => item.trim());
                                //   return lines.join('<br>');
                                // });
                                // debugger;
                                // toast.error(<pre>{formattedMessage}</pre>);
                                const multilineToast = (message) => {
                                  // Extract the portion of the string within parentheses
                                  const match = message.match(/(.*?)\(([^)]+)\)(.*)/);
                                  if (!match) {
                                    toast.error(message);
                                    setCallApi(CallApi + 1);
                                    return;
                                  }
                                  // Split the contents of the parentheses by comma
                                  const lines = match[2].split(',').map((line, i) => (
                                    <div className="text-brand-color" key={i}>
                                      {line.trim()}
                                    </div>
                                  ));
                                  // Create the toast with the text before, inside and after the parentheses
                                  toast(
                                    <div className="text-sm">
                                      {match[1].trim()}
                                      {lines}
                                      {match[3].trim()}
                                    </div>,
                                    {
                                      // Other toast options if needed
                                    }
                                  );
                                  setCallApi(CallApi + 1);
                                };
                                multilineToast(message);
                              }
                            )
                            .catch((err) => {
                              toast.error(err);
                            });
                        }}
                      >
                        Approve
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`flex overflow-auto content-diet-plan1 bg-[#E9D396]/[0.1]`}>
            <DietPlaneTable
              verification={verification}
              setCopyFrom={setCopyFrom}
              copyFrom={copyFrom}
              ref={tableRef}
              loading={loading}
              setLoading={setLoading}
              planDetails={planDetails}
              userData={userData}
              setRefreshData={setRefreshData}
              setUserData={setPlanDetails}
              setDietPlans={setDietPlans}
              DietPlans={DietPlans}
              setCallApi={setCallApi}
              CallApi={CallApi}
              setTableHeadingHeight={setTableHeadingHeight}
            />
          </div>
        </div>
      )}
      {CurrentTab === 'expert' && (
        <div className="bg-white rounded-medium shadow-grid-col">
          <ExpertNotesModalCopy verification={verification} />
        </div>
      )}
      {CurrentTab === 'consulation' && (
        <div className="bg-white rounded-medium shadow-grid-col">
          <ConsolutionGuideCopy
            fetchGuideLines={fetchGuideLines}
            verification={verification}
            GuideLines={GuideLines}
          />
        </div>
      )}
      {CurrentTab === 'rda' && (
        <div className="bg-white rounded-medium shadow-grid-col">
          <RDA verification={verification} />
        </div>
      )}
      {CurrentTab === 'log' && (
        <div className="bg-white rounded-medium shadow-grid-col">
          <ActivityLog />
        </div>
      )}
    </>
  );
};

export default DietPlan;

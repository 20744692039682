import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import SortIcon from '../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProgressData,
  getprogressTracker,
  isLoading,
  setClientName,
  setPrgCurrentIndex,
} from '../../Store/ClientTableSlice';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { timeAgoInstr } from '../../Utils/utils';

export default function ProgressTrackerTable() {
  const [sorting, setSorting] = useState([]);
  const [userIdArray, setUserIdArray] = useState([]);

  const orgId = useSelector((state) => state.ClientTable.prgOrgId);
  const [indexbutton, setIndexButton] = useState([
    {
      id: 1,
    },
  ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const [currentIndex, setCurrentIndex] = React.useState(1);
  let updateSearch = useSelector((state) => state.ClientTable.progressTrackerSearch);
  let totalCount = useSelector((state) => state.ClientTable.dataCount);
  let final = useSelector((state) => state.ClientTable.finalCount);
  const progressTrackerData = useSelector(getprogressTracker);

  useEffect(() => {
    let PrgIds = new Array();
    for (let i = 0; i < progressTrackerData?.length; i++) {
      PrgIds.push(progressTrackerData[i].user_id);
    }
    setUserIdArray(PrgIds);
  }, [progressTrackerData]);

  useEffect(() => {
    dispatch(fetchProgressData());
  }, []);

  const columns = [
    columnHelper.accessor((row) => row.customer_id, {
      id: 'customer_id',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Client ID</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.user_name, {
      id: 'user_name',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize break-all">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span className="">Client Name</span>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.user_email, {
      id: 'user_email',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="lowercase break-all">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Email Address</span>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.user_phone, {
      id: 'user_phone',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="lowercase">{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Phone Number</span>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.org_name, {
      id: 'org_name',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span>{info.getValue()}</span>
        ),
      header: () => (
        <div>
          <span>Organization</span>
        </div>
      ),
    }),

    columnHelper.accessor((row) => row.last_updated, {
      id: 'last_updated',
      cell: (info) =>
        info.getValue() === -1 ? (
          <div style={{ width: '100%', border: 'none' }}>
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          </div>
        ) : (
          <span className="capitalize">{timeAgoInstr(info.getValue())}</span>
        ),
      header: () => (
        <div>
          <span>Last Updated</span>
        </div>
      ),
    }),
  ];

  const table = useReactTable({
    data: progressTrackerData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    setSorting([]);
  }, []);

  const headingRef = useRef(null);
  const handleResize = () => {
    let headerHeight = 0;
    if (headingRef.current) {
      headerHeight = headingRef.current.getBoundingClientRect().height;
      document.documentElement.style.setProperty('--table-heading-height', headerHeight);
    }
  };

  useEffect(() => {
    handleResize();
  }, [progressTrackerData]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function setPagination() {
    if (progressTrackerData?.length <= 0 || totalCount <= 0) {
      return;
    }
    let no_of_pages = Math.ceil(totalCount / 100);
    setIndexButton(
      Array(no_of_pages)
        .fill()
        .map((_, index) => ({ id: index + 1 }))
    );

    setCurrentIndex(1);
  }
  useEffect(setPagination, [orgId]);

  useEffect(() => {
    setCurrentIndex(1);
  }, [updateSearch]);

  useEffect(setPagination, [totalCount]);

  function getEntriesCount() {
    let remainder = totalCount % 50;

    if (remainder === 0 || currentIndex < indexbutton.length) {
      return progressTrackerData?.length * currentIndex;
    } else {
      if (currentIndex === indexbutton.length) {
        return (currentIndex - 1) * 50 + remainder;
      }
    }
  }

  useEffect(() => {
    dispatch(setPrgCurrentIndex(currentIndex));
    dispatch(fetchProgressData());
  }, [currentIndex]);

  return (
    <div className={`lg:mt-[5px] table-client`}>
      {progressTrackerData?.length > 0 ? (
        <>
          {isLoading ? (
            <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
          ) : (
            <>
              <div className="tableFixHead overflow-x-scroll">
                <table className="min-w-[-webkit-fill-available]  table-margin overflow-hidden">
                  <thead ref={headingRef} className="sticky top-0 items-center thead">
                    {table?.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className="overflow-hidden flex ">
                        {headerGroup.headers.map((header, index, array) => {
                          return (
                            <th
                              key={header.id}
                              className={`text-start  tracker_col-${index} bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl border-r px-5 last-of-type:border-r-0  py-[9px]  font-medium   2xl:text-lg xl:text-sm lg:text-xs  ${
                                header.id === 'Email Address' && ''
                              }`}
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <div className="flex items-center justify-between w-full">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header)}
                                {array?.length - 1 === index + 10
                                  ? null
                                  : {
                                      asc: (
                                        <img
                                          src={SortIcon}
                                          // height={20}
                                          className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                          style={{ transform: 'rotate(180deg)' }}
                                          alt="SortIcon"
                                        />
                                      ),
                                      desc: (
                                        <img
                                          src={SortIcon}
                                          className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                          alt="SortIcon"
                                        />
                                      ),
                                      false: (
                                        <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                      ),
                                    }[header.column.getIsSorted()]}
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="duration-1000 tbody">
                    {table.getRowModel().rows.map((row, ind) => (
                      <tr
                        key={row.id}
                        className=" clienttable_row even:bg-secondary-3/20 mb-1 flex w-full"
                        onClick={() => {
                          let dataToBeClicked = row.original;
                          dispatch(setClientName(dataToBeClicked.user_name));
                          navigate(`/progressAnalysis/${dataToBeClicked.user_id}`);
                        }}
                      >
                        {row.getVisibleCells().map((cell, ind) => (
                          <td
                            key={cell.id}
                            className={`duration-1000 cursor-pointer tracker_col-${ind} text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs  `}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex items-center justify-between md:py-3 text-secondary-1">
                <div className="flex">
                  <span className="text-body-color-1 font-Regular 2xl:text-base lg:text-xs">
                    Showing {final} of {totalCount} entries
                  </span>
                </div>
                <div className="flex items-center ">
                  <button
                    disabled={currentIndex === 1}
                    className={`${
                      currentIndex !== 1 ? ' hover:bg-shades-2  hover:text-white ' : 'diabledBtnBg '
                    }duration-200 buttons border first-of-type:rounded-s-[10px]   2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                    onClick={() => {
                      if (currentIndex > 1) {
                        setCurrentIndex(currentIndex - 1);
                      }
                    }}
                  >
                    Previous
                  </button>

                  <div className="flex">
                    {indexbutton.map((value, id) => {
                      return (
                        <>
                          <button
                            key={id}
                            className={`${
                              currentIndex === value.id
                                ? ' bg-shades-2 text-white duration-500 '
                                : 'hover:bg-gray-100 duration-300'
                            }  duration-200    buttons border-t border-b  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                            onClick={() => setCurrentIndex(value.id)}
                          >
                            <div className="flex justify-center items-center 2xl:w-[16px] 2xl:h-[24px] xl:w-[19.18px] xl:h-[18px] lg:w-[16px] lg:h-[18px]">
                              {value.id}
                            </div>
                          </button>
                        </>
                      );
                    })}
                  </div>

                  <button
                    disabled={currentIndex === indexbutton.length}
                    className={`${
                      currentIndex !== indexbutton.length
                        ? ' hover:bg-shades-2  hover:text-white '
                        : 'diabledBtnBg'
                    } duration-200    border nxtbutton  last-of-type:rounded-e-[10px]  2xl:text-base xl:text-[14.38px] lg:text-xs 2xl:p-[10px] xl:p-[9.59px] lg:p-[8px]`}
                    onClick={() => {
                      if (currentIndex < indexbutton.length) setCurrentIndex(currentIndex + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="w-full h-full">
          <ClientError className="w-full h-full" />
        </div>
      )}
    </div>
  );
}

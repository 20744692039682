import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import MealInputDropdown from '../shared/MealInputDropdown';
import TimeInput from '../shared/TimeInput';
import { convertTime } from '../../Utils/utils';
import { toast } from 'react-toastify';

export default function UpdateModal(props) {
  const {
    isOpen,
    closeModal,
    query,
    setQuery,
    selected,
    setSelected,
    inputTimeVal,
    setInputTimeVal,
    data,
    inputMealVal,
    editMealTime,
    setShow,
    mealtype,
    setInputMealVal,
    existingMeals,
  } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[350px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="">
                  <div className="text-center mb-8 font-bold text-xl">Select Meal Type</div>
                  <div className="">
                    <MealInputDropdown
                      className={'w-full '}
                      query={query}
                      setQuery={setQuery}
                      mealtype={mealtype}
                      setInputMealVal={setInputMealVal}
                      inputMealVal={inputMealVal}
                      selected={selected}
                      setSelected={setSelected}
                      data={data}
                    />
                    <TimeInput
                      setInputTimeVal={setInputTimeVal}
                      value={convertTime(inputTimeVal)}
                    />
                  </div>

                  <div className="">
                    <button
                      className="mt-10 cursor-pointer
                  bg-brand-color text-white flex 2xl:px-10 2xl:py-2  lg:px-[30px] md:px-[25px] lg:py-[6px] md:py-[4px] 2xl:rounded-[10px]  lg:rounded-lg md:rounded-md text-base font-Regular justify-center w-full"
                      onClick={() => {
                        if (!inputMealVal) {
                          toast.error('Please Select Valid Meal Name');
                          return;
                        }
                        if (!isOpen) {
                          setShow(false);
                        } else {
                          // let meal = selected?.meal_name;
                          // let time = selected?.meal_time;
                          // for (let { meal_type, meal_time } of existingMeals) {
                          //   if (meal && meal_type) {
                          //     if (
                          //       meal_type?.toLowerCase() === meal?.toLowerCase() &&
                          //       meal_time?.toLowerCase() === time?.toLowerCase()
                          //     ) {
                          //       toast.error('Meal Already Exists');

                          //       // console.log(selected, 'selected');
                          //       return;
                          //     }
                          //   }
                          // }
                          editMealTime();
                        }
                      }}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

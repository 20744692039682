import React, { useEffect, useState, useRef } from 'react';
import _ from 'underscore';
import IngredientsPanel from '../layout/Nutrition/Ingredients/IngredientsPanel';
import RecipePanel from '../layout/Nutrition/Recipe/RecipePanel';
import Referal from '../layout/DashBoard/Referal';
import Payment from '../layout/DashBoard/Payment';
import PagesHeader from '../shared/PagesHeader';
import { ReactComponent as Nutrition } from '../../assets/images/nutrition.svg';
import { ReactComponent as Recipe } from '../../assets/images/recipe.svg';
import { useParams } from 'react-router-dom';
import { getToken } from '../../Utils/Authenticate';
import { ReactComponent as ArrayBack } from '../../assets/images/arrow_back.svg';
import axios from 'axios';

export default function NutritionDashboard({ nutritionIngredient, nutritionRecipe }) {
  const [CurrentTab, setCurrentTab] = useState('ingredients_panel');
  let { userId } = useParams();
  const [tabs, settabs] = useState(
    [
      nutritionIngredient?.can_view
        ? {
            key: 'ingredients_panel',
            label: 'Ingredients Panel',
            icon: <Nutrition className="mr-[15px]" />,
          }
        : null,
      // nutritionRecipe?.can_view
      //   ? { key: 'recipe_panel', label: 'Recipe Panel', icon: <Recipe className="mr-[15px]" /> }
      //   : null,
    ].filter((item) => item !== null)
  );
  const dashboardRef = useRef(null);

  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const tabComponents = {
    ingredients_panel: (
      <IngredientsPanel
        headers={headers}
        userId={userId}
        nutritionIngredient={nutritionIngredient}
      />
    ),
    recipe_panel: <RecipePanel headers={headers} userId={userId} />,
  };

  useEffect(() => {
    setCurrentTab(
      nutritionIngredient?.can_view
        ? 'ingredients_panel'
        : nutritionRecipe?.can_view
        ? 'recipe_panel'
        : ''
    );
  }, [nutritionIngredient, nutritionRecipe]);

  return (
    <div>
      <div className="flex py-[20px] w-full">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`font-normal flex px-[20px] cursor-pointer border-b border-b-[#D6A725] ${
              CurrentTab === tab.key ? 'text-[#D2A72B] border-b-[5px]' : ''
            }`}
            onClick={() => {
              setCurrentTab(tab.key);
            }}
          >
            {tab.icon}
            {tab.label}
          </div>
        ))}
        <div className="flex flex-grow border-b-[#D2A72B] border-b"></div>
      </div>
      <div
        className={`${CurrentTab === 'ingredients_panel' ? '' : 'dashboard_height'}`}
        ref={dashboardRef}
      >
        {tabComponents[CurrentTab]}
      </div>
    </div>
  );
}

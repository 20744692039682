import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import { ReactComponent as Expand } from '../../assets/images/expand_more_dark.svg';
import { Listbox, Combobox, Transition } from '@headlessui/react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';

// const TemplateDatas = [
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
// ];

const TempletDisclose = ({
  className,
  data,
  selected,
  setSelected,
  query,
  setQuery,
  type,
  setTemplates,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (setTemplates) {
      setTemplates([]);
    }
  }, [open]);
  return (
    <>
      <div className="border-b">
        <div className="2xl:w-[200px] lg:w-40 md:w-36 wid-170px">
          <Combobox
            value={selected}
            onChange={(data) => {
              setSelected(data);
              setOpen(!open);
            }}
          >
            {/*  */}
            <div className="relative mt-1">
              <div
                className={`relative w-full cursor-default rounded bg-white  lg:pt-[5px] md:pt-[3px]  lg:px-[1px] md:px-[6px] text-left   font-Regular ${className}`}
              >
                <Combobox.Input
                  className={`w-full border-none 2xl:py-3 2xl:pl-[10px]  lg:py-[6px] md:py-[4px]  lg:pl-[8px] md:pl-[6px]  lg:pr-[32px] md:pr[28px]  text-gray-900 focus:outline-none ${className}`}
                  displayValue={(data) => data.name}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder={'Import Template'}
                />
                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex justify-between items-center truncate">
                    <div>
                      {/* {open ? (
                        <Expand className=" transform rotate-180 lg:w-3 lg:h-3 md:w-1 md:h-1" />
                      ) : (
                        )} */}
                      {type === 'plan' ? (
                        <Expand
                          className={`  lg:w-3 lg:h-3 md:w-1 md:h-1 ui-open:transform ui-open:rotate-180`}
                        />
                      ) : (
                        <UpArray
                          className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
                        />
                      )}
                    </div>
                  </div>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <div className=" text-gray-400" aria-hidden="true" />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options
                  className={`${
                    data.length > 0 || query.length > 1 ? 'border' : ''
                  }  absolute mt-1 max-h-52 w-full overflow-auto  border-[#E6E6E6] shadow-[4px 4px 8px 2px rgba(0, 0, 0, 0.12)] rounded-lg bg-white z-50  2xl:text-xs 2xl:leading-[18px] xl:text-sm lg:text-xs md:text-[10px] font-Regular`}
                >
                  {data.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2  px-2 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    data.map((person, id) => (
                      <Combobox.Option
                        key={id}
                        className={({ active }) =>
                          `relative cursor-default select-none 2xl:px-5 2xl:py-[10px]   lg:py-[8px] lg:px-2 md:py-[6px] md:px-3 border-b ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={person}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {person.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }'
                                  }`}
                              >
                                <div className=" lg:h-4 lg:w-4 md:h-3 md:w-3" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </>
  );
};

export default TempletDisclose;

// import { Fragment, useState } from 'react'
// import { Combobox, Transition } from '@headlessui/react'

// const people = [
//   { id: 1, name: 'Wade Cooper' },
//   { id: 2, name: 'Arlene Mccoy' },
//   { id: 3, name: 'Devon Webb' },
//   { id: 4, name: 'Tom Cook' },
//   { id: 5, name: 'Tanya Fox' },
//   { id: 6, name: 'Hellen Schmidt' },
// ]

// export default function Example() {
//   const [query, setQuery] = useState('')

//   const filteredPeople =
//     query === ''
//       ? people
//       : people.filter((person) =>
//           person.name
//             .toLowerCase()
//             .replace(/\s+/g, '')
//             .includes(query.toLowerCase().replace(/\s+/g, ''))
//         )

//   return (
// <div className="w-72">
//   <Combobox value={selected} onChange={setSelected}>
//     <div className="relative mt-1">
//       <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
//         <Combobox.Input
//           className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
//           displayValue={(person) => person.name}
//           onChange={(event) => setQuery(event.target.value)}
//         />
//         <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
//           <div
//             className="h-5 w-5 text-gray-400"
//             aria-hidden="true"
//           />
//         </Combobox.Button>
//       </div>
//       <Transition
//         as={Fragment}
//         leave="transition ease-in duration-100"
//         leaveFrom="opacity-100"
//         leaveTo="opacity-0"
//         afterLeave={() => setQuery('')}
//       >
//         <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
//           {filteredPeople.length === 0 && query !== '' ? (
//             <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
//               Nothing found.
//             </div>
//           ) : (
//             filteredPeople.map((person) => (
//               <Combobox.Option
//                 key={person.id}
//                 className={({ active }) =>
//                   `relative cursor-default select-none py-2 pl-10 pr-4 ${
//                     active ? 'bg-teal-600 text-white' : 'text-gray-900'
//                   }`
//                 }
//                 value={person}
//               >
//                 {({ selected, active }) => (
//                   <>
//                     <span
//                       className={`block truncate ${
//                         selected ? 'font-medium' : 'font-normal'
//                       }`}
//                     >
//                       {person.name}
//                     </span>
//                     {selected ? (
//                       <span
//                         className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
//                           active ? 'text-white' : 'text-teal-600'
//                         }`}
//                       >
//                         <div className="h-5 w-5" aria-hidden="true" />
//                       </span>
//                     ) : null}
//                   </>
//                 )}
//               </Combobox.Option>
//             ))
//           )}
//         </Combobox.Options>
//       </Transition>
//     </div>
//   </Combobox>
// </div>
//   )
// }

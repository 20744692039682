import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getToken } from '../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ProgressAnalysis = createAsyncThunk(
  'progressAnalysis/fetch',
  async (data, { getState }) => {
    try {
      const token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/analytics?user_id=${
          getState().ProgressAnalysis.userId
        }&month_id=${getState().ProgressAnalysis.monthId}&year=${getState().ProgressAnalysis.year}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(config);
      return response.data.data[0];
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const getTrackersDropDownData = createAsyncThunk(
  'getTrackersDropDownData',
  async (data, { getState }) => {
    try {
      const token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker?search=`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(config);
      return response.data?.data;
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  }
);

export const updateTrackerValue = createAsyncThunk(
  'update',
  async (callBackOnSuccess, { getState, dispatch }) => {
    try {
      const token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/edit_manual/${
          getState().ProgressAnalysis.colId
        }`,
        method: 'patch',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          value: getState().ProgressAnalysis.editValue,
        },
      };
      const response = await axios.request(config);
      toast.success(response.data.message);

      dispatch(ProgressAnalysis());
      callBackOnSuccess();
      return response.data?.data;
    } catch (error) {
      toast.error(error.response.data.message);
      getState().ProgressAnalysis.selectedDate = '';
      getState().ProgressAnalysis.selectedParameter = { id: -1, name: '' };
      throw error;
    }
  }
);

export const addNew = createAsyncThunk(
  'addNew',
  async (callBackOnSuccess, { getState, dispatch }) => {
    try {
      const token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/add_manual`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          user_id: getState().ProgressAnalysis.userId,
          progress_tracker_id:
            getState().ProgressAnalysis.selectedParameter.id === -1
              ? ''
              : getState().ProgressAnalysis.selectedParameter.id,
          date: getState().ProgressAnalysis.selectedDate.replace(/\//g, '-'),
          value: getState().ProgressAnalysis.addValue,
        },
      };
      const response = await axios.request(config);
      const addManualSuccessResponse = 'Tracker value is added successfully';
      toast.success(addManualSuccessResponse);
      dispatch(ProgressAnalysis());
      callBackOnSuccess();
      return response.data?.data;
    } catch (error) {
      toast.error(error.response.data.message);
      getState().ProgressAnalysis.selectedDate = '';
      getState().ProgressAnalysis.selectedParameter = { id: -1, name: '' };

      throw error;
    }
  }
);

export const ProgressAnalysisSlice = createSlice({
  name: 'ProgressAnalysis',
  initialState: {
    isLoading: false,
    data: { columns: [], rows: [] },
    isError: false,
    userId: '',
    monthId: '',
    year: '',
    trackersDropdownData: [],
    editValue: '',
    addValue: '',
    selectedParameter: { id: -1, name: '' },
    colId: '-1',
    clickedIdxs: { row: -1, col: -1 },
    selectedDate: '',
    selectedDate: '',
    SelectedValue: '',
    selectedColumn: '',
  },
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setMonthId: (state, action) => {
      state.monthId = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setEditValue: (state, action) => {
      state.editValue = action.payload;
    },
    setAddValue: (state, action) => {
      state.addValue = action.payload;
    },
    setSelectedParameter: (state, action) => {
      state.selectedParameter = action.payload;
    },
    setColId: (state, action) => {
      state.colId = action.payload;
    },
    setClickedIndex: (state, action) => {
      state.clickedIdxs = action.payload;
    },
    SelectedValueHeading: (state, action) => {
      state.SelectedValue = action.payload;
    },
    setClickedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setSelectedColumn: (state, action) => {
      state.selectedColumn = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ProgressAnalysis.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ProgressAnalysis.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(ProgressAnalysis.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getTrackersDropDownData.fulfilled, (state, action) => {
      state.trackersDropdownData = action.payload;
    });
    builder.addCase(updateTrackerValue.fulfilled, (state, action) => {
      let key = state.selectedColumn;
      let val = action.payload[0].value;
      state.selectedDate = '';
      state.selectedParameter = { id: -1, name: '' };
    });
    builder.addCase(addNew.fulfilled, (state, action) => {
      state.selectedDate = '';
      state.selectedParameter = { id: -1, name: '' };
    });
  },
});

export const {
  setUserId,
  setMonthId,
  setYear,
  setAddValue,
  setEditValue,
  setSelectedParameter,
  setColId,
  setClickedIndex,
  setClickedDate,
  setSelectedDate,
  SelectedValueHeading,
  setSelectedColumn,
} = ProgressAnalysisSlice.actions;

export const PRGAnalysis = (state) => {
  return state.ProgressAnalysis.data[0].rows;
};

export default ProgressAnalysisSlice.reducer;

import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

// const ImportTemplet = ({ setImportData, importData, setRefreshData }) => {
//   let { userId } = useParams();
//   const [selectedValue, setSelectedValue] = useState('');
//   const handleSelect = (value) => {
//     let token = getToken();
//     setSelectedValue(value);
//     let config = {
//       baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/diet_plan_set/import`,
//       method: 'post',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       data: {
//         user_id: userId,
//         template_id: value.id
//       },
//     };
//     axios
//       .request(config)
//       .then((data) => {
//         toast.success(data.data.message);
//         setRefreshData(prev => !prev)
//       }, (err) => {
//         toast.error(err.response.data.message);
//       })
//       .catch((err) => {
//         toast.error(err);
//       });
//   };

//   useEffect(() => {
//     let token = getToken();
//     let config = {
//       baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/diet_plan_set`,
//       method: 'get',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     };
//     axios
//       .request(config)
//       .then(
//         (data) => {
//           setImportData(data.data.data);
//         },
//         (err) => {
//           toast.error(err);
//         }
//       )
//       .catch((err) => {
//         toast.error(err);
//       });
//   }, []);
//   return (
//     <div>
//       <div className="w-56 text-right">
//         <Menu
//           as="div"
//           className="relative w-full border-b-2 cursor-default rounded bg-white  lg:pt-[5px] md:pt-[3px] lg:px-[1px] md:px-[6px] text-left   font-Regular"
//         >
//           <div>
//             <Menu.Button className="w-full border-none 2xl:py-3 2xl:pl-[10px]  lg:py-[6px] md:py-[4px]  lg:pl-[8px] md:pl-[6px]  lg:pr-[32px] md:pr[28px]  text-body-color-2 focus:outline-none">
//               <div className="flex justify-between items-center">
//                 <div className="truncate">{selectedValue ? selectedValue.name : 'Import Template'}</div>
//                 <div>
//                   <UpArray className="ui-not-open:transform ui-not-open:rotate-180" />
//                 </div>
//               </div>
//             </Menu.Button>
//           </div>
//           <Transition
//             as={Fragment}
//             enter="transition ease-out duration-100"
//             enterFrom="transform opacity-0 scale-95"
//             enterTo="transform opacity-100 scale-100"
//             leave="transition ease-in duration-75"
//             leaveFrom="transform opacity-100 scale-100"
//             leaveTo="transform opacity-0 scale-95"
//           >
//             <Menu.Items className="absolute  z-50 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//               <div className="">
//                 {importData?.map((val, ind) => {
//                   return (
//                     <>
//                       <Menu.Item key={ind}>
//                         {({ active }) => (
//                           <h1
//                             className={`${active ? 'bg-shades-2 text-white' : 'text-gray-900'
//                               } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
//                             onClick={() => handleSelect(val)}
//                           >
//                             {val.name}
//                           </h1>
//                         )}
//                       </Menu.Item>
//                     </>
//                   );
//                 })}
//               </div>
//             </Menu.Items>
//           </Transition>
//         </Menu>
//       </div>
//     </div>
//   );
// };

// export default ImportTemplet;

import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import { ReactComponent as Expand } from '../../assets/images/expand_more_dark.svg';
import { Listbox, Combobox, Transition } from '@headlessui/react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';

// const TemplateDatas = [
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
//   {
//     TemplateData: 'TemplateData',
//   },
// ];

const ImportTemplet = ({ setRefreshData, Version, getVersion }) => {
  const [query, setQuery] = useState(null);

  const [importData, setImportData] = useState([]);
  let { userId } = useParams();
  const [selectedValue, setSelectedValue] = useState('');
  const [loading, setLoading] = useState(false);
  const handleSelect = (value) => {
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/diet_plan_set/import`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        user_id: userId,
        template_id: value.id,
        version_id: Version,
      },
    };
    axios
      .request(config)
      .then(
        (data) => {
          // toast.success(data.data.message);
          getVersion();
          setRefreshData((prev) => !prev);
        },
        (err) => {
          toast.error(err.response.data.message);
        }
      )
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (query === null) return;
    setLoading(true);
    setImportData([]);
    const controller = new AbortController();
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/diet_plan_set?search=${query}`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(config)
      .then(
        (data) => {
          setImportData(data.data.data);
          setLoading(false);
        },
        (err) => {
          toast.error(err);
          setLoading(false);
        }
      )
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });

    return () => {
      controller.abort();
    };
  }, [query]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // if (setTemplates) {
    //   setTemplates([]);
    // }
  }, [open]);
  return (
    <>
      <div className="border-b">
        <div className="2xl:w-[200px] lg:w-40 md:w-36 wid-170px">
          <Combobox
            value={selectedValue}
            onChange={(data) => {
              setOpen(!open);
            }}
          >
            {/*  */}
            <div className="relative">
              <div
                className={`relative w-full cursor-default rounded bg-white  lg:pt-[5px] md:pt-[3px]  lg:px-[1px] md:px-[6px] text-left   font-Regular 2xl:text-base xl:text-[14.22px] lg:text-[11.85px]`}
              >
                <Combobox.Input
                  className={`w-full border-none 2xl:py-3 2xl:pl-[10px]  lg:py-[6px] md:py-[4px]  lg:pl-[8px] md:pl-[6px]  lg:pr-[32px] md:pr[28px]  text-gray-900 focus:outline-none 2xl:text-base xl:text-[14.22px] lg:text-[11.85px]`}
                  displayValue={(data) => data.name}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder={'Import Template'}
                />
                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex justify-between items-center truncate">
                    <div>
                      <UpArray
                        className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
                      />
                    </div>
                  </div>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <div className=" text-gray-400" aria-hidden="true" />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options
                  className={`${
                    importData.length > 0 || importData.length > 1 ? 'border' : ''
                  }  absolute mt-1 max-h-52 w-full overflow-auto  border-[#E6E6E6] shadow-[4px 4px 8px 2px rgba(0, 0, 0, 0.12)] rounded-lg bg-white z-50  2xl:text-xs 2xl:leading-[18px] xl:text-sm lg:text-xs md:text-[10px] font-Regular`}
                >
                  {importData.length === 0 && query !== '' && !loading ? (
                    <div className="relative cursor-default select-none py-2  px-2 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    importData.map((template, id) => (
                      <Combobox.Option
                        key={id}
                        onClick={() => handleSelect(template)}
                        className={({ active }) =>
                          `relative cursor-default select-none 2xl:px-5 2xl:py-[10px]   lg:py-[8px] lg:px-2 md:py-[6px] md:px-3 border-b ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={template}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {template.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }'
                                  }`}
                              >
                                <div className=" lg:h-4 lg:w-4 md:h-3 md:w-3" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </>
  );
};

export default ImportTemplet;

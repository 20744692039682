import Home from '../components/pages/Home';
// import Header from '../components/shared/Header';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/pages/Footer';
import Sidebar from '../components/shared/Sidebar';
import DietPlan from '../components/pages/DietPlanPage';
import { useEffect, useState } from 'react';
import CreateDiet from '../components/pages/CreateDiet';
import PdfViewer from '../components/pages/PdfViewer';
import ProgressTrackerHome from '../components/pages/ProgressTrackerHome';
import ProgressAnalysisHome from '../components/pages/ProgressAnalysisHome';
import CustomerDashboard from '../components/pages/CustomerDashboard';
import CustomerList from '../components/pages/CustomerList';
import NutritionDashboard from '../components/pages/NutritionDashboard';
import RecipePanel from '../components/layout/Nutrition/Recipe/RecipePanel';
import IngredientsAdd from '../components/layout/Nutrition/Ingredients/IngredientsAdd';
import RoleManagement from '../components/pages/RoleManagement';
import { ReactComponent as AdminPanel } from '../assets/images/admin_panel.svg';
import { getToken } from '../Utils/Authenticate';
import { setAdmin } from '../Store/UserDataSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as LegacyError } from '../assets/images/LegacyError.svg';
import axios from 'axios';
import MasterDashboard from '../components/pages/Master';

export default function AppRoutes() {
  const [userData, setUserData] = useState({ name: 'Ananya Bhatia', role_name: 'Nutritionist' });
  const [open, setOpen] = useState(false);
  const [path, setPath] = useState('');
  let location = useLocation();
  let navigate = useNavigate();
  let token = getToken();
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);

  const customer = permission?.role_permissions?.find(
    (permission) => permission.name === 'Customer Dashboard'
  );
  const verification = permission?.role_permissions?.find(
    (permission) => permission.name === 'Verification Panel'
  );
  const progress = permission?.role_permissions?.find(
    (permission) => permission.name === 'Progress Tracker'
  );
  const roles = permission?.role_permissions?.find(
    (permission) => permission.name === 'Role Management'
  );
  const nutritionIngredient = permission?.role_permissions?.find(
    (permission) => permission.name === 'Ingredients Panel'
  );
  const nutritionRecipe = permission?.role_permissions?.find(
    (permission) => permission.name === 'Recipe Panel'
  );
  const masterMeasurement = permission?.role_permissions?.find(
    (permission) => permission.name === 'Master Measurement Panel'
  );

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const isPdfRoute = path === '/pdfview';
  const dispatch = useDispatch();

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/user/get_user`, {
        headers: headers,
      })
      .then((res) => {
        let { data: response } = res;
        if (response.user) {
          setUserData(response.user);
          dispatch(setAdmin(response.user));
        }
      })
      .catch((error) => {
        if (
          error.response.data.code === '401' ||
          error.response.data.message === 'Invalid token !!'
        ) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      if (customer?.can_view) {
        navigate('/customer/dashboard');
      } else if (verification?.can_view) {
        navigate('/plan/verification');
      } else if (progress?.can_view) {
        navigate('/progress/tracker');
      } else if (roles?.can_view) {
        navigate('/role_management');
      } else if (nutritionIngredient?.can_view || nutritionRecipe?.can_view) {
        navigate('/nutrition');
      } else if (masterMeasurement?.can_view) {
        navigate('/master');
      } else {
        navigate('/');
      }
    }
  }, [
    location,
    customer,
    masterMeasurement,
    nutritionIngredient,
    nutritionRecipe,
    roles,
    progress,
    verification,
  ]);

  return (
    <>
      <div className="h-screen overflow-hidden">
        <div className="flex ">
          {!isPdfRoute && <Sidebar userData={userData} setUserData={setUserData} token={token} />}
          <div
            className={`flex-1 overflow-hidden ${
              !isPdfRoute && 'lg:px-[40px] '
            }  w-full overflow-auto  bg-secondary-3/20 h-screen`}
          >
            <Routes>
              <Route path="/pdfview" element={<PdfViewer />} />
              {masterMeasurement?.can_view ? (
                <Route
                  path="/master"
                  element={<MasterDashboard masterMeasurement={masterMeasurement} />}
                />
              ) : null}
              {verification?.can_view ? (
                <>
                  <Route path="/plan/verification" element={<Home />}></Route>
                  <Route path="/dietplan/:userId" element={<DietPlan />} />
                </>
              ) : null}
              {verification?.can_create ? (
                <Route path="/createDiet/:userId/:planId" element={<CreateDiet />} />
              ) : null}
              {progress?.can_view ? (
                <>
                  <Route path="/progress/tracker" element={<ProgressTrackerHome />} />
                  <Route path="/progressAnalysis/:userId" element={<ProgressAnalysisHome />} />
                </>
              ) : null}
              {customer?.can_view ? (
                <>
                  <Route path="/customer/dashboard" element={<CustomerList />} />
                  <Route path="/customerDetails/:userId" element={<CustomerDashboard />} />
                </>
              ) : null}
              {roles?.can_view ? (
                <>
                  <Route path="/role_management" element={<RoleManagement />} />
                </>
              ) : null}
              {nutritionIngredient?.can_view || nutritionRecipe?.can_view ? (
                <>
                  <Route
                    path="/nutrition"
                    element={
                      <NutritionDashboard
                        nutritionIngredient={nutritionIngredient}
                        nutritionRecipe={nutritionRecipe}
                      />
                    }
                  />
                </>
              ) : null}
              {nutritionIngredient?.can_create ? (
                <>
                  <Route path="/nutrition/ingredient/add" element={<IngredientsAdd />} />
                  <Route path="/nutrition/ingredient/edit/:id" element={<IngredientsAdd />} />
                </>
              ) : null}
              <Route path="/nutrition/recipeDashboard" element={<RecipePanel />} />
              <Route
                path="*"
                element={
                  <div className="flex flex-col justify-center items-center mt-5 h-[90vh]">
                    <div className="flex items-center justify-center">
                      <LegacyError className="w-full h-full" />
                    </div>
                    <div className="flex items-center justify-center">
                      {' '}
                      It seems you have only Access to Legacy Dashboard, Click here
                      <AdminPanel
                        className="w-5 h-5 ml-2 cursor-pointer"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_LOGIN_PAGE}?user=${token}`,
                            '_blank'
                          );
                        }}
                      />
                    </div>
                  </div>
                }
              ></Route>
            </Routes>
          </div>
        </div>
        {!isPdfRoute && <Footer open={open} />}
      </div>
    </>
  );
}

import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import { CountryCode } from './CountryCode';
import { Paper, Popper, TextField } from '@mui/material';
import { ConfirmDelete } from './ConfirmDelete';

export const Delete = () => {
  const [Name, setName] = useState('');
  const [Number, setNumber] = useState('');
  const [OpenError, setOpenError] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState({ code: '+91', name: 'India' });

  return (
    <div className="flex justify-center items-center h-screen bg-[#FEFBF3]">
      <div
        className="flex items-center justify-center bg-[#FFF] w-[80%] rounded-[30px]"
        style={{ boxShadow: '0px 0px 193.3px 40px rgba(185, 185, 185, 0.25)' }}
      >
        <div className="flex justify-center items-center flex-col">
          <br />
          <Logo className="xl:w-[52px] xl:h-[50px]" />
          <span className="font-[600] 2xl:text-[32px] xl:text-[25px] lg:">unlock.fit</span>
          <br />
          <span className="font-[600] 2xl:text-[30px] xl:text-[22px]">Delete Account</span>
          <br />
          <br />
          <div className="flex flex-col justify-start w-[80%]">
            <br />
            <div className="flex flex-col">
              <span className="text-[#9F9F9F]">Email</span>
              <input
                type="email"
                className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                value={Name}
                style={{
                  boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                }}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <br />
            <span className="font-[600] text-[#9F9F9F] text-[15px] text-center">OR</span>
            <br />
            <div className="flex flex-col">
              <span className="text-[#9F9F9F]">Phone Number</span>
              <div className="flex justify-between">
                <div className="w-[30%]">
                  <Autocomplete
                    id="phone"
                    autoHighlight={true}
                    options={CountryCode}
                    getOptionLabel={(option) => option.code}
                    value={selectedCountryCode}
                    onChange={(event, newValue) => {
                      setSelectedCountryCode(newValue);
                    }}
                    filterOptions={(options, state) => {
                      return options.filter(
                        (option) =>
                          option.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                          option.code
                            .replace(/\D/g, '')
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase()) ||
                          option.code.toLowerCase().includes(state.inputValue.toLowerCase())
                      );
                    }}
                    PopperComponent={({ children, ...other }) => (
                      <Popper {...other}>
                        <Paper style={{ width: '300px' }}>{children}</Paper>
                      </Popper>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: '0px',
                            '&:hover fieldset': {
                              borderColor: '#E6E6E6',
                            },
                            '&:focus': {
                              outLine: 'none',
                            },
                          },
                          endAdornment: null,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li style={{ fontSize: 15 }} {...props}>
                        <img src={option.flag} alt={option.name} />
                        {`${option.code} ${option.name}`}
                      </li>
                    )}
                    disableClearable={true}
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: 10,
                    }}
                  >
                    <option value="" key="placeholder">
                      Select a country
                    </option>
                  </Autocomplete>
                </div>
                <div className="w-[65%]">
                  <input
                    type="phone"
                    className="py-[5px] w-full px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                    value={Number}
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                    onChange={(e) => setNumber(e.target.value.replace(/\D/g, ''))}
                  />
                </div>
              </div>
            </div>
            <br />
            <br />
            <button
              type="submit"
              className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
              onClick={() => {
                if (Number || Name) {
                  setOpenError(true);
                } else {
                  toast.error('Please fill out one of the fields');
                }
              }}
            >
              Delete Account
            </button>
            <br />
            <br />
          </div>
          <br />
          <br />
          <br />
        </div>
        <ConfirmDelete
          setOpenError={setOpenError}
          OpenError={OpenError}
          Number={Number}
          selectedCountryCode={selectedCountryCode}
          Name={Name}
        />
      </div>
    </div>
  );
};

import React, { Fragment, useState } from 'react';
import { ReactComponent as Close } from '../../../../assets/images/close.svg';
import { ReactComponent as MacroSelect } from '../../../../assets/images/select_for_role.svg';
import { ReactComponent as MacroDeSelect } from '../../../../assets/images/deselect.svg';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ExportIngredient = ({ ExportPop, setExportPop, headers, MaxCount }) => {
  const [CustomValue, setCustomValue] = useState({
    start: '',
    end: '',
  });

  const exportApi = () => {
    if (CustomValue.start || CustomValue.end) {
      if (CustomValue.start !== 0) {
        if (CustomValue.end <= MaxCount) {
          if (CustomValue.start && CustomValue.end >= CustomValue.start) {
            axios
              .post(
                `${
                  process.env.REACT_APP_BASE_URL
                }/admin/nutrition_plans/v1/ingredient/export?start=${Number(
                  CustomValue.start
                )}&end=${Number(CustomValue.end)}`,
                {},
                { headers }
              )
              .then((response) => {
                const finalLink = response.data.data[0];
                const link = document.createElement('a');
                link.download = finalLink.excel_url;
                link.href = finalLink.excel_url;
                link.target = '_blank';
                link.click();
                setExportPop(false);
                setCustomValue({ ...CustomValue, start: '', end: '' });
              })
              .catch((error) => {
                const finalLink = error.response.data;
                toast.error(finalLink.message);
              });
          } else {
            toast.error('End Index should be greater than or equal to Start Index');
          }
        } else {
          toast.error('End Index should be less than or equal to maximum number');
        }
      } else {
        toast.error('Start Index should be greater than 0');
      }
    } else {
      toast.error('Give a Range for Start and End Index');
    }
  };

  return (
    <Transition appear show={ExportPop} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[60]"
        onClose={() => {
          setExportPop(false);
          setCustomValue({ ...CustomValue, start: '', end: '' });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className=" w-[30%] flex flex-col items-center transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between items-center head border-b w-full p-4 2xl:text-[18px] xl:text-[16px] lg:text-[14px] font-semibold">
                  Export Ingredient
                  <Close
                    className="cursor-pointer"
                    onClick={() => {
                      setExportPop(false);
                      setCustomValue({ ...CustomValue, start: '', end: '' });
                    }}
                  />
                </div>
                <div className="main-content w-full">
                  <div className="form flex flex-col px-6 pt-4">
                    <span className="text-[#6F6F6F] 2xl:text-[16px] xl:text-[14px] lg:text-[12px]">
                      Range
                      <div className="flex justify-between">
                        <div className="border border-[#E6E6E6] py-[5px] px-[10px] rounded-[10px]">
                          <input
                            className="w-full outline-none border-b"
                            type="text"
                            placeholder="Start (min. 1)"
                            value={CustomValue.start}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(/\D/g, '');
                              setCustomValue({ ...CustomValue, start: newValue });
                            }}
                          />
                        </div>
                        <div className="border border-[#E6E6E6] py-[5px] px-[10px] rounded-[10px]">
                          <input
                            className="w-full outline-none border-b"
                            type="text"
                            placeholder={`End (max. ${MaxCount})`}
                            value={CustomValue.end}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(/\D/g, '');
                              setCustomValue({ ...CustomValue, end: newValue });
                            }}
                          />
                        </div>
                      </div>
                    </span>
                    <br />
                    <div className="flex justify-center">
                      <div
                        className="cursor-pointer text-center w-[80%] text-[#FFFFFF] 2xl:text-[16px] xl:text-[14px] lg:text-[12px] bg-[#D2A62C] font-normal border border-[#D2A62C] rounded-[10px] py-[5px]"
                        onClick={() => {
                          exportApi();
                        }}
                      >
                        Export
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

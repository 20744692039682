import React from 'react';

export default function Button({ children, className, onClick, disabled }) {
  return (
    <>
      <div>
        <button
          onClick={onClick}
          className={` cursor-pointer px-10 py-2 text-SmallerBodyDisplayText rounded-[10px] font-Regular  ${className}`}
          disabled={disabled}
        >
          {children}
        </button>
      </div>
    </>
  );
}

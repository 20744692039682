import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  userData: [{}],
  adminData: {},
};

export const UserDataSlice = createSlice({
  name: 'UserDataSlice',
  initialState: {
    ...initialState,
  },

  reducers: {
    setUser: (data, action) => {
      data.userData = action.payload;
    },
    setAdmin: (data, action) => {
      data.adminData = action.payload;
    }

  },
});

export const { setUser, setAdmin } = UserDataSlice.actions;
export default UserDataSlice.reducer;

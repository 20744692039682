import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as Verified } from '../../assets/images/mail_sent.svg';
import { useNavigate } from 'react-router-dom';

const RedirectWithCountdown = ({ to, countdownSeconds, setOpenSuccess }) => {
  const [countdown, setCountdown] = useState(countdownSeconds);
  const navigate = useNavigate();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      navigate(`/${to}`);
      setOpenSuccess(false);
    }, countdownSeconds * 1000);

    return () => {
      clearInterval(countdownInterval);
      clearTimeout(redirectTimeout);
    };
  }, [countdownSeconds, navigate, to]);

  return (
    <div className="text-[#6B6B6B]">
      {`Redirecting in `}
      <span className="text-[#6B6B6B]">{`${countdown} sec`}</span>
      {` to ${to}`}
    </div>
  );
};

export const SuccessMessage = ({
  setForgetPasswordScreen,
  Email,
  OpenSuccess,
  setOpenSuccess,
  type,
}) => {
  const navigate = useNavigate();
  return (
    <Transition appear show={OpenSuccess} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (type === 'forgot') {
            setOpenSuccess(false);
            setForgetPasswordScreen(false);
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[60%] flex justify-center items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div
                  className={`flex justify-center items-center flex-col w-[${
                    type === 'forgot' ? '65%' : '55%'
                  }]`}
                >
                  <br />
                  <Logo className="xl:w-[52px] xl:h-[50px]" />
                  <span className="font-[600] 2xl:text-[32px] xl:text-[25px] lg:">unlock.fit</span>
                  <br />
                  {type === 'forgot' ? (
                    <div className="flex items-center justify-center flex-col">
                      <span className="font-[600] 2xl:text-[30px] xl:text-[22px]">
                        Verify link From Your Mail
                      </span>
                      <br />
                      <br />
                      <div className="flex justify-center">
                        <Verified className="mr-[5px] mt-1" />
                        <div className="text-[#9F9F9F]">
                          Please Check Your Mail <span className="text-[#626262]"> {Email} </span>{' '}
                          for the verification link.
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center flex-col">
                      <RedirectWithCountdown
                        to="login"
                        countdownSeconds={5}
                        setOpenSuccess={setOpenSuccess}
                      />
                      <br />
                      <span className="text-[27px] text-[#4E4E4E] text-center">
                        Your password has been changed successfully !
                      </span>
                    </div>
                  )}
                  <br />
                  <br />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

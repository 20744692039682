import React, { useMemo, useState, useEffect } from 'react';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SortIcon from '../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import { ReactComponent as Edit } from '../../assets/images/smalledit.svg';
import { ReactComponent as Check } from '../../assets/images/check.svg';
import { ReactComponent as Cancel } from '../../assets/images/cancle.svg';
// import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
// import { ReactComponent as Delete } from '../../assets/images/delete_icon.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PopUpForOverView from './PopUpForOverView';

export default function RDA({ verification }) {
  const [Formula, setFormula] = useState({
    columns: [
      { id: 0, column_name: 'Nutrient Name', map_column: 'nutrient_name', type: 'string' },
      { id: 1, column_name: 'Formula', map_column: 'formula', type: 'string' },
      { id: 1, column_name: 'Action', map_column: 'action', type: 'string' },
    ],
    rows: [
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
      { nutrient_name: -1, formula: -1, action: -1 },
    ],
  });
  const [sorting, setSorting] = useState([]);
  const [IsEdit, setIsEdit] = useState(false);
  const [EditFormula, setEditFormula] = useState();
  const [EditRowId, setEditRowId] = useState();
  const [FormulaName, setFormulaName] = useState();
  const [Macros, setMacros] = useState([]);
  const [OpenModal, setOpenModal] = useState(false);

  const columnHelper = createColumnHelper();

  let { userId } = useParams();
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const columns = useMemo(() => {
    const { columns } = Formula;
    const filteredColumns = verification?.can_create
      ? columns
      : columns.filter((column) => column.map_column !== 'action');
    return filteredColumns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633"></Skeleton>
            </div>
          ) : (
            <span className="text-[13px]">{info.getValue()}</span>
          ),
        header: () => (
          <div>
            <span className="text-[15px]">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [Formula]);

  const table = useReactTable({
    data: Formula.rows,
    columns: columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const fetchFormula = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/formula?user_id=${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const fetchedRows = response.data.data;
        setFormula((prevFormula) => ({
          ...prevFormula,
          rows: fetchedRows,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchOverView = (name) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/formula/overview`,
        {
          user_id: userId,
          nutrient_name: name,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        const fetchedRows = response.data.data;
        setOpenModal(true);
        setMacros(fetchedRows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchFormula();
  }, []);

  // const deleteFormula = (formulaID) => {
  //   axios
  //     .delete(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/formula/${formulaID}`, {
  //       headers: headers,
  //     })
  //     .then((response) => {
  //       fetchFormula();
  //       toast.success(response.data.message);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const updateFormula = () => {
    const bodyForEdit = {
      nutrient_name: FormulaName,
      formula: EditFormula,
      is_unisex: true,
      is_default: true,
    };
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/formula/${EditRowId}`,
        bodyForEdit,
        {
          headers: headers,
        }
      )
      .then((response) => {
        fetchFormula();
        setFormulaName('');
        setEditFormula('');
        setEditRowId('');
        setIsEdit(false);
        toast.success(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="content-rda">
        <div className="font-medium flex h-full text-gray-900">
          <div className="w-full flex justify-center 2xl:text-[14px] xl:text-[14.59px] lg:text-[14.47px] p-[10px] border-r overflow-auto">
            <table className="min-w-full client-table table-margin">
              <thead className="sticky top-[-10px] items-center ">
                {table?.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className=" flex  ">
                    {headerGroup.headers.map((header, index, array) => {
                      return (
                        <th
                          key={header.id}
                          className={`text-start bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base lg:text-xs ${
                            verification?.can_create
                              ? header.column.id === 'nutrient_name'
                                ? 'w-[20%]'
                                : header.column.id === 'formula'
                                ? 'w-[70%]'
                                : header.column.id === 'action'
                                ? 'w-[10%]'
                                : ''
                              : header.column.id === 'nutrient_name'
                              ? 'w-[20%]'
                              : header.column.id === 'formula'
                              ? 'w-[80%]'
                              : ''
                          }`}
                          onClick={
                            header.column.id !== 'action'
                              ? header.column.getToggleSortingHandler()
                              : null
                          }
                        >
                          <div className="flex items-center justify-between w-full">
                            {header.isPlaceholder
                              ? null
                              : flexRender(header.column.columnDef.header)}
                            {array?.length - 1 === index + 10
                              ? null
                              : header.column.id !== 'action' // Check if the column is not the "action" column
                              ? {
                                  asc: (
                                    <img
                                      src={SortIcon}
                                      // height={20}
                                      className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                      style={{ transform: 'rotate(180deg)' }}
                                      alt="SortIcon"
                                    />
                                  ),
                                  desc: (
                                    <img
                                      src={SortIcon}
                                      className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                      alt="SortIcon"
                                    />
                                  ),
                                  false: (
                                    <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                  ),
                                }[header.column.getIsSorted()]
                              : null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody className="duration-1000 tbody_rda">
                {table.getRowModel().rows.map((row, rId) => (
                  <tr
                    key={row.id}
                    className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        key={cell.id}
                        onClick={() => {
                          if (
                            cell.column.id === 'formula' &&
                            (cell.row.original.nutrient_name === 'Calories' ||
                              cell.row.original.nutrient_name === 'Protein')
                          )
                            fetchOverView(cell.row.original.nutrient_name);
                        }}
                        className={`duration-1000 text-ellipsis lg:px-[14px]  2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex   2xl:text-base xl:text-sm lg:text-xs ${
                          cell.column.id === 'nutrient_name'
                            ? 'w-[20%]'
                            : cell.column.id === 'formula'
                            ? 'w-[70%]'
                            : cell.column.id === 'action'
                            ? 'w-[10%]'
                            : ''
                        } ${
                          cell.column.id === 'formula' &&
                          (cell.row.original.nutrient_name === 'Calories' ||
                            cell.row.original.nutrient_name === 'Protein') &&
                          'cursor-pointer'
                        }
                        `}
                      >
                        {cell.column.id === 'formula' &&
                        EditRowId === cell.row.original.id &&
                        IsEdit ? (
                          <div className="flex justify-center space-x-2">
                            <input
                              type="text"
                              value={EditFormula}
                              onChange={(e) => setEditFormula(e.target.value)}
                              className="w-full px-2 py-1 border rounded"
                            />
                          </div>
                        ) : cell.column.id === 'action' ? (
                          <>
                            {verification?.can_create && (
                              <div className="flex justify-center items-center space-x-2">
                                {EditRowId === cell.row.original.id && IsEdit ? (
                                  <>
                                    <Check
                                      onClick={() => {
                                        updateFormula();
                                      }}
                                      className="cursor-pointer"
                                    />
                                    <Cancel
                                      onClick={() => {
                                        setFormulaName('');
                                        setEditFormula('');
                                        setEditRowId('');
                                        setIsEdit(false);
                                      }}
                                      className="cursor-pointer"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Edit
                                      onClick={() => {
                                        setFormulaName(cell.row.original.nutrient_name);
                                        setEditFormula(cell.row.original.formula);
                                        setEditRowId(cell.row.original.id);
                                        setIsEdit(true);
                                      }}
                                      className="cursor-pointer"
                                    />
                                  </>
                                )}
                              </div>
                            )}
                          </>
                        ) : (
                          flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PopUpForOverView OpenModal={OpenModal} setOpenModal={setOpenModal} macros={Macros} />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';

import { Menu, Transition } from '@headlessui/react';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  setClientsTable,
  setOrgId,
  setTotalCount,
  setSearchQuery,
  resetDeaultClient,
} from '../../Store/ClientTableSlice';
import { sortOrganizations } from '../../Utils/SortSearch';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

const CustomSelect = () => {
  const [show, setShow] = useState(false);
  let dispatch = useDispatch();
  const [selectedOrg, setSelectedOrg] = useState('All Organization');
  let orgid = useSelector((state) => state.ClientTable.orgId);
  const scroll = { y: 0 };
  const [organizations, setOrganizations] = useState([]);
  const navigate = useNavigate();

  let token = getToken();

  useEffect(() => {
    if (token) {
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/user/getOrgList`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then((res) => {
          console.log('res', res);
          let { data: response } = res;

          if (response.code && response.code === '401') {
            window.location.assign('/auth-token');
          }

          if (response.result && response.result.toLowerCase() === 'success') {
            response.data.sort(sortOrganizations);
            response.data.push({
              is_archived: false,
              created_at: '',
              updated_at: '',
              id: '',
              org_name: 'ALL Organization',
              logo_url: '',
              website: 'ALL',
              auth_domain: 'ALL',
              org_code: '',
              mou_url: '',
              schedule_id: null,
              is_deleted: 1,
              naf_id: null,
            });
            setOrganizations(response.data);
          }
        })
        .catch((err) => {
          if (
            err.response.data.code === '401' ||
            err.response.data.message === 'Invalid token !!'
          ) {
            navigate('/login');
            // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
          }
        });
    }
  }, [token]);

  useEffect(() => {
    const selectedOrganization = organizations.find((org) => org.id === orgid.orgId);
    if (selectedOrganization) {
      setSelectedOrg(selectedOrganization.org_name);
    }
  }, [orgid, organizations]);

  // useEffect(() => {
  //   let token = getToken();
  //   const controller = new AbortController();

  //   if (token) {
  //     dispatch(resetDeaultClient());
  //     let config = {
  //       baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/diet_plans?size=50&page=1&search=&org_id=${orgid}`,
  //       method: 'get',
  //       signal: controller.signal,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     axios
  //       .request(config)

  //       .then((res) => {
  //         let { data: response } = res;

  //         if (response.code && response.code === '401') {
  //           window.location.assign('/auth-token');
  //         }

  //         if (response.result && response.result.toLowerCase() === 'success') {
  //           let newData = response.data[0].plans.map((plan, id) => {
  //             return {
  //               Customer_Id: plan.customer_id,
  //               ClientName: plan.user_name.toLowerCase(),
  //               EmailAddress: plan.user_email,
  //               DateCreated: plan.created_at,
  //               Status: plan.verified ? 'Verified' : 'Not Verified',
  //               UserId: plan.user_id,
  //             };
  //           });

  //           dispatch(setClientsTable(newData));
  //           dispatch(setOrgId(orgid));
  //           dispatch(setSearchQuery({ searchQuery: '', isNewSearch: false }));
  //           dispatch(setTotalCount(response.data[0].total_count));
  //         }
  //       })
  //       .catch((err) => {
  //         // window.location.replace(`${process.env.REACT_APP_LOGIN_PAGE}`);
  //         // console.log('clients table Error', err);
  //       });
  //   }
  //   return () => {
  //     controller.abort();
  //   };
  // }, []);

  function handleScroll(e) {
    scroll.y = e.target.scrollTop;
  }
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="">
        <Menu as="div" className="relative inline-block text-right " open={open}>
          <div>
            <Menu.Button className="  w-auto justify-center  2xl:py-[10px] 2xl:px-3 lg:py-[13px] lg:px-[10px] md:py-[4px] md:px-[6px]  shadow-input-shadow border border-secondary-3 2xl:rounded-small lg:rounded-lg md:rounded-md hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <div
                className="flex justify-between items-center "
                onClick={() => {
                  setShow(!show);
                }}
              >
                <div>
                  <span className=" lg:w-[135px] md:w-[90px] justify-start flex  2xl:text-base xl:text-sm lg:text-xs text-body-color-2 ">
                    <span
                      className=" text-left text-ellipsis whitespace-nowrap inline-block overflow-hidden"
                      style={{ width: '95%' }}
                    >
                      {selectedOrg}
                    </span>
                  </span>
                </div>
                {/* <div>{!show ? <UpArray className="transform rotate-180" /> : <UpArray />}</div> */}
                <div>
                  <UpArray className="ui-not-open:transform ui-not-open:rotate-180" />
                </div>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              onFocus={(e) => (e.target.scrollTop = scroll.y)}
              onScroll={(e) => handleScroll(e)}
              className=" overflow-y-scroll absolute z-10  mt-[2px] 2xl:w-[200px] 2xl:h-[220px]  lg:w-[170px] lg:h-[185px] md:w-[130px] md:h-[150px] origin-top-center  divide-gray-100 rounded-md  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className=" md:px-[2px] md:py-[2px] ">
                {organizations.map((organization, index) => {
                  return (
                    <>
                      <Menu.Item
                        key={index}
                        className="text-ellipsis whitespace-nowrap w-full border-b"
                      >
                        {({ active }) => (
                          <div
                            onClick={() => {
                              // console.log(organization.org_name, organization.id);
                              dispatch(setOrgId({ orgId: organization.id }));
                              // setOrgid(organization.id);
                              setSelectedOrg(organization.org_name);
                            }}
                            className={`${
                              active
                                ? ' duration-150 hover:bg-shades-2/20 hover:text-black'
                                : 'text-gray-900'
                            }  flex w-full items-start justify-center 2xl:rounded-md  md:rounded  md:px-1   `}
                          >
                            <button
                              className={classNames(
                                'flex text-left w-full text-ellipsis   whitespace-nowrap overflow-hidden justify-start my-1  items-center'
                              )}
                            >
                              <span
                                className={`${
                                  index === organizations.length - 1 && ' text-blue-500  '
                                } ${
                                  organization.org_name === selectedOrg && ' text-shades-2 '
                                } hover:text-black text-left text-ellipsis whitespace-nowrap inline-block overflow-hidden   2xl:text-base xl:text-sm lg:text-xs md:font-normal`}
                                style={{ width: '95%' }}
                              >
                                {organization.org_name}
                              </span>
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                    </>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export { CustomSelect };

// import { Listbox, Transition } from '@headlessui/react';
// import { Fragment, useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import { getToken } from '../../Utils/Authenticate';
// import {
//   resetDeaultClient,
//   setClientsTable,
//   setOrgId,
//   setSearchQuery,
//   setTotalCount,
// } from '../../Store/ClientTableSlice';
// import { sortOrganizations } from '../../Utils/SortSearch';
// const CustomSelect = () => {
//   let dispatch = useDispatch();
//   const [selectedOrg, setSelectedOrg] = useState('All Organisation');
//   const [orgid, setOrgid] = useState('');
//   const [organizations, setOrganizations] = useState([]);
//   let token = getToken();
//   useEffect(() => {
//     if (token) {
//       let config = {
//         baseURL: `${process.env.REACT_APP_BASE_URL}/admin/user/getOrgList`,
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };
//       axios
//         .request(config)
//         .then((res) => {
//           let { data: response } = res;

//           if (response.code && response.code === '401') {
//             window.location.assign('/auth-token');
//           }

//           if (response.result && response.result.toLowerCase() === 'success') {
//             response.data.sort(sortOrganizations);
//             response.data.push({
//               is_archived: false,
//               created_at: '',
//               updated_at: '',
//               id: '',
//               org_name: 'ALL Organization',
//               logo_url: '',
//               website: 'ALL',
//               auth_domain: 'ALL',
//               org_code: '',
//               mou_url: '',
//               schedule_id: null,
//               is_deleted: 1,
//               naf_id: null,
//             });
//             setOrganizations(response.data);
//           }
//         })
//         .catch((err) => {});
//     }
//   }, [token]);
//   useEffect(() => {
//     let token = getToken();
//     const controller = new AbortController();

//     if (token) {
//       dispatch(resetDeaultClient());
//       let config = {
//         baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/diet_plans?size=50&page=1&search=&org_id=${orgid}`,
//         method: 'get',
//         signal: controller.signal,
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       };
//       // console.log('ORGID', orgid);

//       axios
//         .request(config)

//         .then((res) => {
//           let { data: response } = res;

//           if (response.code && response.code === '401') {
//             window.location.assign('/auth-token');
//           }

//           if (response.result && response.result.toLowerCase() === 'success') {
//             let newData = response.data[0].plans.map((plan, id) => {
//               return {
//                 Customer_Id: plan.customer_id,
//                 ClientName: plan.user_name.toLowerCase(),
//                 EmailAddress: plan.user_email,
//                 DateCreated: plan.created_at,
//                 Status: plan.verified ? 'Verified' : 'Not Verified',
//                 UserId: plan.user_id,
//               };
//             });

//             dispatch(setClientsTable(newData));
//             dispatch(setOrgId(orgid));
//             dispatch(setSearchQuery({ searchQuery: '', isNewSearch: false }));
//             dispatch(setTotalCount(response.data[0].total_count));
//           }
//         })
//         .catch((err) => {
//           // console.log('clients table Error', err);
//         });
//     }
//     return () => {
//       controller.abort();
//     };
//   }, [orgid, dispatch]);
//   return (
//     <Listbox value={selectedOrg} onChange={setSelectedOrg}>
//       <div className="relative mt-1">
//         <Listbox.Button className="  w-auto justify-center  2xl:py-[10px] 2xl:px-3 lg:py-[7px] lg:px-[10px] md:py-[4px] md:px-[6px]  shadow-input-shadow border border-secondary-3 2xl:rounded-small lg:rounded-lg md:rounded-md hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  lg:w-[135px] md:w-[90px]  flex  lg:text-xs md:text-[8px]">
//           {selectedOrg}
//         </Listbox.Button>
//         <Transition
//           as={Fragment}
//           leave="transition ease-in duration-100"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <Listbox.Options className=" overflow-y-scroll absolute z-10  mt-[2px]   lg:w-[170px] lg:h-[185px] md:w-[130px] md:h-[150px] origin-top-center  divide-gray-100 rounded-md  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
//             {organizations.map((organization, index) => (
//               <Listbox.Option
//                 key={organization.id}
//                 value={organization.org_name}
//                 className={({ active }) =>
//                   `${
//                     active ? 'text-blue-500' : 'text-black'
//                   } cursor-default select-none relative py-2 pl-10 pr-4`
//                 }
//                 onClick={() => {
//                   setOrgid(organization.id);
//                   setSelectedOrg(organization.org_name);
//                 }}
//               >
//                 {({ selected, active }) => (
//                   <>
//                     <span
//                       className={`${index === organizations.length - 1 && ' text-blue-500  '} ${
//                         organization.org_name === selectedOrg && ' text-shades-2 '
//                       } hover:text-black text-left text-ellipsis whitespace-nowrap inline-block overflow-hidden  lg:text-xs md:text-[8px] md:font-normal`}
//                       style={{ width: '95%' }}
//                     >
//                       {organization.org_name}
//                     </span>
//                   </>
//                 )}
//               </Listbox.Option>
//             ))}
//           </Listbox.Options>
//         </Transition>
//       </div>
//     </Listbox>
//   );
// };
// export { CustomSelect };

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import SortIcon from '../../assets/images/des.png';
import Skeleton from 'react-loading-skeleton';
import {
  ProgressAnalysis,
  getTrackersDropDownData,
  setClickedDate,
  setClickedIndex,
  setColId,
  setEditValue,
  setSelectedColumn,
  setSelectedParameter,
  setUserId,
} from '../../Store/ProgressAnalysisSlice';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgressData, getprogressTracker } from '../../Store/ClientTableSlice';
import { ReactComponent as ClientError } from '../../assets/images/ClientError.svg';
import PrgAnlEditValue from '../shared/PrgAnlEditValue';
import PrgAnlAddValue from '../shared/PrgAnlAddValue';
import { useParams } from 'react-router-dom';
import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';

export default function ProgressTrackerTable({ progress }) {
  // debugger;
  const [sorting, setSorting] = useState([]);
  let [isOpen, setIsOpen] = useState(false);
  const [IsTrue, setIsTrue] = useState(false);

  const [openAddValue, setOpenAddValue] = useState(false);
  const dispatch = useDispatch();

  function closeModal() {
    setIsOpen(false);
    // setTimeout(() => {
    dispatch(setSelectedParameter({ id: -1, name: '' }));
    dispatch(setClickedIndex({ row: -1, col: -1 }));
    dispatch(setClickedDate(''));
    // }, 1000);
  }

  let trackerData = useSelector((state) => state.ProgressAnalysis.trackersDropdownData);
  function openModal(data) {
    let _data = data?.row;
    let column_name = progressAnalysisData.columns[data?.col]?.column_name
      .split(' ')[0]
      .toLowerCase();
    console.log(
      'selectedData',
      _data,
      progressAnalysisData.rows[data?.row],
      column_name?.toLowerCase()
    );
    let column = progressAnalysisData.columns[data?.col]?.id;
    // debugger;
    if (column_name === 'waist') {
      column_name = 'waist_size';
    } else if (column_name === 'hip') {
      column_name = 'hip_size';
    } else if (column_name === 'body') {
      column_name = 'body_fat_percentage';
    } else if (column_name === 'bg') {
      column_name = 'bg_level';
    }
    trackerData = trackerData.filter((obj) => {
      return obj?.id === column;
    });
    console.log('selectedParam', trackerData[0], 'tracker');
    console.log('data', progressAnalysisData);
    if (trackerData[0]?.name?.length) {
      dispatch(setSelectedParameter(trackerData[0]));
    } else {
      // console.log("hey")
      dispatch(setSelectedParameter({ id: -1, name: '' }));
    }
    dispatch(setSelectedColumn(column_name));
    dispatch(setEditValue(_data[column_name]?.value));
    dispatch(setColId(_data[column_name]?.id));
    console.log('clicked', _data[column_name]?.id);
    console.log('selected', data.row.date);
    dispatch(setClickedDate(data.row.date));
    // debugger;
    if (_data[column_name]?.id === 0) {
      setOpenAddValue(true);
    } else {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    dispatch(getTrackersDropDownData());
  }, []);

  const closeAddValueModal = () => {
    // dispatch(setSelectedParameter({ id: -1, name: "" }));

    // dispatch(setClickedDate(""));
    setOpenAddValue(false);
    dispatch(setSelectedParameter({ id: -1, name: '' }));
    dispatch(setClickedIndex({ row: -1, col: -1 }));
    dispatch(setClickedDate(''));
    // setTimeout(() => {}, 1000);
  };

  const columnHelper = createColumnHelper();
  const progressTrackerData = useSelector(getprogressTracker);
  const { userId } = useParams();

  useEffect(() => {
    dispatch(setUserId(userId));
    dispatch(fetchProgressData());
  }, []);

  const progressAnalysisData = useSelector((state) => state.ProgressAnalysis.data);
  // console.log('progressAnalysisData', progressAnalysisData.rows);

  useEffect(() => {
    dispatch(ProgressAnalysis());
  }, []);

  const columns = useMemo(() => {
    const { columns } = progressAnalysisData;
    return columns.map((column, index) => {
      return columnHelper.accessor((row) => row[column.map_column], {
        id: column.map_column,
        cell: (info) =>
          info.getValue() === -1 ? (
            <div style={{ width: '100%', border: 'none' }}>
              <Skeleton width="100%" baseColor="#e9d39633">
                {index != 0 ? info.getValue().value : info.getValue()}
              </Skeleton>
            </div>
          ) : info.getValue().message !== '' ? (
            <Tooltip title={info.getValue().message} placement="top">
              <span className="capitalize">
                {index !== 0 ? info.getValue().value : info.getValue()}
              </span>
            </Tooltip>
          ) : (
            <span className="capitalize">
              {index !== 0 ? info.getValue().value : info.getValue()}
            </span>
          ),
        header: () => (
          <div>
            <span className="">{column.column_name}</span>
          </div>
        ),
      });
    });
  }, [progressAnalysisData]);

  const table = useReactTable({
    data: progressAnalysisData.rows,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    setSorting([]);
  }, []);

  useEffect(() => {
    dispatch(fetchProgressData());
  }, []);

  return (
    <>
      <div className={`lg:mt-[5px] table-client`}>
        {progressAnalysisData.rows.length > 0 ? (
          <>
            <div className="tableFixHead overflow-x-scroll">
              <table className="min-w-[-webkit-fill-available] table-margin overflow-hidden">
                <thead className="sticky top-0 items-center ">
                  {table?.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className=" flex  ">
                      {headerGroup.headers.map((header, index, array) => {
                        return (
                          <th
                            key={header.id}
                            className={`text-start Analysis_col-${index}  bg-[#f7efd9] text-body-color-1 first-of-type:rounded-tl-xl last-of-type:rounded-tr-xl   border-r px-4 last-of-type:border-r-0  py-[9px]  font-medium 2xl:text-base    lg:text-xs  ${
                              header.id === 'Email Address' && ''
                            }`}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            <div className="flex items-center justify-between w-full">
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header)}
                              {array?.length - 1 === index + 10
                                ? null
                                : {
                                    asc: (
                                      <img
                                        src={SortIcon}
                                        // height={20}
                                        className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                        style={{ transform: 'rotate(180deg)' }}
                                        alt="SortIcon"
                                      />
                                    ),
                                    desc: (
                                      <img
                                        src={SortIcon}
                                        className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                        alt="SortIcon"
                                      />
                                    ),
                                    false: (
                                      <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                                    ),
                                  }[header.column.getIsSorted()]}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="duration-1000 tbody_analysis">
                  {table.getRowModel().rows.map((row, rId) => (
                    <tr
                      key={row.id}
                      className=" clienttable_row  even:bg-secondary-3/20 mb-1 flex w-full"
                    >
                      {row.getVisibleCells().map((cell, index) => (
                        <td
                          key={cell.id}
                          className={`duration-1000 cursor-pointer Analysis_col-${index}  text-ellipsis lg:px-[14px] 2xl:text-base 2xl:py-[10px] xl:py-[8px] lg:py-[6px] md:px-3  text-left text-body-color-2 last-of-type:border-r-0 font-Regular border-r flex    xl:text-sm lg:text-xs  `}
                          onClick={() => {
                            let dataToBeClicked = row.original;
                            if (progress?.can_create) {
                              let token = getToken();
                              let config = {
                                baseURL: `${process.env.REACT_APP_BASE_URL}/admin/tracker/add_status/${index}`,
                                method: 'get',
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                },
                              };
                              axios
                                .request(config)
                                .then((data) => {
                                  setIsTrue(data.data.is_add);
                                  if (data.data.is_add === true) {
                                    dispatch(setClickedIndex({ row: rId, col: index }));
                                    openModal({ row: dataToBeClicked, col: index });
                                  } else {
                                    toast.warn(data.data.message);
                                  }
                                })
                                .catch((err) => {});
                            }
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <div className="flex justify-center items-center">
            <ClientError className="table-client md:h-[30rem]" />
          </div>
        )}
      </div>
      <PrgAnlEditValue isOpen={isOpen} closeModal={closeModal} />
      <PrgAnlAddValue isOpen={openAddValue} closeModal={closeAddValueModal} />
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import axios from 'axios';
import Button from '../common/Button';
import { toast } from 'react-toastify';

export const SaveMeal = ({ OpenSave, setOpenSave, headers, MealPass }) => {
  const [Templates, setTemplates] = useState({
    diet_preferences: [],
    cuisine_preferences: [],
    locality: [],
    allergies: [],
  });
  const [SaveData, setSaveData] = useState({
    meal_type: '',
    meal_time: '',
    recipe_id: '',
    serving: '',
    recipe_measurement: '',
    add_on_ids: [],
    add_on_serving: [],
    add_on_recipe_measurement: [],
    diet_preferences_ids: [],
    cusine_preference_ids: [],
    locality_ids: [],
    allergy_ids: [],
    calories: '',
    proteins: '',
    fats: '',
    carbs: '',
  });

  const fetchData = async (url, stateKey) => {
    try {
      const { data } = await axios.get(url, { headers: headers });
      const storeData = data.data;
      setTemplates((prevTemplates) => ({ ...prevTemplates, [stateKey]: storeData }));
    } catch (err) {
      console.error(err);
    }
  };

  const getAllPreferences = async () => {
    await fetchData(
      `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/diet_preferences`,
      'diet_preferences'
    );
    await fetchData(
      `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/cuisine_preferences`,
      'cuisine_preferences'
    );
    await fetchData(
      `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/locality`,
      'locality'
    );
    await fetchData(
      `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/allergies`,
      'allergies'
    );
  };

  useEffect(() => {
    if (OpenSave) getAllPreferences();
  }, [OpenSave]);

  const saveMeal = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/recipe_template/add`,
        SaveData,
        { headers: headers }
      )
      .then((response) => {
        const getData = response.data;
        toast.success(getData.message);
        setOpenSave(false);
        setSaveData({
          ...SaveData,
          meal_type: '',
          meal_time: '',
          recipe_id: '',
          serving: '',
          recipe_measurement: '',
          add_on_ids: [],
          add_on_serving: [],
          add_on_recipe_measurement: [],
          diet_preferences_ids: [],
          cusine_preference_ids: [],
          locality_ids: [],
          allergy_ids: [],
          calories: '',
          proteins: '',
          fats: '',
          carbs: '',
        });
      })
      .catch((error) => {
        const getData = error.response.data;
        toast.error(getData.message);
      });
  };

  useEffect(() => {
    if (MealPass && MealPass.suggested_recipes && MealPass.suggested_recipes.length > 0) {
      const data = MealPass;
      const values = data.nutrition_profile;
      const recipe = data.suggested_recipes;
      const addOnIds = recipe?.slice(1)?.map((item) => item.recipe_id);
      const addOnServings = recipe?.slice(1)?.map((item) => item.serving);
      const addOnMeasurements = recipe?.slice(1)?.map((item) => item.measurement);
      setSaveData((prevTemplates) => ({
        ...prevTemplates,
        meal_type: data.meal_type,
        meal_time: data.meal_time,
        recipe_id: recipe[0]?.recipe_id || null,
        serving: recipe[0]?.serving || null,
        recipe_measurement: recipe[0]?.measurement || null,
        add_on_ids: addOnIds,
        add_on_serving: addOnServings,
        add_on_recipe_measurement: addOnMeasurements,
        calories: values?.find((data) => data.nutritent_name === 'Calories')?.consumed_value || 0,
        carbs: values?.find((data) => data.nutritent_name === 'Carbs')?.consumed_value || 0,
        proteins: values?.find((data) => data.nutritent_name === 'Protein')?.consumed_value || 0,
        fats: values?.find((data) => data.nutritent_name === 'Fat')?.consumed_value || 0,
      }));
    }
  }, [MealPass]);

  const formattedNutrients = (nutritionProfile) => {
    if (
      !nutritionProfile ||
      nutritionProfile.length === 0 ||
      nutritionProfile.every((nutrient) => !nutrient.nutritent_name)
    ) {
      return (
        <>
          <span>Calories- 0kcal, Carbs - 0g, Proteins- 0g, Fat- 0g</span>
        </>
      );
    } else {
      return nutritionProfile.map((nutrient, index) => {
        const { nutritent_name, consumed_value, unit } = nutrient;
        const formattedValue = consumed_value || 0;
        const displayUnit = unit ? ` ${unit}` : '';

        return (
          <span key={index}>
            {nutritent_name} - {formattedValue}
            {displayUnit}
            {index !== nutritionProfile.length - 1 && ' ,'}
          </span>
        );
      });
    }
  };

  return (
    <>
      <div>
        <Transition appear show={OpenSave} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setOpenSave(false);
              setSaveData({
                ...SaveData,
                meal_type: '',
                meal_time: '',
                recipe_id: '',
                serving: '',
                recipe_measurement: '',
                add_on_ids: [],
                add_on_serving: [],
                add_on_recipe_measurement: [],
                diet_preferences_ids: [],
                cusine_preference_ids: [],
                locality_ids: [],
                allergy_ids: [],
                calories: '',
                proteins: '',
                fats: '',
                carbs: '',
              });
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center  text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-[80%] transform overflow-hidden  rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="div"
                      className="2xl:px-5 xl:px-4 lg:px-3 font-medium justify-between  border-b items-center flex  text-gray-900"
                    >
                      <div></div>
                      <div className=" text-center flex  text-shades-2 2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] p-5">
                        Save Meal As Template
                      </div>

                      <div className="flex">
                        <div
                          onClick={() => {
                            setOpenSave(false);
                            setSaveData({
                              ...SaveData,
                              meal_type: '',
                              meal_time: '',
                              recipe_id: '',
                              serving: '',
                              recipe_measurement: '',
                              add_on_ids: [],
                              add_on_serving: [],
                              add_on_recipe_measurement: [],
                              diet_preferences_ids: [],
                              cusine_preference_ids: [],
                              locality_ids: [],
                              allergy_ids: [],
                              calories: '',
                              proteins: '',
                              fats: '',
                              carbs: '',
                            });
                          }}
                          className=" cursor-pointer"
                        >
                          <Close />
                        </div>
                      </div>
                    </Dialog.Title>
                    <div className="2xl:p-5 xl:p-[15.47px] lg:p-[12.89px] max-h-[60vh] overflow-auto">
                      <div className="flex flex-col">
                        <div className="text-[16px] text-[#666666] mb-3">
                          <span className=" border-b border-brand-color">Meal Details</span>
                        </div>
                        <div className="text-[15px]">
                          <div className="text-[17px]">
                            {MealPass.meal_type} - {MealPass.meal_time}
                          </div>
                          <div className="flex text-[14px]">
                            {formattedNutrients(MealPass.nutrition_profile)}
                          </div>
                          <div className="text-[14px]">
                            {MealPass?.suggested_recipes?.map((recipe, index) => (
                              <li key={index}>
                                <span className="font-semibold">{recipe.recipe_name}</span> -{' '}
                                {recipe.serving} {recipe.measurement}
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col 2xl:mt-5 xl:mt-[15.47px] lg:mt-[12.89px]">
                        <div className="text-[16px] text-[#666666] mb-3">
                          <span className=" border-b border-brand-color">Diet Preferences</span>
                        </div>
                        <div className="flex text-[#B6B6B6] flex-wrap text-[12px]">
                          {Templates?.diet_preferences.map((details, index) => (
                            <div
                              className={`border rounded ${
                                SaveData.diet_preferences_ids.some((match) => match === details.id)
                                  ? 'border-[#BD9528] text-[#BD9528]'
                                  : ''
                              } cursor-pointer ml-[10px] mb-[5px] p-1`}
                              onClick={() => {
                                const mainId = Number(details.id);
                                const isItemInPreferences = SaveData.diet_preferences_ids.some(
                                  (match) => match === mainId
                                );
                                if (isItemInPreferences) {
                                  const updatedPreferences = SaveData.diet_preferences_ids.filter(
                                    (match) => match !== mainId
                                  );
                                  setSaveData({
                                    ...SaveData,
                                    diet_preferences_ids: updatedPreferences,
                                  });
                                } else {
                                  setSaveData({
                                    ...SaveData,
                                    diet_preferences_ids: [
                                      ...SaveData.diet_preferences_ids,
                                      mainId,
                                    ],
                                  });
                                }
                              }}
                            >
                              {details.name}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col 2xl:mt-5 xl:mt-[15.47px] lg:mt-[12.89px]">
                        <div className="text-[16px] text-[#666666] mb-3">
                          <span className=" border-b border-brand-color">Cuisine Preferences</span>
                        </div>
                        <div className="flex text-[#B6B6B6] flex-wrap text-[12px]">
                          {Templates?.cuisine_preferences.map((details, index) => (
                            <div
                              className={`border rounded ${
                                SaveData.cusine_preference_ids.some((match) => match === details.id)
                                  ? 'border-[#BD9528] text-[#BD9528]'
                                  : ''
                              } cursor-pointer ml-[10px] mb-[5px] p-1`}
                              onClick={() => {
                                const mainId = Number(details.id);
                                const isItemInPreferences = SaveData.cusine_preference_ids.some(
                                  (match) => match === mainId
                                );
                                if (isItemInPreferences) {
                                  const updatedPreferences = SaveData.cusine_preference_ids.filter(
                                    (match) => match !== mainId
                                  );
                                  setSaveData({
                                    ...SaveData,
                                    cusine_preference_ids: updatedPreferences,
                                  });
                                } else {
                                  setSaveData({
                                    ...SaveData,
                                    cusine_preference_ids: [
                                      ...SaveData.cusine_preference_ids,
                                      mainId,
                                    ],
                                  });
                                }
                              }}
                            >
                              {details.name}
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <div className="flex flex-col 2xl:mt-5 xl:mt-[15.47px] lg:mt-[12.89px]">
                        <div className="text-[16px] text-[#666666] mb-3">
                          <span className=" border-b border-brand-color">Locality</span>
                        </div>
                        <div className="flex text-[#B6B6B6] flex-wrap text-[12px]">
                          {Templates?.locality.map((details, index) => (
                            <div
                              className={`border rounded ${
                                SaveData.locality_ids.some((match) => match === details.id)
                                  ? 'border-[#BD9528] text-[#BD9528]'
                                  : ''
                              } cursor-pointer ml-[10px] mb-[5px] p-1`}
                              onClick={() => {
                                const mainId = Number(details.id);
                                const isItemInPreferences = SaveData.locality_ids.some(
                                  (match) => match === mainId
                                );
                                if (isItemInPreferences) {
                                  const updatedPreferences = SaveData.locality_ids.filter(
                                    (match) => match !== mainId
                                  );
                                  setSaveData({
                                    ...SaveData,
                                    locality_ids: updatedPreferences,
                                  });
                                } else {
                                  setSaveData({
                                    ...SaveData,
                                    locality_ids: [...SaveData.locality_ids, mainId],
                                  });
                                }
                              }}
                            >
                              {details.name}
                            </div>
                          ))}
                        </div>
                      </div> */}
                      <div className="flex flex-col 2xl:mt-5 xl:mt-[15.47px] lg:mt-[12.89px]">
                        <div className="text-[16px] text-[#666666] mb-3">
                          <span className=" border-b border-brand-color">Allergy</span>
                        </div>
                        <div className="flex text-[#B6B6B6] flex-wrap text-[12px]">
                          {Templates?.allergies.map((details, index) => (
                            <div
                              className={`border rounded ${
                                SaveData.allergy_ids.some((match) => match === details.id)
                                  ? 'border-[#BD9528] text-[#BD9528]'
                                  : ''
                              } cursor-pointer ml-[10px] mb-[5px] p-1`}
                              onClick={() => {
                                const mainId = Number(details.id);
                                const isItemInPreferences = SaveData.allergy_ids.some(
                                  (match) => match === mainId
                                );
                                if (isItemInPreferences) {
                                  const updatedPreferences = SaveData.allergy_ids.filter(
                                    (match) => match !== mainId
                                  );
                                  setSaveData({
                                    ...SaveData,
                                    allergy_ids: updatedPreferences,
                                  });
                                } else {
                                  setSaveData({
                                    ...SaveData,
                                    allergy_ids: [...SaveData.allergy_ids, mainId],
                                  });
                                }
                              }}
                            >
                              {details.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center 2xl:py-5 xl:py-[15.47px] border-t lg:py-[12.89px]">
                      <Button
                        type="button"
                        className=" rounded-small border border-transparent   bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-medium text-white"
                        onClick={saveMeal}
                      >
                        Save
                      </Button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </>
  );
};

import { useEffect, useState, Fragment, useRef } from 'react';
import { getToken } from '../../../../Utils/Authenticate';
import axios from 'axios';
import { ReactComponent as Search } from '../../../../assets/images/search.svg';
import { Combobox, Transition } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import Popper from '@mui/material/Popper';

const MeasurementDropDown = ({ MeasurementData, setQuery, SelectedValue, setSelectedValue }) => {
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let { userId } = useParams();
  // useEffect(() => {
  //   setSelectedValue(setData);
  // }, [setData]);

  const comboboxRef = useRef(null);

  const openDirection = () => {
    if (comboboxRef.current) {
      const comboboxRect = comboboxRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceAbove = comboboxRect.top;
      const spaceBelow = windowHeight - comboboxRect.bottom;

      const threshold = 200;

      if (spaceBelow < threshold && spaceAbove > spaceBelow) {
        return 'top';
      } else {
        return 'bottom';
      }
    }
  };

  let accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  // const updateMeasurement = () =>{
  //   axios
  // .patch(
  //   `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/ingredient/measurement/${}`,
  //   {
  //     name:SelectedValue.name,
  //     unit:SelectedValue.unit,
  //     value:SelectedValue.value,
  //   },
  //   {
  //     headers: headers,
  //   }
  // )}

  return (
    <div className="w-full 2xl:mr-[20px] xl:mr-[16px] lg:mr-[12px]">
      <Combobox
        value={SelectedValue}
        ref={comboboxRef}
        open={open}
        onChange={(data) => {
          setOpen(!open);
          setSelectedValue(data);
          setQuery('');
        }}
      >
        <div className="relative">
          <div
            className="relative flex w-full items-center text-[#FFFFFF] bg-[#FFFFFF] font-normal border border-[#E6E6E6] rounded-[10px] px-[10px] py-[8px]"
            style={{
              boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
            }}
          >
            <Search className="my-[4px] mr-[4px] 2xl:h-[18px] 2xl:w-[18px] xl:h-[16px] xl:w-[16px] lg:h-[14px] lg:w-[14px]" />
            <Combobox.Input
              displayValue={(data) => SelectedValue?.name}
              onChange={(event) => {
                setQuery(event.target.value);
                setSelectedValue('');
              }}
              placeholder={'Search'}
              className="w-full outline-none 2xl:text-[16px] xl:text-[14px] lg:text-[12px] text-[#666666]"
              autocomplete="off"
            />
            {/* <Combobox.Button
              onClick={(e) => {
                setOpen(!open);
                setAnchorEl(e.currentTarget);
                setOpenPop(!openPop);
              }}
              className="absolute top-0 bottom-0 right-3"
            >
              <UpArray
                className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
              />
            </Combobox.Button> */}
          </div>
          <Transition
            as={Combobox.Options}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            style={{ borderRadius: '0px 0px 10px 10px', boxShadow: '4px 4px 8px -2px #0000001F' }}
            className={`absolute max-h-60 w-full overflow-auto font-normal bg-white 2xl:text-[16px] xl:text-[14px] lg:text-[12px]`}
            afterLeave={() => {
              setOpen(!open);
              setQuery('');
            }}
          >
            <>
              {MeasurementData?.length === 0 ? (
                <div className="relative cursor-default select-none 2xl:py-[10px] xl:py-[8px] lg:py-[6px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] text-gray-700 ">
                  Nothing found.
                </div>
              ) : (
                MeasurementData?.map((recipe_name, id) => (
                  <Combobox.Option
                    key={id}
                    className={({ active }) =>
                      `  select-none cursor-pointer 2xl:py-[10px] xl:py-[8px] lg:py-[6px] 2xl:px-[20px] xl:px-[18px] lg:px-[16px] border-b  ${
                        active ? 'bg-[#D2A62C] text-[#FFFFFF]' : 'text-body-color-2'
                      }`
                    }
                    value={recipe_name}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate `}>{recipe_name.name}</span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <div className=" lg:h-4 lg:w-3 md:w-1 md:h-2" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default MeasurementDropDown;

import React from 'react';

const RacipeHeader = ({ heading, className }) => {
  return (
    <>
      <div className="flex text-start border-b w-full  ">
        <h1 className={`text-body-color-2 font-Regular 2xl:text-2xl xl:text-xl lg:text-base 2xl:p-[20px] xl:p-[15px] lg:p-[13px]    ${className}`}>{heading}</h1>
      </div>
    </>
  );
};

export default RacipeHeader;

import React from 'react';
import { ReactComponent as Swap } from '../../assets/images/swap_vert.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import RacipeHeader from './RacipeHeader';
import SortIcon from '../../assets/images/des.png';
import { useState } from 'react';

const defaultData = [
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
  {
    name: 'name Name',
    value: '2.5',
  },
];

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor((row) => {
    return {
      name: row.name,
      unit: row.unit
    }
  }, {
    id: 'name',
    cell: (info) => info.getValue().name + "(" + info.getValue().unit + ")",
    header: () => (
      <div>
        <span> Nutrition</span>
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.value, {
    id: 'value',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => (
      <div>
        <span>Value</span>
      </div>
    ),
  }),
];

const NutritionProfile = ({ nutData }) => {
  const [data, setData] = React.useState(() => [...nutData]);

  const [sorting, setSorting] = React.useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <RacipeHeader heading={'Nutrition Profile'} />
      <div className="2xl:mt-[20px] xl:mt-[15px] lg:mt-[13px]">
        <div className="">
          <table className="min-w-[-webkit-fill-available]">
            <thead className=" bg-tint-1/20 top-0 sticky">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className='flex'>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="w-1/2 first-of-type:border-l  2xl:py-[9px] 2xl:px-5 xl:py-[6px] xl:px-4 lg:py-[3px] lg:px-3 text-left text-body-color-1 border-r last-of-type:border-r-0 font-Medium  2xl:text-SmallBodyDisplayText xl:text-base lg:text-sm"
                    >

                      <div className="flex justify-between items-center 2xl:gap-10 2xl:px-5  ">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header)}
                        <div className='cursor-pointer'>
                          {{
                            asc: (
                              <img
                                src={SortIcon}
                                // width={20}
                                // height={20}
                                className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                style={{ transform: 'rotate(180deg)' }}
                                alt="SortIcon"
                              />
                            ),
                            desc: (
                              <img
                                src={SortIcon}
                                className=" lg:w-[16px] lg:h-[16px] md:h-3 md:w-3"
                                alt="SortIcon"
                              />
                            ),
                            false: (
                              <Swap className="  lg:w-[16px] lg:h-[16px] md:h-3 md:w-3" />
                            )
                          }[header.column.getIsSorted()]}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="2xl:h-[580px] xl:h-[470px] lg:h-[385px] overflow-auto block">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className='flex  clienttable_row  mt-[4px] even:bg-secondary-3/20'>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="w-1/2 first-of-type:border-l border-r 2xl:px-5 2xl:py-3 xl:px-4 xl:py-2 lg:px-3 lg:py-2  text-left text-body-color-2  font-Regular 2xl:text-SmallerBodyDisplayText xl:text-sm lg:text-xs"
                    >
                      <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default NutritionProfile;

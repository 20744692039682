import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const setToken = function (token) {
  localStorage.setItem('auth_token', token);
};

export const getToken = function () {
  return localStorage.getItem('auth_token') || '';
};

export const verifyToken = async function (token) {
  if (token) {
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/user/tokenCheck`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      let { data } = await axios.request(config);
      if (data.code) {
        if (data.code === '200') {
          setToken(token);
          return true;
        } else {
          setToken('');
          return false;
        }
      }
    } catch (err) {
      if (err.response.data.error.name === 'TokenExpiredError') {
        window.location.href = process.env.REACT_APP_LOGIN_PAGE;
      }
      setToken('');
      return false;
    }
  }
};

import { Time } from '@internationalized/date';
import axios from 'axios';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);
axios.interceptors.response.use(
  function (response) {
    // do something with the response data
    if (response.status === 401) {
      window.location.href = process.env.REACT_APP_LOGIN_PAGE;
      return;
    }
    // you must return the response object or else you won't have access to it in the then() or async/await
    return response;
  },
  function (error) {
    // do something when a response error occurs
    return Promise.reject(error);
  }
);

export function convertTime(timeString) {
  const [time, amPm] = timeString.split(' ');
  const [hour, minute] = time.split(':').map(Number);

  let convertedHour = hour;
  if (amPm === 'PM' && hour !== 12) {
    convertedHour += 12;
  } else if (amPm === 'AM' && hour === 12) {
    convertedHour = 0;
  }

  return new Time(convertedHour, minute);
}

export const timeAgoInstr = (date) => {
  const timeAgo = new TimeAgo('en-IN');
  const dateUtc = new Date(date);
  return timeAgo.format(dateUtc);
};

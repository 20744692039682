import React, { Fragment, useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { ReactComponent as Correct } from '../../assets/images/correct.svg';
import { ReactComponent as Incorrect } from '../../assets/images/incorrect.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuccessMessage } from './SuccessMessage';
import { getToken } from '../../Utils/Authenticate';
import { ErrorMessages } from './ErrorMessages';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 434,
    padding: '20px !important',
    borderRadius: '10px',
    fontFamily: 'Poppins',
    boxShadow: '0px 0px 23.3px 0px rgba(32, 31, 31, 0.15)',
  },
  '& .MuiTooltip-arrow': {
    color: '#FFF',
  },
}));

export const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = getToken();
  const [NewPassword, setNewPassword] = useState('');
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [AuthToken, setAuthToken] = useState('');
  const [PasswordVisible, setPasswordVisible] = useState(false);
  const [PasswordVisibleIcon, setPasswordVisibleIcon] = useState(false);
  const [PopOver, setPopOver] = useState(false);
  const [SamePassword, setSamePassword] = useState(false);
  const [OpenSuccess, setOpenSuccess] = useState(false);
  const [OpenError, setOpenError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [Validation, setValidation] = useState({
    Length: false,
    Special: false,
    Case: false,
    Digit: false,
  });

  useEffect(() => {
    if (token) {
      navigate('/customer/dashboard');
    }
  }, [token]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const auth = searchParams.get('auth');
    setAuthToken(auth);
    if (!auth) {
      navigate('/customer/dashboard');
    }
  }, [location.search]);

  const checkPasswordStrength = (value) => {
    const regexWeak = /^(?=.*[a-zA-Z])/;
    const regexMedium = /^(?=.*\d)(?=.*[a-zA-Z])/;
    const regexStrong = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*])/;

    if (regexStrong.test(value)) {
      return 'Strong';
    } else if (regexMedium.test(value)) {
      return 'Medium';
    } else if (regexWeak.test(value)) {
      return 'Weak';
    } else {
      return '';
    }
  };

  const checkPassword = (value) => {
    setPopOver(true);

    const newPasswordValid = {
      Length: value.length >= 8,
      Case: /[a-z]/.test(value) && /[A-Z]/.test(value),
      Digit: /\d/.test(value),
      Special: /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(value),
    };

    setValidation(newPasswordValid);

    const isValid = Object.values(newPasswordValid).every((condition) => condition);
    setPopOver(!isValid);

    return isValid;
  };

  const handleInputChange = (e, isConfirmPassword = false) => {
    const value = e.target.value.trim();
    if (isConfirmPassword) {
      setConfirmPassword(value);
      setSamePassword(checkConfirmPasswordMatch(value));
    } else {
      setNewPassword(value);
      checkPassword(value);
    }
  };

  const checkConfirmPasswordMatch = (confirmPassword) => {
    return confirmPassword === NewPassword;
  };

  const passwordStrength = checkPasswordStrength(NewPassword);

  const resetPassword = () => {
    const body = {
      password: ConfirmPassword,
    };
    const headers = {
      Authorization: `Bearer ${AuthToken}`,
    };
    const isPasswordValid = checkPassword(NewPassword);
    const isConfirmPasswordValid = checkConfirmPasswordMatch(ConfirmPassword);

    if (NewPassword || ConfirmPassword) {
      if (NewPassword) {
        if (ConfirmPassword) {
          if (isPasswordValid) {
            if (isConfirmPasswordValid) {
              axios
                .post(`${process.env.REACT_APP_BASE_URL}/admin/user/reset_password`, body, {
                  headers: headers,
                })
                .then((response) => {
                  const userDetails = response.data;
                  setNewPassword('');
                  setConfirmPassword('');
                  setOpenSuccess(true);
                })
                .catch((error) => {
                  const userRetry = error.response.data;
                  setOpenError(true);
                  setErrorMessage(userRetry.message);
                });
            } else {
              setOpenError(true);
              setErrorMessage('New Password and Confirm Password are Not Matching');
            }
          } else {
            setOpenError(true);
            setErrorMessage('All Password Rules Should Match');
          }
        } else {
          setOpenError(true);
          setErrorMessage('Enter The Confirm Password');
        }
      } else {
        setOpenError(true);
        setErrorMessage('Enter The New Password');
      }
    } else {
      setOpenError(true);
      setErrorMessage('Enter Password.');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-[#FEFBF3]">
      <div
        className="flex items-center justify-center bg-[#FFF] w-[70%] rounded-[30px]"
        style={{ boxShadow: '0px 0px 193.3px 40px rgba(185, 185, 185, 0.25)' }}
      >
        <div className="flex justify-center items-center flex-col">
          <br />
          <Logo className="xl:w-[52px] xl:h-[50px]" />
          <span className="font-[600] 2xl:text-[32px] xl:text-[25px] lg:">unlock.fit</span>
          <br />
          <span className="font-[600] 2xl:text-[30px] xl:text-[22px]">Enter New Password</span>
          <br />
          <br />
          <form
            className="flex flex-col justify-start 2xl:w-[125%] xl:w-[135%]"
            onSubmit={(e) => {
              e.preventDefault();
              resetPassword();
            }}
          >
            <div className="flex relative flex-col">
              <span className="text-[#9F9F9F]">New Password</span>
              <div className="relative flex items-center">
                <HtmlTooltip
                  title={
                    <Fragment>
                      <div className="text-[13px]">
                        <div
                          className={`flex items-center mb-[2px] ${
                            Validation.Length ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                          }`}
                        >
                          {Validation.Length ? <Correct /> : <Incorrect />}
                          <span className="ml-2">Passwords must be at least 8 characters long</span>
                        </div>
                        <div
                          className={`flex items-center mb-[2px] ${
                            Validation.Case ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                          }`}
                        >
                          {Validation.Case ? <Correct /> : <Incorrect />}
                          <span className="ml-2">
                            Use a combination of uppercase and lowercase letters
                          </span>
                        </div>
                        <div
                          className={`flex items-center mb-[2px] ${
                            Validation.Digit ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                          }`}
                        >
                          {Validation.Digit ? <Correct /> : <Incorrect />}
                          <span className="ml-2">Include at least one numeric digit (0-9)</span>
                        </div>
                        <div
                          className={`flex items-center mb-[2px] ${
                            Validation.Special ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                          }`}
                        >
                          {Validation.Special ? <Correct /> : <Incorrect />}
                          <span className="ml-2">
                            Include at least one special character (e.g., !, @, #, $, %)
                          </span>
                        </div>
                      </div>
                    </Fragment>
                  }
                  open={PopOver && NewPassword.length > 0}
                  placement="right-start"
                  arrow
                >
                  <input
                    className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                    type={PasswordVisibleIcon ? 'text' : 'password'}
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                    autocomplete="new-password"
                    value={NewPassword}
                    onChange={(e) => handleInputChange(e)}
                  />
                </HtmlTooltip>
                <div
                  className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-[5px]"
                  onClick={() => {
                    setPasswordVisibleIcon(!PasswordVisibleIcon);
                  }}
                >
                  {!PasswordVisibleIcon ? (
                    <VisibilityIcon className="text-[#9F9F9F]" />
                  ) : (
                    <VisibilityOffIcon className="text-[#9F9F9F]" />
                  )}
                </div>
              </div>
              <div
                className={`flex items-center w-full justify-between mt-2 transition-all ease-in-out overflow-hidden ${
                  NewPassword ? 'opacity-100 max-h-[200px]' : 'opacity-0 max-h-0'
                }`}
              >
                <div
                  className={`w-[20%] text-[14px] ${
                    passwordStrength === 'Weak'
                      ? 'text-[#FF1C1C]'
                      : passwordStrength === 'Medium'
                      ? 'text-yellow-500'
                      : passwordStrength === 'Strong'
                      ? 'text-[#63B72F]'
                      : ''
                  }`}
                >
                  {passwordStrength}
                </div>
                <div className="w-[80%] flex">
                  <div
                    className={`border mx-[3px]
                      ${
                        passwordStrength === 'Weak'
                          ? 'border-[#FF1C1C] w-full h-full rounded-[5px]'
                          : passwordStrength === 'Medium'
                          ? 'border-yellow-500 w-full h-full rounded-[5px]'
                          : passwordStrength === 'Strong'
                          ? 'border-[#63B72F] w-full h-full rounded-[5px]'
                          : 'border-[#D1D1D1] w-full h-full rounded-[5px]'
                      }
                    `}
                  />
                  <div
                    className={`border mx-[3px]
										${
                      passwordStrength === 'Medium'
                        ? 'border-yellow-500 w-full h-full rounded-[5px]'
                        : passwordStrength === 'Strong'
                        ? 'border-[#63B72F] w-full h-full rounded-[5px]'
                        : 'border-[#D1D1D1] w-full h-full rounded-[5px]'
                    }
											`}
                  />
                  <div
                    className={`border mx-[3px]
										${
                      passwordStrength === 'Strong'
                        ? 'border-[#63B72F] w-full h-full rounded-[5px]'
                        : 'border-[#D1D1D1] w-full h-full rounded-[5px]'
                    }
											`}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="flex relative flex-col">
              <span className="text-[#9F9F9F]">Confirm Password</span>
              <div className="relative flex items-center">
                <HtmlTooltip
                  title={
                    <Fragment>
                      <div className="text-[13px]">
                        <div
                          className={`flex items-center mb-[2px] ${
                            SamePassword ? 'text-[#63B72F]' : 'text-[#FF1C1C]'
                          }`}
                        >
                          {SamePassword ? <Correct /> : <Incorrect />}
                          <span className="ml-2">
                            New Password and Confirm Password are Not Matching
                          </span>
                        </div>
                      </div>
                    </Fragment>
                  }
                  open={!SamePassword && ConfirmPassword.length > 0}
                  placement="right-start"
                  arrow
                >
                  <input
                    className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                    type={PasswordVisible ? 'text' : 'password'}
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                    autocomplete="new-password"
                    value={ConfirmPassword}
                    onChange={(e) => handleInputChange(e, true)}
                  />
                </HtmlTooltip>
                <div
                  className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-[5px]"
                  onClick={() => {
                    setPasswordVisible(!PasswordVisible);
                  }}
                >
                  {!PasswordVisible ? (
                    <VisibilityIcon className="text-[#9F9F9F]" />
                  ) : (
                    <VisibilityOffIcon className="text-[#9F9F9F]" />
                  )}
                </div>
              </div>
            </div>
            <br />
            <br />
            <button
              type="submit"
              className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
            >
              Change Password
            </button>
          </form>
          <br />
          <br />
          <br />
        </div>
        <SuccessMessage OpenSuccess={OpenSuccess} setOpenSuccess={setOpenSuccess} type="reset" />
        <ErrorMessages
          OpenError={OpenError}
          setOpenError={setOpenError}
          ErrorMessage={ErrorMessage}
          setErrorMessage={setErrorMessage}
        />
      </div>
    </div>
  );
};

import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import Button from '../common/Button';
import TempletDisclose from './TempletDisclose';
import SelectParameterDropdown from '../common/SelectParameterDropdown';
import { useState } from 'react';
import DatePickerMUI from '../common/DatePickerMUI';
import { useDispatch, useSelector } from 'react-redux';
import { addNew, setAddValue, setSelectedDate } from '../../Store/ProgressAnalysisSlice';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const PrgAnlEditValue = ({ isOpen, closeModal, ApiTrigger, setApiTrigger }) => {
  const val = useSelector((state) => state.ProgressAnalysis.addValue);
  const SelectID = useSelector((state) => state.ProgressAnalysis.selectedParameter);
  const selectedIdxs = useSelector((state) => state.ProgressAnalysis.clickedIdxs);
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  // useEffect(() => {
  //   if (SelectID && SelectID?.id === 1 && (val.length > 3 || isNaN(val) || parseFloat(val) <= 0)) {
  //     setError('In valid value');
  //   } else {
  //     setError('');
  //   }
  // }, [val, SelectID]);
  const handleOnChange = (e) => {
    dispatch(setAddValue(e.target.value));
  };
  const date = useSelector((state) => state.ProgressAnalysis.selectedDate);
  useEffect(() => {
    if (isOpen) {
      dispatch(setAddValue(''));
    }
  }, [isOpen]);

  const saveValue = (e) => {
    const callBackOnSuccess = () => {
      closeModal();
      dispatch(setSelectedDate(''));
      setApiTrigger(ApiTrigger + 1);
    };
    dispatch(addNew(callBackOnSuccess));
    // setTimeout(() => {
    // }, 2000);
  };
  return (
    <div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg border-b font-medium leading-6 text-gray-900"
                  >
                    <div className="flex justify-between py-4 px-10 items-center">
                      <div></div>
                      <div className="text-shades-2 font-normal text-base">Add Progress Value</div>
                      <div onClick={closeModal} className="2xl:pr-5">
                        <Close className="cursor-pointer 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px] " />
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className=" border-b px-9">
                    <div className="flex text-body-color-2 py-5 text-xs justify-between items-center">
                      <div className="w-1/2">
                        <h1>Update On</h1>
                      </div>
                      {selectedIdxs?.col === -1 ? (
                        <div className="w-1/2 ">
                          <DatePickerMUI disabled={selectedIdxs?.col !== -1} value={date} />
                        </div>
                      ) : (
                        <div className=" w-1/2 text-black text-xs font-medium ">
                          {date.replace(/-/g, '/')}
                        </div>
                      )}
                    </div>
                    <div className="flex text-body-color-2  text-xs justify-between items-center">
                      <div className="w-1/2">
                        <h1>Select Parameter</h1>
                      </div>
                      <div className="w-1/2 border-b border-black">
                        <SelectParameterDropdown isDisabled={selectedIdxs?.col !== -1} />
                      </div>
                    </div>

                    <div className="flex text-body-color-2 py-5 text-xs justify-between items-center">
                      <div className="w-1/2">
                        <h1>Add value</h1>
                      </div>
                      <div className="w-1/2 border-b border-black">
                        <input
                          className=" w-1/2 focus:outline-none py-[10px] px-3 text-xs font-medium text-black "
                          value={val}
                          onChange={handleOnChange}
                        />
                        <span className="text-red-500 text-[10px]">{error}</span>
                      </div>
                    </div>
                  </div>

                  <div className="my-4 flex justify-center">
                    <Button
                      type="button"
                      disabled={error.length > 0}
                      className="inline-flex justify-center rounded-[10px] bg-shades-2 px-4 py-2 text-xs font-normal text-white "
                      onClick={saveValue}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default PrgAnlEditValue;

import { useEffect, useState, useRef } from 'react';
import { ReactComponent as Edit } from '../../../assets/images/editfordash.svg';
import { ReactComponent as Apply } from '../../../assets/images/checkfordash.svg';
import Close from '@mui/icons-material/Close';
import { ReactComponent as DateCal } from '../../../assets/images/calendar.svg';
import { ReactComponent as OpenLog } from '../../../assets/images/view_log.svg';
import { ReactComponent as AddPlan } from '../../../assets/images/add_new_plan.svg';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Popper from '@mui/material/Popper';
import dayjs from 'dayjs';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import AuditTrial from './Audit';
import axios from 'axios';

export default function CustomerInfo({ headers, userId }) {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);

  const customer = permission?.role_permissions?.find(
    (permission) => permission.name === 'Customer Dashboard'
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [Editable, setEditable] = useState(false);
  const [AddingProduct, setAddingProduct] = useState(false);
  const [SelectedDate, setSelectedDate] = useState();
  const [OpenDatePicker, setOpenDatePicker] = useState(false);
  const [EditableItem, setEditableItem] = useState(null);
  const [SelectedItems, setSelectedItems] = useState({
    id: '',
    plan_id: '',
    duration_id: '',
    expire_date: '',
  });
  const [Price, setPrice] = useState('');
  const [AllowExpire, setAllowExpire] = useState(false);
  const [Compare, setCompare] = useState({
    id: '',
    plan_id: '',
    duration_id: '',
    expire_date: '',
  });
  const [PlanDetails, setPlanDetails] = useState([]);
  const [OpenModal, setOpenModal] = useState(false);
  const [userDetails, setuserDetails] = useState({
    userDetail: [],
    familyDetail: [],
    planDetails: [],
    nutrionDetails: [],
    customerActivity: [],
  });
  const [userProfile, setuserProfile] = useState({
    profilepic: '',
    name: '',
    age: '',
    gender: '',
    org: '',
  });
  const fetchUserDetails = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/details/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalData = response.data.data[0];
        const userData = finalData.customer_details;
        const planDetails = finalData.customer_plan_details;
        const nutrionDetail = finalData.nutrition_plan_details.flatMap((plan, index) => [
          { label: `Nutritionist's Name`, data: plan.nutritionist_name },
          { label: `Nutrition Plan Validity`, data: plan.plan_validity },
          { label: `Nutrition Plan Issue Date`, data: plan.issue_date },
        ]);
        const userDetail = [
          { label: 'Customer Id', data: userData.customer_id },
          { label: 'Primary Email Address', data: userData.primary_email },
          { label: 'Secondary Email Address', data: userData.alternative_email },
          { label: 'Phone Number', data: userData.phone },
          { label: 'Current Status', data: userData.current_status },
          { label: 'Address', data: userData.address },
          { label: 'DNA Consent Signed Version', data: userData.dna_consent_signed_version },
          { label: 'DNA Consent Timestamp', data: userData.dna_consent_signed_at },
          { label: 'DNA Consent Device Name', data: userData.dna_consent_device_name },
          {
            label: 'Psychologist Consent Device Name',
            data: userData.consultation_consent_device_name,
          },
          {
            label: 'Psychologist Consent Device Location',
            data: userData.consultation_consent_signed_location,
          },
          {
            label: 'Psychologist Consent Signed At',
            data: userData.consultation_consent_signed_at,
          },
          { label: 'Psychologist Consent Version', data: userData.consultation_consent_version },
          { label: 'App Signup Date', data: userData.app_signup_date },
        ];
        const familyDetails = finalData.family_details.flatMap((member, index) => [
          {
            label: `Family Member ${index + 1} Name`,
            data: member.name,
            id: member.id,
          },
          {
            label: `Family Member ${index + 1} Customer ID`,
            data: member.customer_id,
            id: member.id,
          },
        ]);
        const customerAct = finalData.customer_activities.flatMap((activity, index) => [
          { label: 'Customer Testimonial', data: activity.customer_testimonials },
          { label: 'Testimonial Date', data: activity.customer_testimonial_date },
          { label: 'Customer Feedback', data: activity.customer_feedback },
          { label: 'Customer Feedback Date', data: activity.customer_feedback_date },
          { label: 'Next Step', data: activity.next_step },
        ]);

        setuserProfile({
          ...userProfile,
          profilepic: userData.profile_pic,
          name: userData.name,
          age: userData.age,
          gender: userData.gender,
          org: userData.org_name,
          hasprof: userData.has_profile,
        });
        setuserDetails({
          ...userDetails,
          userDetail: userDetail,
          familyDetail: familyDetails,
          planDetails: planDetails,
          nutrionDetails: nutrionDetail,
          customerActivity: customerAct,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchPlans = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/plans/${userId}`, {
        headers: headers,
      })
      .then((response) => {
        const finalPlan = response.data.data;
        setPlanDetails(finalPlan);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const part = (inputDate) => {
    const parts = inputDate.split(' ');

    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];

      const months = {
        January: '01',
        February: '02',
        March: '03',
        April: '04',
        May: '05',
        June: '06',
        July: '07',
        August: '08',
        September: '09',
        October: '10',
        November: '11',
        December: '12',
      };

      const formatted = `${year}-${months[month]}-${day}`;
      return dayjs(formatted);
    } else {
      return null;
    }
  };

  const addPlan = () => {
    const body = {
      user_id: userId,
      plan_id: SelectedItems.plan_id,
      duration_id: SelectedItems.duration_id,
      expire_date: SelectedItems.expire_date,
      plan_price: Price,
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/subscription/add`, body, {
        headers: headers,
      })
      .then((response) => {
        console.log(response);
        const finalSuccess = response.data;
        setEditable(false);
        setAddingProduct(false);
        toast.success(finalSuccess.message);
        setSelectedItems({
          ...SelectedItems,
          id: '',
          plan_id: '',
          expire_date: '',
          duration_id: '',
        });
        fetchUserDetails();
      })
      .catch((error) => {
        const finalError = error.response.data;
        toast.error(finalError.message);
      });
  };

  const updatePlan = () => {
    const body = {
      id: SelectedItems.id,
      plan_id: SelectedItems.plan_id,
      duration_id: SelectedItems.duration_id,
      expire_date: SelectedItems.expire_date,
      plan_price: Price,
    };
    axios
      .patch(`${process.env.REACT_APP_BASE_URL}/admin/v2/customer/plans/${userId}`, body, {
        headers: headers,
      })
      .then((response) => {
        const finalSuccess = response.data;
        console.log(response);
        setEditable(false);
        toast.success(finalSuccess.message);
        setSelectedItems({
          ...SelectedItems,
          id: '',
          plan_id: '',
          expire_date: '',
          duration_id: '',
        });
        fetchUserDetails();
      });
  };

  const Durations = PlanDetails?.filter((details) => details.plan_id === SelectedItems?.plan_id)[0]
    ?.durations;
  const hasDuration = PlanDetails?.filter(
    (details) => details.plan_id === SelectedItems?.plan_id
  )[0]?.has_duration;

  useEffect(() => {
    fetchUserDetails();
    fetchPlans();
  }, [userId]);

  const uniqueStyles = `
  li:nth-child(odd) {
    background: #e6e6e633;
  }
`;

  const formattedDate = dayjs(SelectedDate).format('DD MMMM YYYY');
  const todayDate = dayjs().format('DD MMMM YYYY');

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div className="w-full flex justify-between">
      <style>{uniqueStyles}</style>
      <div className="w-[49.5%]">
        <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
          <div className="items-center flex  border-b">
            <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] p-[20px]">
              Customer Details
            </div>
          </div>
          <div className="flex justify-center items-center flex-col mt-[20px] mb-[44px]">
            {userProfile.hasprof ? (
              <img
                src={userProfile.profilepic}
                alt="test"
                className="w-[100px] h-[100px] rounded-[50%] border-[2px] border-[#D2A62C]"
              />
            ) : (
              <div className="flex justify-center items-center text-[35px] w-[100px] h-[100px] rounded-[50%] border-[2px] border-[#D2A62C]">
                {userProfile.name.charAt(0).toUpperCase()}
              </div>
            )}
            {Object.values(userProfile).some((value) => value !== '') ? (
              <div className="text-[20px]">
                {userProfile.name}, {userProfile.age} {userProfile.gender}
              </div>
            ) : (
              <Skeleton width={150} height={25} baseColor="#e9d39633"></Skeleton>
            )}
            {Object.values(userProfile).some((value) => value !== '') ? (
              <div className="text-[12px] text-[#666666]">{userProfile.org}</div>
            ) : (
              <Skeleton width={100} height={25} baseColor="#e9d39633"></Skeleton>
            )}
          </div>
          <div>
            <ul className="w-full">
              {userDetails.userDetail.length !== 0
                ? userDetails.userDetail.map((data, index) => (
                    <li key={index} className={`py-[8px] px-[20px] unique`}>
                      <div className="text-[12px] text-[#666666]">{data.label}</div>
                      <div className="text-[16px]">{data.data}</div>
                    </li>
                  ))
                : Array.from({ length: 5 }).map((_, index) => (
                    <li key={index} className="skeleton-loader">
                      <Skeleton className="ml-[15px]" width="20%" baseColor="#e9d39633"></Skeleton>
                      <Skeleton className="ml-[15px]" width="80%" baseColor="#e9d39633"></Skeleton>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        {userDetails.familyDetail.length !== 0 && (
          <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
            <div className="items-center flex  border-b">
              <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] p-[20px]">
                Family Details
              </div>
            </div>
            <div style={{ maxHeight: '350px', overflowY: 'scroll', scrollSnapType: 'y mandatory' }}>
              <ul className="w-full">
                {userDetails.familyDetail.length !== 0
                  ? userDetails.familyDetail?.map((data, index) => (
                      <li
                        onClick={() => {
                          navigate(`/customerDetails/${data.id}`);
                        }}
                        style={{
                          scrollSnapAlign: index % 6 === 0 ? 'start' : 'none',
                        }}
                        className={`${
                          index % 2 === 0 ? 'bg-[#E6E6E633]' : ''
                        } py-[8px] cursor-pointer px-[20px] unique`}
                      >
                        <div className="text-[12px] text-[#666666]">{data.label}</div>
                        <div className="text-[16px]">{data.data}</div>
                      </li>
                    ))
                  : Array.from({ length: 6 }).map((_, index) => (
                      <li key={index} className="skeleton-loader">
                        <Skeleton
                          className="ml-[15px]"
                          width="20%"
                          baseColor="#e9d39633"
                        ></Skeleton>
                        <Skeleton
                          className="ml-[15px]"
                          width="80%"
                          baseColor="#e9d39633"
                        ></Skeleton>
                      </li>
                    ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="w-[49.5%]">
        <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
          <div className="items-center flex justify-between border-b">
            <div className="flex items-center justify-center">
              <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] p-[20px]">
                Customer Plan Details
              </div>
              <OpenLog
                onClick={() => {
                  setOpenModal(true);
                }}
                className="cursor-pointer"
              />
            </div>
            {!Editable && customer?.can_create && (
              <AddPlan
                className="mr-[20px] cursor-pointer"
                onClick={() => {
                  if (userDetails.planDetails.every((plan) => plan.id === 0)) {
                    setEditable(true);
                    setEditableItem(0);
                  } else {
                    setEditable(true);
                    setAddingProduct(true);
                    setuserDetails((prevDetails) => ({
                      ...prevDetails,
                      planDetails: [
                        ...prevDetails.planDetails,
                        {
                          id: 0,
                          plan_id: 0,
                          plan_name: '',
                          plan_duration: '',
                          duration_id: null,
                          plan_start: '',
                          plan_end: '',
                          plan_status: '',
                          plan_description: '',
                        },
                      ],
                    }));
                    setEditableItem(0);
                  }
                }}
              />
            )}
          </div>
          <div style={{ maxHeight: '310px', overflowY: 'scroll', scrollSnapType: 'y mandatory' }}>
            <ul className="w-full">
              {userDetails.planDetails.length !== 0
                ? userDetails.planDetails
                    ?.sort((a, b) => {
                      if (a.id === 0) return -1;
                      if (b.id === 0) return 1;
                      return a.id - b.id;
                    })
                    .map((data, index) =>
                      Editable && EditableItem === data.id ? (
                        <div
                          style={{
                            scrollSnapAlign: 'start',
                          }}
                        >
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="flex justify-between">
                              <div className="flex flex-col">
                                <div className="text-[12px] text-[#666666]">Plan Name</div>
                                <div className="flex text-[#B6B6B6] flex-wrap text-[12px]">
                                  {PlanDetails.map((details, index) => (
                                    <div
                                      className={`border rounded ${
                                        SelectedItems.plan_id === details.plan_id
                                          ? 'border-[#BD9528] text-[#BD9528]'
                                          : ''
                                      } cursor-pointer ml-[10px] mb-[5px] p-1`}
                                      onClick={() => {
                                        const formattedDate = dayjs(todayDate).format('YYYY-MM-DD');
                                        setSelectedItems({
                                          ...SelectedItems,
                                          id: data.id,
                                          plan_id: details.plan_id,
                                          expire_date:
                                            EditableItem === 0
                                              ? formattedDate
                                              : dayjs(data.plan_end).format('YYYY-MM-DD'),
                                          duration_id: null,
                                        });
                                        setPrice(details.price);
                                        setAllowExpire(false);
                                        setAnchorEl(null);
                                        if (EditableItem === 0) {
                                          setSelectedDate(part(todayDate));
                                        } else {
                                          setSelectedDate(part(data.plan_end));
                                        }
                                      }}
                                    >
                                      {details.plan_name}
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="flex">
                                <Apply
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (!AddingProduct) {
                                      if (
                                        JSON.stringify(SelectedItems) === JSON.stringify(Compare)
                                      ) {
                                        setEditable(false);
                                      } else {
                                        updatePlan();
                                      }
                                    } else {
                                      addPlan();
                                    }
                                  }}
                                />
                                <Close
                                  sx={{ color: 'red' }}
                                  className="cursor-pointer"
                                  onClick={() => {
                                    setEditable(false);
                                    setSelectedItems({
                                      ...SelectedItems,
                                      id: '',
                                      plan_id: '',
                                      expire_date: '',
                                      duration_id: '',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="text-[12px] text-[#666666]">Plan Start Date</div>
                            <div className="text-[16px]">
                              {EditableItem === 0 ? todayDate : data.plan_start}
                            </div>
                          </li>
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="text-[12px] text-[#666666]">Plan Duration</div>
                            <div className="flex text-[#B6B6B6] flex-wrap text-[12px]">
                              {hasDuration ? (
                                Durations?.map((details, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`border rounded ${
                                        SelectedItems.duration_id === details.id
                                          ? 'border-[#BD9528] text-[#BD9528]'
                                          : ''
                                      } cursor-pointer ml-[10px] mb-[5px] p-1`}
                                      onClick={() => {
                                        const match = details.duration.match(/\d+/);
                                        const numberOfMonths = match
                                          ? parseInt(match[0], 10)
                                          : null;
                                        const startDate = dayjs(
                                          part(EditableItem === 0 ? todayDate : data.plan_start)
                                        );
                                        const endDate = startDate.add(numberOfMonths, 'month');
                                        const formattedEndDate =
                                          dayjs(endDate).format('YYYY-MM-DD');
                                        setSelectedItems({
                                          ...SelectedItems,
                                          duration_id: details.id,
                                          expire_date: formattedEndDate,
                                        });
                                        setPrice(details.price);
                                        setAllowExpire(true);
                                        setAnchorEl(null);
                                        console.log(
                                          'testt',
                                          numberOfMonths,
                                          dayjs(formattedEndDate)
                                        );
                                        setSelectedDate(dayjs(formattedEndDate));
                                      }}
                                    >
                                      {details.duration}
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-[16px] text-black">NA</div>
                              )}
                            </div>
                          </li>
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="text-[12px] text-[#666666]">Plan End Date</div>
                            <div className="flex flex-wrap text-[12px]">
                              <div className="text-[16px]">{formattedDate}</div>
                              <DateCal
                                className={`ml-[10px] h-[20px] w-[20px] ${
                                  AllowExpire ? 'cursor-not-allowed' : 'cursor-pointer'
                                }`}
                                onClick={(event) => {
                                  if (AllowExpire) {
                                    return;
                                  } else {
                                    setOpenDatePicker(true);
                                    setAnchorEl(anchorEl ? null : event.currentTarget);
                                  }
                                }}
                              />
                              <Popper
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                sx={{ bgcolor: '#F5F5F5', borderRadius: '16px', minWidth: '334px' }}
                              >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DateCalendar
                                    open={OpenDatePicker}
                                    onClose={() => setOpenDatePicker(false)}
                                    value={SelectedDate}
                                    onChange={(event) => {
                                      setAnchorEl(null);
                                      setSelectedDate(event);
                                      const formattedDate = dayjs(event).format('YYYY-MM-DD');
                                      setSelectedItems({
                                        ...SelectedItems,
                                        expire_date: formattedDate,
                                      });
                                    }}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        display: 'none',
                                      },
                                      '& .MuiPickersCalendarHeader-root': {},
                                      '& .MuiPickersArrowSwitcher-button': {
                                        border: 0,
                                        backgroundColor: '#FFFFFF',
                                      },
                                      '& .MuiPickersDay-root': {
                                        color: '#1F1F1F',
                                        borderRadius: '6px',
                                        backgroundColor: '#FFFFFF',
                                        fontSize: '16px',
                                        margin: '3px',
                                        height: 43,
                                        width: 43,
                                      },
                                      '& .Mui-selected': {
                                        color: '#FFF !important',
                                        backgroundColor: '#BD9528 !important',
                                      },
                                      '& .MuiPickersCalendarHeader-labelContainer': {
                                        fontSize: '22px',
                                        fontFamily: 'poppins',
                                      },
                                      '& .MuiDayCalendar-weekDayLabel': {
                                        fontSize: '16px',
                                        height: 43,
                                        width: 43,
                                      },
                                      '& .MuiIconButton-edgeEnd': {
                                        marginRight: '2px',
                                      },
                                      '& .MuiDayCalendar-slideTransition': {
                                        minHeight: 260,
                                      },
                                      minWidth: '365px',
                                      height: '370px',
                                      maxHeight: '365px',
                                    }}
                                    disablePast
                                  />
                                </LocalizationProvider>
                              </Popper>
                            </div>
                          </li>
                          {PlanDetails.map((details, index) => {
                            if (SelectedItems.plan_id === details.plan_id) {
                              return (
                                <li className={` py-[8px] px-[20px]`} key={index}>
                                  <div className="text-[12px] text-[#666666]">Plan Features</div>
                                  <div className="text-[16px]">{details.plan_description}</div>
                                </li>
                              );
                            }
                            return null;
                          })}
                        </div>
                      ) : (
                        <>
                          <li
                            className={`unique py-[8px] px-[20px]`}
                            style={{
                              scrollSnapAlign: 'start',
                            }}
                          >
                            <div className="flex justify-between">
                              <div className="flex flex-col">
                                <div className="text-[12px] text-[#666666]">Plan Name</div>
                                <div className="text-[16px]">{data.plan_name}</div>
                              </div>
                              {!data.is_add_on && data.id !== 0 && customer?.can_create && (
                                <div>
                                  <Edit
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setEditable(true);
                                      setEditableItem(data.id);
                                      setAllowExpire(data.duration_id ? true : false);
                                      setSelectedItems({
                                        ...SelectedItems,
                                        id: data.id,
                                        plan_id: data.plan_id,
                                        duration_id: data.duration_id,
                                        expire_date: dayjs(part(data.plan_end)).format(
                                          'YYYY-MM-DD'
                                        ),
                                      });
                                      setCompare({
                                        ...Compare,
                                        id: data.id,
                                        plan_id: data.plan_id,
                                        duration_id: data.duration_id,
                                        expire_date: dayjs(part(data.plan_end)).format(
                                          'YYYY-MM-DD'
                                        ),
                                      });
                                      setSelectedDate(part(data.plan_end));
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </li>
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="text-[12px] text-[#666666]">Plan Start Date</div>
                            <div className="text-[16px]">{data.plan_start}</div>
                          </li>
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="text-[12px] text-[#666666]">Plan Duration</div>
                            <div className="text-[16px]">{data.plan_duration}</div>
                          </li>
                          <li className={` py-[8px] px-[20px]`}>
                            <div className="text-[12px] text-[#666666]">Plan End Date</div>
                            <div className="text-[16px]">{data.plan_end}</div>
                          </li>
                          {data.plan_description && (
                            <li className={` py-[8px] px-[20px]`}>
                              <div className="text-[12px] text-[#666666]">Plan Features</div>
                              <div className="text-[16px]">{data.plan_description}</div>
                            </li>
                          )}
                        </>
                      )
                    )
                : Array.from({ length: 5 }).map((_, index) => (
                    <li key={index} className="skeleton-loader">
                      <Skeleton className="ml-[15px]" width="20%" baseColor="#e9d39633"></Skeleton>
                      <Skeleton className="ml-[15px]" width="80%" baseColor="#e9d39633"></Skeleton>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
          <div className="items-center flex  border-b">
            <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] p-[20px]">
              Nutrition Plan Details
            </div>
          </div>
          <div style={{ maxHeight: '180px', overflowY: 'scroll', scrollSnapType: 'y mandatory' }}>
            <ul className="w-full">
              {userDetails.nutrionDetails.length !== 0
                ? userDetails.nutrionDetails?.map((data, index) => (
                    <li
                      style={{
                        scrollSnapAlign: index % 3 === 0 ? 'start' : 'none',
                      }}
                      className={`unique py-[8px] px-[20px]`}
                    >
                      <div className="text-[12px] text-[#666666]">{data.label}</div>
                      <div className="text-[16px]">{data.data}</div>
                    </li>
                  ))
                : Array.from({ length: 3 }).map((_, index) => (
                    <li key={index} className="skeleton-loader">
                      <Skeleton className="ml-[15px]" width="20%" baseColor="#e9d39633"></Skeleton>
                      <Skeleton className="ml-[15px]" width="80%" baseColor="#e9d39633"></Skeleton>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        <div className="mb-[15px] bg-white shadow-card-shadow rounded-medium">
          <div className="items-center flex  border-b">
            <div className=" font-Regular text-[20px] md:leading-5 text-body-color-2 mr-[5px] p-[20px]">
              Customer Activities
            </div>
          </div>
          <div style={{ maxHeight: '300px', overflowY: 'scroll', scrollSnapType: 'y mandatory' }}>
            <ul className="w-full">
              {userDetails.customerActivity.length !== 0
                ? userDetails.customerActivity?.map((data, index) => (
                    <li
                      style={{
                        scrollSnapAlign: index % 5 === 0 ? 'start' : 'none',
                      }}
                      className={`py-[8px] px-[20px] unique`}
                    >
                      <div className="text-[12px] text-[#666666]">{data.label}</div>
                      <div className="text-[16px]">{data.data}</div>
                    </li>
                  ))
                : Array.from({ length: 5 }).map((_, index) => (
                    <li key={index} className="skeleton-loader">
                      <Skeleton className="ml-[15px]" width="20%" baseColor="#e9d39633"></Skeleton>
                      <Skeleton className="ml-[15px]" width="80%" baseColor="#e9d39633"></Skeleton>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
      </div>
      <AuditTrial
        setOpenModal={setOpenModal}
        OpenModal={OpenModal}
        headers={headers}
        userId={userId}
      />
    </div>
  );
}

import { Fragment, useEffect, useRef, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';

const people = [
  { name: 'Wade Cooper' },
  { name: 'Arlene Mccoy' },
  { name: 'Devon Webb' },
  { name: 'Tom Cook' },
  { name: 'Tanya Fox' },
  { name: 'Hellen Schmidt' },
];

export default function MultiSelectDropDown({
  open,
  allergyList,
  selectedAllergies,
  setSelectedAllergies,
  saveChange,
  addNew,
}) {
  // console.log("selected", allergyList, selectedAllergies)
  const listbox = useRef(null);
  const listboxBtn = useRef(null);
  const options = useRef(null);
  useEffect(() => {
    if (open) {
      listboxBtn.current.disabled = false;
      listboxBtn.current.click();
    } else {
      listboxBtn.current.disabled = true;
    }
  }, [open]);
  return (
    <div className=" ">
      <Listbox
        value={selectedAllergies}
        onChange={(data) => {
          setSelectedAllergies(data);
        }}
        multiple
        ref={listbox}
        className={'border-b'}
        onBlur={() => {}}
      >
        {({ open }) => (
          <div className="  bottom-[20px] ">
            <Listbox.Button
              className="relative lg:max-w-[170px] 2xl:max-w-[200px] truncate-custom  break-all overflow-hidden text-ellipsis whitespace-nowrap items-center text-left break-words  font-Medium 2xl:text-base xl:text-sm lg:text-[11.85px] text-secondary-4 normalFont focus:outline-none "
              ref={listboxBtn}
              title={selectedAllergies?.map((person) => person).join(', ')}
              onFocus={() => {
                // console.log("ans", selectedAllergies)
                saveChange();
              }}
            >
              {selectedAllergies?.map((person) => person).join(', ')}
            </Listbox.Button>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="">
                  <Listbox.Options
                    className="absolute max-h-60 shadow-2xl mt-1 border-b z-40  overflow-auto rounded bg-white  focus:outline-none    2xl:text-base xl:text-sm lg:text-[11.85px] font-Regular"
                    ref={options}
                    onBlur={() => {
                      // console.log("blurred", selectedAllergies)
                    }}
                    static
                  >
                    <button
                      className="bg-dark border-b w-full py-[5px] flex justify-center gap-2 hover:bg-shades-2/20 font-medium"
                      onClick={() => {
                        listboxBtn.current.click();
                        addNew();
                      }}
                    >
                      <div>+</div> <div> Add New</div>
                    </button>
                    {allergyList?.map((person, personIdx) => (
                      <Listbox.Option
                        key={personIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none border-b lg:py-[8px] lg:px-4 md:py-[6px] md:px-3 ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={person}
                      >
                        {({ selected }) => (
                          <>
                            <div
                              className={` truncate flex justify-center gap-2 just  ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              <input
                                type="checkbox"
                                checked={selected}
                                className="mr-2 2xl:w-[17px] 2xl:h-[17px] xl:h-[12px] xl:w-[12px] lg:w-[10px] lg:h-[10px] w-[30%]"
                              />{' '}
                              <p className="w-[70%]"> {person}</p>
                            </div>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Transition>
            )}
          </div>
        )}
      </Listbox>
    </div>
  );
}

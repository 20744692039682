import React, { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { Dialog, Transition } from '@headlessui/react';

export const ErrorMessages = ({ OpenError, setOpenError, ErrorMessage, setErrorMessage }) => {
  return (
    <Transition appear show={OpenError} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99999]"
        onClose={() => {
          // setOpenError(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-[30%] flex justify-center items-center transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Close
                  className="absolute cursor-pointer right-2 top-2"
                  onClick={() => {
                    setOpenError(false);
                  }}
                />
                <div className={`flex justify-center items-center flex-col`}>
                  <div className="text-[#9F9F9F] ml-[5px]">{ErrorMessage}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

import React, { useState, useRef } from 'react';
import { ReactComponent as Nutrition } from '../../assets/images/nutrition.svg';
import { useParams } from 'react-router-dom';
import { getToken } from '../../Utils/Authenticate';
import MasterPanel from '../layout/Master/Measurement/MasterPanel';

export default function MasterDashboard({ masterMeasurement }) {
  const [CurrentTab, setCurrentTab] = useState('measurement');
  let { userId } = useParams();
  const tabs = [
    masterMeasurement?.can_view
      ? {
          key: 'measurement',
          label: 'Measurement',
          icon: <Nutrition className="mr-[15px]" />,
        }
      : null,
  ].filter((item) => item !== null);
  const dashboardRef = useRef(null);

  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const tabComponents = {
    measurement: (
      <MasterPanel headers={headers} userId={userId} masterMeasurement={masterMeasurement} />
    ),
  };

  return (
    <div>
      <div className="flex py-[20px] w-full">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`font-normal flex px-[20px] cursor-pointer border-b border-b-[#D6A725] ${
              CurrentTab === tab.key ? 'text-[#D2A72B] border-b-[5px]' : ''
            }`}
            onClick={() => {
              setCurrentTab(tab.key);
            }}
          >
            {tab.icon}
            {tab.label}
          </div>
        ))}
        <div className="flex flex-grow border-b-[#D2A72B] border-b"></div>
      </div>
      <div
        className={`${CurrentTab === 'measurement' ? '' : 'dashboard_height'}`}
        ref={dashboardRef}
      >
        {tabComponents[CurrentTab]}
      </div>
    </div>
  );
}

import { getToken } from '../../Utils/Authenticate';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';

const TemplateImport = ({ setSelectedValue, selectedValue, verification }) => {
  const [importData, setImportData] = useState([]);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  let accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const fetchTemplate = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/guideline_templates`, {
        headers: headers,
      })
      .then((response) => {
        setLoading(false);
        setImportData(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching guidelines:', error);
      });
  };
  useEffect(() => {}, [open]);

  useEffect(() => {
    fetchTemplate();
  }, []);

  useEffect(() => {
    if (query) {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/guideline_templates?search=${query}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setLoading(false);
          setImportData(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching guidelines:', error);
        });
    }
  }, [query]);

  return (
    <>
      <div className="text-xs relative inline-block bg-white w-1/2">
        <div className="relative w-[83%]">
          <Combobox
            disabled={!verification?.can_create}
            value={selectedValue}
            onChange={(data) => {
              setSelectedValue(data);
              setOpen(!open);
            }}
          >
            <div className="relative">
              <div
                className={`relative w-full cursor-default rounded bg-white  lg:pt-[5px] md:pt-[3px]  lg:px-[1px] md:px-[6px] text-left   font-Regular 2xl:text-base xl:text-[14.22px] lg:text-[11.85px]`}
              >
                <Combobox.Input
                  className='relative border-b w-auto justify-center text-[11.852px] 2xl:py-[10px] lg:py-[13px] md:py-[4px] min-w-[250px] hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"'
                  // className={`w-full border-none 2xl:py-3 2xl:pl-[10px]  lg:py-[6px] md:py-[4px]  lg:pl-[8px] md:pl-[6px]  lg:pr-[32px] md:pr[28px]  text-gray-900 focus:outline-none 2xl:text-base xl:text-[14.22px] lg:text-[11.85px]`}
                  displayValue={(data) => data.name}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    setLoading(true);
                  }}
                  placeholder={'Import Template'}
                />
                {/* <div
                  onClick={() => {
                    // setOpenGuide(false);
                  }}
                  className="2xl:pr-5 xl:pr-[16px] lg:pr-[14px] absolute top-[10%] right-[1%] "
                > */}
                {/* </div> */}
                {selectedValue && (
                  <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                    onClick={() => {
                      setSelectedValue('');
                    }}
                  >
                    <Close className="relative t-[18px] right-[15px] w-[8px]" />
                  </Combobox.Button>
                )}

                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center pr-2"
                  onClick={() => setOpen(!open)}
                >
                  <div className="flex justify-between items-center truncate">
                    <div>
                      <UpArray
                        className={`  lg:w-3 lg:h-3 md:w-1 md:h-1  ui-not-open:transform ui-not-open:rotate-180  `}
                      />
                    </div>
                  </div>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <div className=" text-gray-400" aria-hidden="true" />
                  </span>
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options
                  className={`${
                    importData.length > 0 || importData.length > 1 ? 'border' : ''
                  } absolute mt-1 max-h-52 w-full overflow-auto  border-[#E6E6E6] shadow-[4px 4px 8px 2px rgba(0, 0, 0, 0.12)] rounded-lg bg-white z-50  2xl:text-xs 2xl:leading-[18px] xl:text-sm lg:text-xs md:text-[10px] font-Regular`}
                >
                  {importData.length === 0 && query !== '' && !loading ? (
                    <div className="relative cursor-default select-none py-2  px-2 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    importData.map((template, id) => (
                      <Combobox.Option
                        key={id}
                        onClick={() => {
                          // handleSelect(template)
                        }}
                        className={({ active }) =>
                          `relative cursor-pointer select-none 2xl:px-5 2xl:py-[10px]   lg:py-[8px] lg:px-2 md:py-[6px] md:px-3 border-b ${
                            active ? 'bg-shades-2/20 text-black' : 'text-gray-900'
                          }`
                        }
                        value={template}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {template.name}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }'
                                  }`}
                              >
                                <div className=" lg:h-4 lg:w-4 md:h-3 md:w-3" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>
      </div>
    </>
  );
};

export default TemplateImport;

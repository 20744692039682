import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';
import { getToken, verifyToken } from '../../Utils/Authenticate';
import { useNavigate } from 'react-router-dom';
import { SuccessMessage } from './SuccessMessage';
import { WarningMessage } from './WarningMessage';
import { ErrorMessages } from './ErrorMessages';

export const Login = () => {
  const navigate = useNavigate();
  const token = getToken();
  const [Data, setData] = useState({ email: '', password: '', no_of_attempts: 1 });
  const [Email, setEmail] = useState('');
  const [VerifyCaptcha, setVerifyCaptcha] = useState(false);
  const [PasswordVisible, setPasswordVisible] = useState(false);
  const [ForgetPasswordScreen, setForgetPasswordScreen] = useState(false);
  const [OpenSuccess, setOpenSuccess] = useState(false);
  const [OpenWarning, setOpenWarning] = useState(false);
  const [OpenError, setOpenError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [AuthToken, setAuthToken] = useState('');
  const [type, setType] = useState('');
  const [UserData, setUserData] = useState('');
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const forgotPassword = () => {
    const body = {
      email: Email,
    };
    if (Email) {
      if (emailRegex.test(Email)) {
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/admin/user/forgot_password`, body)
          .then((response) => {
            setOpenSuccess(true);
          })
          .catch((error) => {
            const userRetry = error.response.data;
            setOpenError(true);
            setErrorMessage(userRetry.message);
          });
      } else {
        setOpenError(true);
        setErrorMessage('Email Is Incorrect.');
      }
    } else {
      setOpenError(true);
      setErrorMessage('Email is Empty.');
    }
  };

  useEffect(() => {
    if (token) {
      navigate('/customer/dashboard');
    }
  }, [token]);

  let isLoggingIn = false;

  const logIn = () => {
    if (isLoggingIn) {
      return;
    }
    isLoggingIn = true;
    const body = {
      email: Data.email,
      password: Data.password,
      no_of_attempts: Data.no_of_attempts,
    };

    if (VerifyCaptcha) {
      if (Data.email || Data.password) {
        if (Data.email) {
          if (emailRegex.test(Data.email)) {
            if (Data.password) {
              axios
                .post(`${process.env.REACT_APP_BASE_URL}/admin/user/login`, body)
                .then((response) => {
                  const userDetails = response.data.data;
                  const userData = userDetails[0];
                  if (userData.is_remainder === false && userData.is_password_reset === false) {
                    const customer = userData.role_permissions?.find(
                      (permission) => permission.name === 'Customer Dashboard'
                    );
                    const verification = userData.role_permissions?.find(
                      (permission) => permission.name === 'Verification Panel'
                    );
                    const progress = userData.role_permissions?.find(
                      (permission) => permission.name === 'Progress Tracker'
                    );
                    const roles = userData.role_permissions?.find(
                      (permission) => permission.name === 'Role Management'
                    );
                    const ingredient = userData.role_permissions?.find(
                      (permission) => permission.name === 'Ingredients Panel'
                    );
                    const recipe = userData.role_permissions?.find(
                      (permission) => permission.name === 'Recipe Panel'
                    );
                    const master = userData.role_permissions?.find(
                      (permission) => permission.name === 'Master Measurement Panel'
                    );
                    verifyToken(userData.access_token).then((res) =>
                      navigate(
                        `${
                          customer?.can_view
                            ? '/customer/dashboard'
                            : verification?.can_view
                            ? '/plan/verification'
                            : roles?.can_view
                            ? '/role_management'
                            : progress?.can_view
                            ? '/progress/tracker'
                            : ingredient?.can_view || recipe?.can_view
                            ? '/nutrition'
                            : master?.can_view
                            ? '/master'
                            : '/'
                        }`
                      )
                    );
                    localStorage.setItem('user', JSON.stringify(userData));
                  } else if (
                    userData.is_remainder === true &&
                    userData.is_password_reset === true
                  ) {
                    setOpenWarning(true);
                    setType('reminder');
                    setAuthToken(userData.access_token);
                    setUserData(userData);
                  } else if (
                    userData.is_remainder === false &&
                    userData.is_password_reset === true
                  ) {
                    setAuthToken(userData.access_token);
                    setOpenWarning(true);
                    setType('reset');
                  }
                  setData({ ...Data, no_of_attempts: 1 });
                })
                .catch((error) => {
                  const userRetry = error.response.data;
                  if (
                    userRetry.message ===
                    'Your account is currently locked. Please try to login after 15 min.'
                  ) {
                    setData({ ...Data, no_of_attempts: 1 });
                  } else {
                    setData({ ...Data, no_of_attempts: Data.no_of_attempts + 1 });
                  }
                  setOpenError(true);
                  setErrorMessage(userRetry.message);
                })
                .finally(() => {
                  isLoggingIn = false;
                });
            } else {
              setOpenError(true);
              setErrorMessage('Enter Password.');
            }
          } else {
            setOpenError(true);
            setErrorMessage('Email Is Incorrect.');
          }
        } else {
          setOpenError(true);
          setErrorMessage('Email is Empty.');
        }
      } else {
        setOpenError(true);
        setErrorMessage('Enter Email and Password.');
      }
    } else {
      setOpenError(true);
      setErrorMessage(`Captcha hasn't been verified.`);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-[#FEFBF3]">
      <div
        className="flex items-center justify-center bg-[#FFF] w-[70%] rounded-[30px]"
        style={{ boxShadow: '0px 0px 193.3px 40px rgba(185, 185, 185, 0.25)' }}
      >
        <div className="flex justify-center items-center flex-col">
          <br />
          <Logo className="xl:w-[52px] xl:h-[50px]" />
          <span className="font-[600] 2xl:text-[32px] xl:text-[25px] lg:">unlock.fit</span>
          <br />
          <span className="font-[600] 2xl:text-[30px] xl:text-[22px]">
            {ForgetPasswordScreen ? 'Forgot Password' : 'Admin Dashboard'}
          </span>
          {!ForgetPasswordScreen && (
            <span className="font-[400] 2xl:text-[25px] xl:text-[17px] text-[#9F9F9F] mt-[10px]">
              Sign in with Email Address
            </span>
          )}
          <br />
          <br />
          {ForgetPasswordScreen ? (
            <form
              className="flex flex-col justify-start 2xl:w-[135%] xl:w-[145%]"
              onSubmit={(e) => {
                e.preventDefault();
                forgotPassword();
              }}
            >
              <br />
              <div className="flex flex-col">
                <span className="text-[#9F9F9F]">Email</span>
                <input
                  type="email"
                  className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                  value={Email}
                  style={{
                    boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                  }}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />
              </div>
              <br />
              <br />
              <button
                type="submit"
                className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
              >
                Send Verification Link
              </button>
              <br />
              <br />
            </form>
          ) : (
            <form
              className="flex flex-col justify-start 2xl:w-[125%] xl:w-[135%]"
              onSubmit={(e) => {
                e.preventDefault();
                logIn();
              }}
            >
              <div className="flex flex-col">
                <span className="text-[#9F9F9F]">Email</span>
                <input
                  type="email"
                  className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] outline-none"
                  value={Data.email}
                  style={{
                    boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                  }}
                  onChange={(e) => {
                    setData({ ...Data, email: e.target.value.toLowerCase(), no_of_attempts: 1 });
                  }}
                />
              </div>
              <br />
              <div className="flex relative flex-col">
                <span className="text-[#9F9F9F]">Password</span>
                <div className="relative flex items-center">
                  <input
                    className="py-[5px] px-[10px] rounded-[10px] border border-[#E6E6E6] w-full outline-none"
                    type={PasswordVisible ? 'text' : 'password'}
                    style={{
                      boxShadow: '4px 4px 8px -2px rgba(0, 0, 0, 0.08)',
                    }}
                    value={Data.password}
                    onChange={(e) => setData({ ...Data, password: e.target.value.trim() })}
                  />
                  <div
                    className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-[5px]"
                    onClick={() => {
                      setPasswordVisible(!PasswordVisible);
                    }}
                  >
                    {!PasswordVisible ? (
                      <VisibilityIcon className="text-[#9F9F9F]" />
                    ) : (
                      <VisibilityOffIcon className="text-[#9F9F9F]" />
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="flex justify-center">
                <ReCAPTCHA
                  sitekey="6LdgzJQpAAAAAOkt7w_c8OYLb-eOfBFKOVZ_hzS2"
                  onChange={() => {
                    setVerifyCaptcha(true);
                  }}
                  onExpired={() => {
                    setVerifyCaptcha(false);
                  }}
                />
              </div>
              <br />
              <button
                type="submit"
                className="p-[5px] rounded-[10px] text-body-color-3 bg-brand-color outline-none"
              >
                Sign In
              </button>
              <br />
              <button
                onClick={() => setForgetPasswordScreen(true)}
                className="p-[5px] rounded-[10px] text-brand-color transition-colors hover:bg-[#F3EBD6] outline-none"
              >
                Forget Password ?
              </button>
            </form>
          )}
          <br />
        </div>
      </div>
      <SuccessMessage
        Email={Email}
        OpenSuccess={OpenSuccess}
        setOpenSuccess={setOpenSuccess}
        setForgetPasswordScreen={setForgetPasswordScreen}
        type="forgot"
      />
      <WarningMessage
        setAuthToken={setAuthToken}
        AuthToken={AuthToken}
        OpenWarning={OpenWarning}
        setOpenWarning={setOpenWarning}
        type={type}
        setType={setType}
        UserData={UserData}
        setUserData={setUserData}
      />
      <ErrorMessages
        OpenError={OpenError}
        setOpenError={setOpenError}
        ErrorMessage={ErrorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

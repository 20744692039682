import { useEffect } from 'react';
import { useState } from 'react';
import { TimeField, DateInput, DateSegment } from 'react-aria-components';

export default function TimeInput({ setInputTimeVal, value }) {
  // console.log('value change', value);
  const [timeVal, setTimeVal] = useState(value);
  const timeChanged = (time) => {
    // console.log('time', time);
    if (time) {
      setTimeVal(time);
      let timeVal = 'AM';
      let hour = time?.hour;
      if (time.hour === 0) {
        hour = 12;
      }
      if (time.hour >= 12) {
        timeVal = 'PM';
        if (time.hour > 12) {
          hour = time.hour - 12;
        }
      }

      // console.log(
      //   'time',
      //   time,
      //   `${('0' + hour).slice(-2)}:${('0' + time.minute).slice(-2)} ${timeVal}`
      // );
      setInputTimeVal(`${('0' + hour).slice(-2)}:${('0' + time.minute).slice(-2)} ${timeVal}`);
      // console.log('value', value);
    } else {
      setTimeVal('');
    }
  };

  useEffect(() => {
    if (value) {
      setTimeVal(value);
    }
  }, [value]);
  return (
    <>
      <div className="flex mt-8">
        {/* {timeVal ? ( */}
        <TimeField onChange={timeChanged} hourCycle={12} value={timeVal}>
          <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>
        </TimeField>
        {/* ) : (
          <TimeField onChange={timeChanged} hourCycle={12}>
            <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>
          </TimeField>
        )} */}
      </div>
    </>
  );
}

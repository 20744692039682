import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import Button from './Button';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { getToken } from '../../Utils/Authenticate';
import { ClipLoader, HashLoader } from 'react-spinners';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const VersionCreation = ({
  setType,
  setVersionTrigger,
  VersionTrigger,
  Versions,
  Version,
  planDetails,
}) => {
  const [VersionName, setVersionName] = useState('');
  const [overlay, setOverlay] = useState(false);
  const [Modal, setModal] = useState(false);
  let { userId } = useParams();

  let accessToken = getToken();
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const createVersion = () => {
    setOverlay(true);
    setModal(false);
    const bodyForVersionControl = {
      name: VersionName,
      type: setType,
      user_id: userId,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v2/version`,
        bodyForVersionControl,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const res = response.data;
        setOverlay(false);
        setVersionName('');
        setVersionTrigger(VersionTrigger + 1);
      })
      .catch((error) => {
        setOverlay(false);
        const res = error.response.data;
        toast.error(res.message);
        // console.error(res);
      });
  };

  useEffect(() => {
    if (Versions.length > 0) {
      const dateStringArray = Versions?.filter(
        (item) => item.id === Number(Version) && item.is_verified === true
      );
      setVersionName('');
      if (
        dateStringArray.length > 0 &&
        dateStringArray[0]?.last_day_plan_date &&
        dateStringArray[0]?.last_day_plan_date.length > 0
      ) {
        const dateString = dateStringArray[0].last_day_plan_date;
        const [year, month, day] = dateString?.split('-').map(Number);

        const originalDate = new Date(year, month - 1, day);
        originalDate.setDate(originalDate.getDate() + 1);
        const sevenDaysLater = new Date(originalDate);
        sevenDaysLater.setDate(originalDate.getDate() + 7);

        if (!isNaN(sevenDaysLater.getTime())) {
          const options = { day: 'numeric', month: 'short', year: 'numeric' };
          const originalFormattedDate = new Intl.DateTimeFormat('en-US', options).format(
            originalDate
          );
          const sevenDaysLaterFormattedDate = new Intl.DateTimeFormat('en-US', options).format(
            sevenDaysLater
          );

          const formattedOriginalDate = originalFormattedDate.replace(/,/g, '');
          const formattedSevenDaysLaterDate = sevenDaysLaterFormattedDate.replace(/,/g, '');

          const newVersionName = `${formattedOriginalDate} to ${formattedSevenDaysLaterDate}`;
          setVersionName(newVersionName);
        } else {
          console.log('Invalid date string:', dateString);
        }
      }
    } else {
      if (planDetails && planDetails.length > 0) {
        const lastObject = planDetails[planDetails?.length - 1];

        const dateString = lastObject.date;
        const [day, month, year] = dateString.split('-').map(Number);
        const originalDate = new Date(year, month - 1, day);
        originalDate.setDate(originalDate.getDate() + 1);

        const sevenDaysLater = new Date(originalDate);
        sevenDaysLater.setDate(originalDate.getDate() + 7);

        if (!isNaN(sevenDaysLater.getTime())) {
          const options = { day: 'numeric', month: 'short', year: 'numeric' };
          const originalFormattedDate = new Intl.DateTimeFormat('en-US', options).format(
            originalDate
          );
          const sevenDaysLaterFormattedDate = new Intl.DateTimeFormat('en-US', options).format(
            sevenDaysLater
          );

          const formattedOriginalDate = originalFormattedDate.replace(/,/g, '');
          const formattedSevenDaysLaterDate = sevenDaysLaterFormattedDate.replace(/,/g, '');

          const newVersionName = `${formattedOriginalDate} to ${formattedSevenDaysLaterDate}`;
          setVersionName(newVersionName);
        } else {
          console.log('Invalid date string:', lastObject.date);
        }
      }
    }
  }, [Versions, Version, planDetails]);

  return (
    <>
      {overlay && (
        <div className="fixed h-screen w-screen z-40 inset-0 bg-[rgba(0,0,0,0.5)] flex flex-col justify-center items-center">
          <HashLoader color="#BD9528" cssOverride={{}} loading size={100} speedMultiplier={2} />
          <div className="text-[#ffff] mt-[15px] text-[20px]">Generating New Version Diet Plan</div>
        </div>
      )}
      <div className=" flex items-center justify-center">
        <button
          onClick={() => {
            setModal(true);
          }}
          type="button"
          className="bg-brand-color text-white rounded-[10px] border  lg:px-4 md:px-2  lg:gap-[8px] md:gap-[6px]  lg:py-[6px] md:py-[4px] flex  lg:text-xs md:text-[10px]"
        >
          Create Version
        </button>
      </div>
      <Transition show={Modal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            setModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center  text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="2xl:w-[694px] 2xl:h-[240px] xl:w-[536.81px] xl:h-[185.64px] lg:w-[447.34px] lg:h-[154.7px] transform overflow-hidden  rounded-2xl bg-white  text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="2xl:px-5 xl:px-4 lg:px-3 font-medium justify-between  border-b items-center   text-gray-900"
                  >
                    <div className="flex justify-between items-center">
                      <div></div>
                      <div className="text-center flex  text-shades-2 2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] 2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px]">
                        Create New Version
                      </div>
                      <div
                        className=" cursor-pointer"
                        onClick={() => {
                          setModal(false);
                        }}
                      >
                        <Close />
                      </div>
                    </div>
                  </Dialog.Title>
                  <div className=" border-b 2xl:py-5 xl:py-[15.47px]  lg:py-[12.89px]">
                    <div className="flex justify-center items-center 2xl:px-10 xl:px-[30.94px] lg:px-[25.78px] ">
                      <div className="text-body-color-2  text-base font-Regular  w-full">
                        Enter Version Name
                      </div>
                      <div className="border-b border-black text-body-color-1  2xl:text-base xl:text-[12.38px] lg:text-[10.31px] font-Medium w-full">
                        <input
                          type="text"
                          className="w-full outline-none"
                          placeholder="Version Name"
                          value={VersionName}
                          onChange={(e) => setVersionName(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" flex justify-center 2xl:py-5 xl:py-[15.47px]  lg:py-[12.89px]">
                    <Button
                      type="button"
                      className=" rounded-small border border-transparent   bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-medium text-white"
                      onClick={createVersion}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default VersionCreation;

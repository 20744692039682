import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ReactComponent as Close } from '../../assets/images/close.svg';
import TempletDisclose from '../shared/TempletDisclose';
import Button from '../common/Button';
import { getToken } from '../../Utils/Authenticate';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SaveTempletModal from './SaveTempletModal';

const MealNoteModal = ({ Openis, Closeis, meal_type_id, type, setRefresh, setOpenNote }) => {
  let { userId, planId } = useParams();
  const [currVal, setVal] = useState('');
  const [notesTemplates, setNotesTemplates] = useState([]);
  const [selectedNoteTemp, setSelectedNoteTemp] = useState();
  const [temopen, setSavOpen] = useState(false);

  const [tempQuery, setTempQuery] = useState('');

  useEffect(() => {
    if (Openis) {
      setSelectedNoteTemp();
      setVal('');
    }
  }, [Openis]);

  // useEffect(() => {
  //   let token = getToken();
  //   let config = {
  //     baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/meal_notes`,
  //     method: 'get',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   axios
  //     .request(config)
  //     .then(({ data }) => {
  //       setNotesTemplates(data.data);
  //     })
  //     .catch((err) => {
  //       toast.error(err);
  //     });

  // }, [])

  useEffect(() => {
    if (selectedNoteTemp) {
      let token = getToken();
      let config = {
        baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/?template_id=${selectedNoteTemp?.id}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .request(config)
        .then(
          ({ data }) => {
            data = data.data[0];
            setVal(data?.description);
            // setSelectedNoteTemp("")
          },
          (err) => {}
        )
        .catch((err) => {
          toast.error(err);
        });
    }
  }, [selectedNoteTemp]);

  //--------------------Setting data when the Query Changes ----------------

  useEffect(() => {
    const controller = new AbortController();
    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/template/meal_notes?search=${tempQuery}`,
      method: 'get',
      signal: controller.signal,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (tempQuery) {
      axios
        .request(config)
        .then(({ data }) => {
          // console.log('data', data);
          setNotesTemplates(data.data);
          //data.data.length > 0 ? setSelectedTemp(data.data[0]) : "";
        })
        .catch((err) => {
          toast.error(err);
        });
    }

    return () => {
      controller.abort();
    };
  }, [tempQuery]);

  //--------------------------------------------------

  function CloseSavTem() {
    setSavOpen(false);
  }

  function OpenSaveTem() {
    if (currVal?.trim()?.length > 0) {
      // console.log('curval', currVal);
      setSavOpen(true);

      Closeis();
    } else {
      toast.warning('Please Enter Note');
    }
  }

  const saveMeal = () => {
    if (currVal.trim().length === 0) {
      toast.error('Please Enter Some Text');
      return;
    }

    let payload = {
      user_id: userId,
      plan_id: planId,
      description: currVal,
    };
    if (meal_type_id) {
      payload['meal_type_id'] = meal_type_id;
    }
    // console.log("payload", payload)

    let token = getToken();
    let config = {
      baseURL: `${process.env.REACT_APP_BASE_URL}/admin/nutrition_plans/v1/notes`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: payload,
    };
    axios
      .request(config)
      .then(
        ({ data }) => {
          // console.log("dataRec", data)
          toast.success(data.message);
          setSelectedNoteTemp('');
          setVal('');
          Closeis();
          setOpenNote((old) => !old);
          //setRefresh((old) => !old)
          // opneMealNote()
        },
        (err) => {}
      )
      .catch((err) => {
        toast.error(err);
      });
  };

  // function opneMealNote() {
  //   setOpenNote(true);
  // }

  // function CloseNoteModal() {
  //   setOpenNote(false);
  // }

  return (
    <div>
      <Transition appear show={Openis} as={Fragment}>
        <Dialog as="div" className="relative z-10  " onClose={Closeis}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto ">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="  transform 2xl:w-[694px] 2xl:h-[414px] xl:w-[536.81px] xl:h-[340.23px] lg:w-[447.34px] lg:h-[296.86px] overflow-hidden rounded-2xl bg-white  text-left align-middle shadow-xl transition-all ">
                  <Dialog.Title
                    as="div"
                    className=" font-medium  flex justify-between border-b  items-center text-shades-2"
                  >
                    <div></div>
                    <div className="2xl:text-2xl xl:text-[18.56px] lg:text-[15.47px] 2xl:p-[20px] xl:p-[15.47px] lg:p-[12.89px]  text-shades-2">
                      Meal Notes
                    </div>
                    <div onClick={Closeis} className=" 2xl:pr-5 xl:pr-4 lg:pr-3">
                      <Close className="cursor-pointer 2xl:h-[13.31px] 2xl:w-[13.31px] xl:w-[10.29px] xl:h-[10.29px] lg:w-[8.58px] lg:h-[8.58px] " />
                    </div>
                  </Dialog.Title>
                  <div className=" 2xl:px-10 xl:px-[30.94px] lg:px-[25.78px] border-b">
                    <div className=" float-right 2xl:mb-5 xl:mb-[15.47px] lg:mb-[12.89px]">
                      <TempletDisclose
                        className={'2xl:text-base xl:text-[12.38px] lg:text-[10.31px]'}
                        data={notesTemplates}
                        selected={selectedNoteTemp}
                        setSelected={setSelectedNoteTemp}
                        type={'mealNotes'}
                        query={tempQuery}
                        setQuery={setTempQuery}
                      />
                    </div>
                    <div className="   2xl:py-5 xl:py-[15.47px] lg:py-[12.89px]   ">
                      <textarea
                        name=""
                        className=" 2xl:p-[10px] xl:p-[7.74px] lg:p-[6.45px]  2xl:w-[614px] 2xl:h-[160px] xl:w-[474px] xl:h-[123.76px] lg:w-[395.77px] lg:h-[103.73px] rounded-small text-body-color-2 border shadow-grid-col outline-none   2xl:text-SmallRegular xl:text-[9.28px] lg:text-[7.74px]"
                        id=""
                        onChange={(e) => {
                          setVal(e.target.value);
                        }}
                        value={currVal}
                        placeholder="Write your notes here"
                        style={{ resize: 'none' }}
                        maxLength={'200'}
                      >
                        Write your notes here
                      </textarea>

                      <div className="-mt-[28px] z-40 absolute right-[40px] text-body-color-2   2xl:text-SmallRegular xl:text-[9.28px] lg:text-[7.74px]">
                        {currVal?.length}/200
                      </div>
                    </div>
                  </div>

                  <div className=" flex justify-center gap-5 2xl:py-5 xl:py-[15.47px] lg:py-[12.89px]">
                    <Button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-white border  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]   text-shades-2 text-SmallerBodyDisplayText font-Regular"
                      onClick={OpenSaveTem}
                    >
                      Save As Template
                    </Button>
                    <Button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-shades-2  2xl:px-10  2xl:py-[8px] xl:px-[30.94px] xl:py-[6.19px] lg:px-[25.78px] lg:py-[5.16px]  text-SmallerBodyDisplayText font-Regular text-white "
                      onClick={saveMeal}
                    >
                      Save
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SaveTempletModal
        isOpen={temopen}
        closeModal={CloseSavTem}
        type={type}
        meal_type_id={meal_type_id}
        description={currVal}
        setDescVal={setVal}
      />
      {/* <ViewNotes isOpen={openNote} closeModal={CloseNoteModal} userId={userId} planId={planId} type={"Meal Note Wise"} /> */}
    </div>
  );
};

export default MealNoteModal;

import React, { useEffect } from 'react';
import PagesHeader from '../shared/PagesHeader';
import { ReactComponent as ArrowBack } from '../../assets/images/arrow_back.svg';
import { useRef } from 'react';
import Button from '../common/Button';
import { ReactComponent as Add } from '../../assets/images/add_icon.svg';
import ProgressAnalysisTable from '../layout/ProgressAnalysisTable';
import { ProgressAnalysis } from '../../Store/ProgressAnalysisSlice';
import { useDispatch, useSelector } from 'react-redux';
import AnalysisAboutDialog from '../shared/AnalysisAboutDialog';
import { useState } from 'react';
import PrgAnlDropDown from '../shared/PrgAnlDropDown';
import { getClientName, postadddata } from '../../Store/ClientTableSlice';
import PrgAnlAddValue from '../shared/PrgAnlAddValue';

const ProgressAnalysisHome = () => {
  const user = localStorage.getItem('user');
  const permission = JSON.parse(user);

  const progress = permission?.role_permissions?.find(
    (permission) => permission.name === 'Progress Tracker'
  );
  const tableHeader = useRef(null);
  const tableFilter = useRef(null);
  let [open, setIsOpen] = useState(false);
  const [openAddValue, setOpenAddValue] = useState(false);
  const [ApiTrigger, setApiTrigger] = useState(0);
  const dispatch = useDispatch();

  const handleResize = () => {
    let tableHeaderHeight = 0;
    let tableFilterHeight = 0;
    if (tableHeader.current) {
      tableHeaderHeight = tableHeader.current.getBoundingClientRect().height;
    }
    if (tableFilter.current) {
      tableFilterHeight = tableFilter.current.getBoundingClientRect().height;
    }
    document.documentElement.style.setProperty(
      '--table-header-height',
      tableHeaderHeight + tableFilterHeight
    );
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial measurement

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const clientName = useSelector(getClientName);

  useEffect(() => {
    if (!clientName || clientName?.length === 0) {
      // dispatch();
    }
  });

  return (
    <>
      <div>
        <PagesHeader
          name={clientName}
          secondHeading={'Progress Tracker'}
          thirdHeading={clientName}
          array={<ArrowBack className="cursor-pointer" />}
        />
        <div className="bg-white shadow-card-shadow rounded-medium table-card">
          <div className="flex justify-between border-b items-center">
            <div ref={tableHeader} className=" divide-y py-[10px] ">
              <div className=" font-Regular pl-5   xl:text-2xl lg:text-xl md:text-[12px] md:leading-5 text-body-color-2  md:py-2  ">
                Progress Analysis
              </div>
            </div>
            <div className="flex pr-5 items-center gap-5">
              <div
                onClick={() => {
                  openModal();
                }}
              >
                <h1 className="text-shades-2 text-xs cursor-pointer font-normal">About Client</h1>
              </div>
              <div>
                <PrgAnlDropDown ApiTrigger={ApiTrigger} />
              </div>
              {progress?.can_create && (
                <div
                  onClick={() => {
                    setOpenAddValue(true);
                    // dispatch(postadddata());
                  }}
                >
                  <Button className="bg-shades-2 px-[9px] py-[5px] items-center gap-[7px] text-white font-normal text-[11.85px] flex">
                    <Add /> Add
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className="px-5">
            <ProgressAnalysisTable progress={progress} />
          </div>
        </div>
      </div>
      <AnalysisAboutDialog isOpen={open} isClose={closeModal} />
      <PrgAnlAddValue
        isOpen={openAddValue}
        ApiTrigger={ApiTrigger}
        setApiTrigger={setApiTrigger}
        closeModal={() => setOpenAddValue(false)}
      />
    </>
  );
};

export default ProgressAnalysisHome;

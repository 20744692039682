import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ReactComponent as UpArray } from '../../assets/images/up_array.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getTrackersDropDownData, setSelectedParameter } from '../../Store/ProgressAnalysisSlice';

export default function SelectParameterDropdown({ isDisabled }) {
  //   const [selected, setSelected] = useState('');
  const selected = useSelector((state) => state.ProgressAnalysis.selectedParameter);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ProgressAnalysis.trackersDropdownData);

  return (
    <Listbox
      value={selected}
      onChange={(value) => dispatch(setSelectedParameter(value))}
      className={`relative inline-block bg-white ${isDisabled ? '' : 'w-1/2'}`}
      disabled={isDisabled}
    >
      <div className="relative ">
        <Listbox.Button
          className={
            !isDisabled &&
            'relative w-auto justify-center  2xl:py-[10px] 2xl:px-3 lg:py-[13px] lg:px-[10px] md:py-[4px] md:px-[6px]  hover:bg-opacity-30 outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          }
        >
          <div className="flex justify-between items-center ">
            <div>
              <span className=" lg:w-[164px] md:w-[90px] justify-start flex  text-xs text-body-color-2 ">
                <span
                  className=" text-left font-medium text-black text-ellipsis whitespace-nowrap inline-block overflow-hidden"
                  style={{ width: '95%' }}
                >
                  {selected?.name}
                </span>
              </span>
            </div>
            <div>
              <UpArray className="ui-not-open:transform ui-not-open:rotate-180" />
            </div>
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className=" overflow-y-scroll absolute z-10  mt-[2px] 2xl:w-[200px] h-[135px] lg:w-[170px] md:w-[130px] origin-top-center  divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className=" md:px-[2px] md:py-[2px]">
              {data?.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `text-ellipsis whitespace-nowrap w-full cursor-pointer border-b 2xl:py-[10px] 2xl:px-5 xl:py-[8px] xl:px-[16px] lg:py-[8px] lg:px-[16px]  text-xs`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-semibold' : 'font-medium'}`}
                      >
                        {person.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </div>
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
